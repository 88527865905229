import { getRequiredSkills } from "PFApp/hooks/matches";

import { useMatchCardContext } from "../match_card_context";

export const useRequiredSkills = () => {
  const { custom_values: customValues, role, shortlist } = useMatchCardContext();

  return getRequiredSkills({
    matchCustomValues: customValues,
    role,
    applicableCustomValues: shortlist?.applicable_custom_values
  });
};
