import { ColumnHelper } from "@tanstack/react-table";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { EconomicsVacancy, EconomicsVacancyProfile, Shortlist } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import css from "../vacancies_table.module.scss";

type UseStateColumn = {
  shortlists: Shortlist[];
  getVacancyProfile: (vacancy: EconomicsVacancy) => EconomicsVacancyProfile;
};

export const useStateColumn = ({ shortlists, getVacancyProfile }: UseStateColumn) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });
  const { t: tActivities } = useTranslation("activities");

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor((row) => row, {
        id: "state",
        header: () => <span className={css.leftMargin}>{t("state")}</span>,
        cell: (info) => {
          const vacancy = info.getValue();

          const vacancyProfile = getVacancyProfile(vacancy);

          if (!vacancyProfile && vacancy.closureReason === "filled_externally") {
            return (
              <span className={css.leftMargin}>
                <SimpleBadge small text={tActivities(`show.shortlist.state.filled`)} />
              </span>
            );
          }

          if (!vacancyProfile) {
            return <span className={css.leftMargin}>{"-"}</span>;
          }

          const shortlist = shortlists.find(({ profile }) => profile.id === vacancyProfile.id);

          return (
            <span className={css.leftMargin}>
              <SimpleBadge
                small
                text={
                  shortlist?.state
                    ? tActivities(`show.shortlist.state.${shortlist.state}`)
                    : t("notShortlisted")
                }
              />
            </span>
          );
        },
        enableSorting: false,
        size: 30
      }),
    [getVacancyProfile, shortlists, t, tActivities]
  );
};
