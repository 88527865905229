import classNames from "classnames";
import { ActionDiv } from "PFComponents/containers";
import CloseIcon from "PFIcons/x.svg";
import PropTypes from "prop-types";

import css from "./preview_post_link.less";

const PreviewPostLink = ({ postResp, removePost, isMobile }) => (
  <div className={css.wrapper}>
    <ActionDiv className={css.removePost} onClick={removePost}>
      <CloseIcon />
    </ActionDiv>
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img src={postResp.image} className={classNames(css.image, { [css.imageMobile]: isMobile })} />
    {!isMobile && (
      <a href={postResp.url} className={css.url}>
        {postResp.url}
      </a>
    )}
    <div className={css.title}>{postResp.title}</div>
    <div>{postResp.description}</div>
  </div>
);

PreviewPostLink.propTypes = {
  postResp: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string
  }),
  removePost: PropTypes.func,
  isMobile: PropTypes.bool
};

export default PreviewPostLink;
