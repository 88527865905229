import isNil from "lodash/isNil";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type HighlightedIndex = number | null;

type UseHighlightedIndex = {
  optionsCount: number;
  isFetching: boolean;
};

type UseHighlightedIndexReturn = [HighlightedIndex, Dispatch<SetStateAction<HighlightedIndex>>];

export const useHighlightedIndex = ({
  optionsCount,
  isFetching
}: UseHighlightedIndex): UseHighlightedIndexReturn => {
  const [highlightedIndex, setHighlightedIndex] = useState<HighlightedIndex>(null);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (!isNil(highlightedIndex) && highlightedIndex > optionsCount - 1) {
      setHighlightedIndex(optionsCount === 0 ? null : optionsCount - 1);
    }
  }, [optionsCount, isFetching]);

  return [highlightedIndex, setHighlightedIndex];
};
