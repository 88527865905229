import { Trans, useTranslation } from "react-i18next";

export const DefaultTermsOfService = () => {
  const { t } = useTranslation("onboarding", { keyPrefix: "termsOfService.default" });

  return (
    <>
      <p>
        <Trans t={t} i18nKey={"byUsingTheService"} components={[<strong key={0} />]} />
      </p>
      <h2>{t("accountTerms")}</h2>
      <ol>
        <li>{t("ageCondition")}</li>
        <li>{t("noBotsCondition")}</li>
        <li>{t("singleLoginCondition")}</li>
        <li>{t("passwordSecurity")}</li>
        <li>{t("contentResponsibility")}</li>
        <li>{t("illegalUse")}</li>
      </ol>
      <h2>{t("termination")}</h2>
      <ol>
        <li>{t("accountTermination")}</li>
        <li>{t("accountDeactivation")}</li>
        <li>{t("employerContact")}</li>
        <li>{t("serviceRefusal")}</li>
      </ol>
      <h2>{t("copyright")}</h2>
      <ol>
        <li>{t("intellectualPropertyRights")}</li>
        <li>{t("contentPreScreening")}</li>
        <li>{t("designCopyingForbidden")}</li>
      </ol>
      <h2>{t("generalConditions")}</h2>
      <ol>
        <li>{t("yourSoleRisk")}</li>
        <li>{t("requirements")}</li>
        <li>{t("noModificationsAllowed")}</li>
        <li>{t("copyingSellingForbidden")}</li>
        <li>{t("contentRemoval")}</li>
        <li>{t("abuse")}</li>
        <li>{t("unencryptedTransmission")}</li>
        <li>{t("spamMessages")}</li>
        <li>{t("viruses")}</li>
        <li>{t("noWarranty")}</li>
        <li>{t("liability")}</li>
        <li>{t("failureOfProFinda")}</li>
        <li>{t("questions")}</li>
      </ol>
    </>
  );
};
