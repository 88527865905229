import { Typography } from "PFComponents/typography";
import { Profile, ProfileCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import SkillsExplorer from "../../../charts/skills_explorer";
import css from "./skills_insights_section.module.scss";

type SkillsInsightsSectionProps = {
  profile: Profile;
  skills: ProfileCustomValue[];
};

export const SkillsInsightsSection = ({ profile, skills }: SkillsInsightsSectionProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });

  return (
    <section className={css.skillsInsights}>
      <Typography variant="h5" noMargin>
        {t("skillInsights")}
      </Typography>
      <SkillsExplorer
        profile={profile}
        skills={skills}
        selectedChart="bubble"
        showTooltip={false}
        className={css.explorer}
      />
    </section>
  );
};
