import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";

import { ActionIconData, ActionIcons } from "../action_icons";
import { SelectV2Props } from "../select_v2.types";
import css from "./header.module.scss";

type HeaderProps = Pick<SelectV2Props, "label" | "labelTooltip" | "required"> & {
  actionIcons: ActionIconData[];
};

export const Header = ({ label, labelTooltip, actionIcons, required }: HeaderProps) => {
  if (!label) {
    return null;
  }

  const text = required ? `${label} *` : label;

  return (
    <div className={css.root}>
      <div className={css.labelContainer}>
        <Typography variant="labelRegular" noMargin>
          {text}
        </Typography>
        {labelTooltip && (
          <Tooltip
            content={
              <Typography variant="bodyRegular" tag="span">
                {labelTooltip.content}
              </Typography>
            }
          >
            <span aria-label={labelTooltip.content} className={css.iconContainer}>
              <Icon size="sm" name={labelTooltip.icon} className={css.icon} />
            </span>
          </Tooltip>
        )}
      </div>
      <ActionIcons actionIcons={actionIcons} />
    </div>
  );
};
