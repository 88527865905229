import useStorageFilters from "PFApp/use_filtered_collection/use_storage_filters";
import { Filters, Value } from "PFTypes";
import { useCallback } from "react";

import { UseFiltersReturn } from "../../../use_filtered_collection/use_filters";

type UseFiltersWithPostAction = {
  storagePrefix: string;
  postChangeAction: (...args: any) => void;
  defaultValue?: Filters<Value>;
};
export type UseFiltersWithPostActionReturn = UseFiltersReturn & {
  setFiltersRaw: UseFiltersReturn["setFilters"];
};

export const useFiltersWithPostAction = ({
  storagePrefix,
  postChangeAction,
  defaultValue = {}
}: UseFiltersWithPostAction): UseFiltersWithPostActionReturn => {
  const { setFilters, updateFilter, resetFilters, selectedFilters, ...rest } = useStorageFilters({
    storagePrefix,
    defaultValue
  });

  const handleResetFilters = useCallback<UseFiltersReturn["resetFilters"]>(
    (...args) => {
      resetFilters(...args);
      postChangeAction(...args);
    },
    [postChangeAction, resetFilters]
  );

  const handleUpdateFilter = useCallback<UseFiltersReturn["updateFilter"]>(
    (...args) => {
      updateFilter(...args);
      postChangeAction(...args);
    },
    [postChangeAction, updateFilter]
  );

  const handleSetFilters = useCallback<UseFiltersReturn["setFilters"]>(
    (filters, ...args) => {
      setFilters(filters, ...args);
      postChangeAction(filters, ...args);
    },
    [postChangeAction, setFilters]
  );

  return {
    ...rest,
    selectedFilters,
    resetFilters: handleResetFilters,
    updateFilter: handleUpdateFilter,
    setFilters: handleSetFilters,
    setFiltersRaw: setFilters
  };
};
