import {
  RoleProfileActionButtons,
  RoleProfileActionButtonsProps
} from "PFApp/components/role_profile_action_buttons";

import css from "./table_cells.module.scss";

export const RoleProfileActionsCell = (props: RoleProfileActionButtonsProps) => (
  <div className={css.alignRight}>
    <RoleProfileActionButtons {...props} small />
  </div>
);
