import { TemplateKey } from "PFApp/constants/templates";
import { useMemo } from "react";

import { useTemplate } from "../../../hooks";
import { useActivityEditPageFlags } from "../activity_edit_page_context";

export const useEngagementSchema = () => {
  const { isAudit } = useActivityEditPageFlags();

  const template = useTemplate(isAudit ? TemplateKey.AuditEngagement : TemplateKey.Engagement);

  return useMemo(() => template?.schema, [template?.schema]);
};
