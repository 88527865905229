import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { Icon } from "PFComponents/icon";
import {
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useState
} from "react";

import { ActionIconData, ActionIcons } from "../action_icons";
import { Option, OptionOriginal, SelectV2Props } from "../select_v2.types";
import css from "./value_box.module.scss";
import { ValueContainer } from "./value_container";

const DEFAULT_DISPLAY_VALUES_HEIGHT = 22;
const DISPLAY_VALUES_PADDING_OFFSET = 8;

type ValueBoxProps<T> = Pick<
  SelectV2Props<T>,
  "label" | "multiple" | "locked" | "disabled" | "renderDisplayValue" | "onChange" | "displayValueBelow"
> & {
  isOpen: boolean;
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
  onClick: VoidFunction;
  onBlur: (event: FocusEvent<HTMLDivElement>) => void;
  actionIcons?: ActionIconData[];
  dropdownId: string;
  highlightedId?: string;
  selectedOptions: Option<T>[];
  isFetching: boolean;
};

const ValueBoxComponent = <T extends OptionOriginal = OptionOriginal>(
  {
    selectedOptions,
    label,
    multiple,
    renderDisplayValue,
    isOpen,
    onKeyDown,
    onClick,
    onChange,
    onBlur,
    actionIcons,
    dropdownId,
    highlightedId,
    locked,
    disabled,
    displayValueBelow,
    isFetching
  }: ValueBoxProps<T>,
  ref: MutableRefObject<HTMLDivElement | null>
) => {
  const [style, setStyle] = useState<React.CSSProperties>({});

  const selectedIdsString = selectedOptions.map(({ id }) => id).join(",");

  const updateBoxSize = useCallback(
    (node) => {
      if (node === null) {
        setStyle({});
        return;
      }
      const customHeight = node.getBoundingClientRect().height;
      if (customHeight > DEFAULT_DISPLAY_VALUES_HEIGHT) {
        setStyle({ height: customHeight + DISPLAY_VALUES_PADDING_OFFSET });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIdsString]
  );

  const ariaLabel = useMemo(() => {
    if (selectedOptions.length > 0) {
      return `${label}, ${
        multiple ? selectedOptions.map(({ value }) => value).join(", ") : selectedOptions[0].value
      }`;
    } else {
      return label;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, label, selectedIdsString]);

  return (
    <div
      ref={ref}
      role="combobox"
      aria-controls={dropdownId}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      aria-label={ariaLabel}
      aria-activedescendant={highlightedId}
      tabIndex={0}
      className={classNames(css.root, { [css.disabled]: locked || disabled })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      style={style}
    >
      {!displayValueBelow ? (
        <ValueContainer
          isFetching={isFetching}
          onChange={onChange}
          renderDisplayValue={renderDisplayValue}
          multiple={multiple}
          displayValueRef={updateBoxSize}
          selectedOptions={selectedOptions}
          className={css.valueContainer}
          locked={locked}
          disabled={disabled}
        />
      ) : (
        <div />
      )}
      {locked && <Icon name="locked" size="sm" />}
      {!isEmpty(actionIcons) && <ActionIcons actionIcons={actionIcons!} />}
      <Icon name="chevron-down" size="sm" className={css.chevronIcon} />
    </div>
  );
};

export const ValueBox = forwardRef(ValueBoxComponent) as <T>(
  props: ValueBoxProps<T> & { ref?: MutableRefObject<HTMLDivElement | null> }
) => ReturnType<typeof ValueBoxComponent>;
