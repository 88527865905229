import api from "PFCore/api";
import { ActivityPureCustomValue } from "PFTypes";

export type AISkillsFrameworkSkill = Pick<
  ActivityPureCustomValue,
  "value" | "importance" | "requiredExperience"
>;

export type AISkillsFrameworkResponse = {
  name: string;
  description: string;
  skills: ActivityPureCustomValue[];
};

// AIGeneratorBaseParams are not yet implemented on API side
type GenerateSkillsFrameworkData = {
  name: string;
  description?: string;
  skills?: AISkillsFrameworkSkill[];
};

export const generateSkillsFramework = (data: GenerateSkillsFrameworkData) =>
  api<AISkillsFrameworkResponse>({
    method: "POST",
    url: "ai/generate_skills_framework",
    data
  });
