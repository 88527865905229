import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValues } from "../questions_modal.types";
import { createLabel } from "../questions_modal.utils";

const STATEMENT_MAX_LENGTH = 400;

type StatementSectionProps = {
  id: number;
  control: Control<FormValues>;
};

export const StatementSection = ({ id, control }: StatementSectionProps) => {
  const { t } = useTranslation("activities");

  return (
    <>
      <Controller
        name="statementOfQualification"
        control={control}
        rules={{ validate: (value) => !!value?.trim() }}
        render={({ field }) => (
          <InputFieldSet
            {...field}
            label={createLabel(id, t("questionsModal.enterStatementOfQualification"))}
            labelVariant="bodyRegular"
            inputType="textarea"
            maxLength={STATEMENT_MAX_LENGTH}
            minHeight={60}
            onChange={field.onChange}
            onBlur={field.onBlur}
            labelWrap
            required
          />
        )}
      />
    </>
  );
};
