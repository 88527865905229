import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useMatchProfileInfo } from "PFApp/hooks/use_match_profile_info";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { getProfileName, ObjectWithProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./table_cells.module.scss";

type WorkforceMemberCellProps = {
  profile: ObjectWithProfileName & Partial<Profile> & Pick<Profile, "positions">;
};

export const WorkforceMemberCell = ({ profile }: WorkforceMemberCellProps) => {
  const { t } = useTranslation("activities");
  const { isActivityUnbiased } = useActivityPageContext();
  const profileName = getProfileName(profile);
  const { headerInfo: headerProfileInfo } = useMatchProfileInfo(profile);
  const { showPreview } = useProfilePreview();

  const formattedHeaderProfileInfo = headerProfileInfo.map(({ value }) => value).join(", ");

  return (
    <div className={css.profile}>
      {isActivityUnbiased ? (
        <Typography variant="bodyBold" noMargin clipOverflow>
          {t("show.parts.candidateHash", { profileHash: profileHash(profile.id) })}
        </Typography>
      ) : (
        <Button
          kind="text"
          text={profileName}
          className={css.profileName}
          onClick={() => showPreview({ id: profile.id, profile: profile as Profile })}
        />
      )}

      {formattedHeaderProfileInfo && (
        <Typography
          variant="labelRegular"
          className={css.customValues}
          title={formattedHeaderProfileInfo}
          tag="span"
          noMargin
          clipOverflow
        >
          {formattedHeaderProfileInfo}
        </Typography>
      )}
    </div>
  );
};
