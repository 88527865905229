import classNames from "classnames";
import BookingPill from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import { UpdatedBookingChange } from "../overbookings_management_modal";
import { BookingPillContent } from "./components/booking_pill_content";
import css from "./suggested_changes.module.scss";
import { getTextColor } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type SuggestedChangesIgnoreProps = {
  bookingChange: UpdatedBookingChange;
  category: BookingCategory;
};

export const SuggestedChangesIgnore = ({ bookingChange, category }: SuggestedChangesIgnoreProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });

  const { booking } = bookingChange;
  const { formatBookingDates } = useFormatBookingDates();

  const originalBookingDates = formatBookingDates(booking.startDate, booking.endDate);
  const textColor = getTextColor(category);

  return (
    <div className={css.flexColumnContainer}>
      <div className={css.categoryContainer}>
        <Icon name="error" color="paletteRed0" />
        <Typography variant="bodyBold" tag="span">
          {category.display_as}
        </Typography>
      </div>
      <Typography variant="bodyRegular" tag="span">
        {t("noBookingPermission")}
      </Typography>
      <div className={classNames(css.flexColumnContainer, css.singleChangeContainer)}>
        <BookingPill
          title={originalBookingDates}
          category={category}
          className={css.bookingPill}
          style={{
            color: textColor,
            borderWidth: 1
          }}
        >
          <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
        </BookingPill>
      </div>
    </div>
  );
};
