import { ActionIcon } from "PFComponents/action_icon";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { Option, OptionOriginal } from "../../select_v2.types";

export type RemoveHandler<T> = (value: Option<T>, event: SyntheticEvent) => void;

export type ValueBadgeProps<T> = {
  onRemove: RemoveHandler<T>;
  value: Option<T>;
  disabled?: boolean;
};

export const ValueBadge = <T extends OptionOriginal = OptionOriginal>({
  value,
  onRemove,
  disabled
}: ValueBadgeProps<T>) => {
  const { t } = useTranslation("core", { keyPrefix: "components.selectV2" });
  const displayValue = value.displayElement || value.value || "";

  return (
    <SimpleBadge
      small
      text={displayValue as string}
      icon={
        !disabled ? (
          <ActionIcon
            name="cross"
            size="xs"
            title={t("removeOption", {
              label: displayValue
            })}
            onClick={(e) => onRemove(value, e)}
            color="paletteBlue2"
          />
        ) : undefined
      }
    />
  );
};
