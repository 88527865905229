import kebabCase from "lodash/kebabCase";
import { useMemo } from "react";

import { useOptionsInvalidate } from "../use_options/use_options_invalidate";

export const useOptionCreate = ({
  searchTerm,
  onCreateOption,
  setIsOpen,
  dropdownId,
  isFetchingOptionsEnabled,
  resultOptions
}) => {
  const { invalidate } = useOptionsInvalidate();

  const allowCreate = useMemo(
    () =>
      !!onCreateOption &&
      searchTerm.trim().length > 0 &&
      !resultOptions.find(({ value }) => value.toLowerCase() === searchTerm.trim().toLowerCase()),
    [onCreateOption, searchTerm, resultOptions]
  );

  const handleCreateOption = allowCreate
    ? () => {
        const newOption = { id: kebabCase(searchTerm), value: searchTerm };
        if (isFetchingOptionsEnabled) {
          Promise.resolve(onCreateOption(newOption)).then(() => {
            invalidate(dropdownId);
          });
        } else {
          onCreateOption(newOption);
        }
        setIsOpen(false);
      }
    : undefined;

  return { handleCreateOption };
};
