import css from "PFTheme/styles/typography.scss";

import { Variant } from "./typography.types";

type VariantTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "a" | "label";

type VariantData = {
  variantTag: VariantTag;
  className: string;
};

export const VARIANTS: Record<Variant, VariantData> = {
  h1: {
    variantTag: "h1",
    className: css.typographyH1
  },
  h2: {
    variantTag: "h2",
    className: css.typographyH2
  },
  h3: {
    variantTag: "h3",
    className: css.typographyH3
  },
  h4: {
    variantTag: "h4",
    className: css.typographyH4
  },
  h5: {
    variantTag: "h5",
    className: css.typographyH5
  },
  h6: {
    variantTag: "h6",
    className: css.typographyH6
  },
  bodyRegular: {
    variantTag: "p",
    className: css.typographyBodyRegular
  },
  bodyBold: {
    variantTag: "p",
    className: css.typographyBodyBold
  },
  bodyButtons: {
    variantTag: "p",
    className: css.typographyBodyButtons
  },
  labelRegular: {
    variantTag: "label",
    className: css.typographyLabelRegular
  },
  labelSemibold: {
    variantTag: "label",
    className: css.typographyLabelSemibold
  },
  labelBold: {
    variantTag: "label",
    className: css.typographyLabelBold
  },
  labelButton: {
    variantTag: "label",
    className: css.typographyLabelButtons
  }
};
