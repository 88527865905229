import { OTHER_REASON_KEY } from "PFApp/components/expiry_date_modal/expiry_date_modal";
import { PostUntilChangeReason, PostUntilChangeReasonData } from "PFTypes";

export const getPostUntilChangeReason = (
  postUntilChangeReason: PostUntilChangeReason | null,
  reasonsList: PostUntilChangeReason[]
): PostUntilChangeReason | null => {
  if (postUntilChangeReason && postUntilChangeReason.key === OTHER_REASON_KEY) {
    return postUntilChangeReason;
  }

  return reasonsList.find(({ key }) => key === postUntilChangeReason?.key) ?? null;
};

export const getPostUntilChangeReasonData = (
  postUntilChangeReason: Pick<PostUntilChangeReason, "key"> | null
): PostUntilChangeReasonData | null => {
  if (postUntilChangeReason && postUntilChangeReason.key === OTHER_REASON_KEY) {
    return postUntilChangeReason;
  }

  return postUntilChangeReason ? { key: postUntilChangeReason.key } : null;
};
