import { filter } from "lodash";
import { Card } from "PFComponents/card";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { EconomicsVacancy, EconomicsVacancyProfile, Id } from "PFTypes";
import { useMemo } from "react";

import { AssignmentsForm } from "./assignments_form";
import { ResetVacanciesRow } from "./reset_vacancies_row";
import css from "./role_card.module.scss";
import { VacanciesTable } from "./vacancies_table";

type RoleCardProps = {
  vacancies: EconomicsVacancy[];
  scenarioId: Id;
  isEditable?: boolean;
  selectedVacancies: Id[];
  onSelectVacancy: (vacancyId: Id, select: boolean) => void;
  checkCanSelectVacancy: (roleId: number, profile: EconomicsVacancyProfile) => boolean;
};

export const RoleCard = ({
  vacancies,
  scenarioId,
  isEditable = true,
  selectedVacancies,
  onSelectVacancy,
  checkCanSelectVacancy
}: RoleCardProps) => {
  const { roleName, roleId }: { roleName: string; roleId: number } = useMemo(
    () => ({ roleName: vacancies[0].activity.name, roleId: vacancies[0].activity.id }),
    [vacancies]
  );

  const [openVacancies, assignmentVacancies, emptyVacancies] = useMemo(
    () => [
      filter(vacancies, "opened"),
      filter(vacancies, "assignment.profile"),
      isEditable ? filter(vacancies, (vacancy) => !vacancy.assignment?.profile) : []
    ],
    [vacancies, isEditable]
  );

  const hasUnassignedVacancies = openVacancies.length - selectedVacancies.length > 0;
  const hasClosedVacancies = openVacancies.length < vacancies.length;
  const hasOpenVacancies = openVacancies.length > 0;

  const handleAssign = (vacancyId: Id, profile: EconomicsVacancyProfile) => {
    if (hasUnassignedVacancies && checkCanSelectVacancy(roleId, profile)) {
      onSelectVacancy(vacancyId, true);
    }
  };

  return (
    <Card className={css.roleCard}>
      <Typography variant="h5" noMargin>
        {roleName} ({vacancies.length})
      </Typography>
      {hasClosedVacancies && (
        <ResetVacanciesRow vacancies={vacancies} scenarioId={scenarioId} isEditable={isEditable} />
      )}
      {!!assignmentVacancies.length && (
        <VacanciesTable
          vacancies={assignmentVacancies}
          scenarioId={scenarioId}
          isEditable={isEditable}
          selectedVacancies={selectedVacancies}
          hasUnassignedVacancies={hasUnassignedVacancies}
          hasOpenVacancies={hasOpenVacancies}
          getVacancyProfile={(vacancy) => vacancy.assignment!.profile!}
          onSelectVacancy={onSelectVacancy}
          checkCanSelectVacancy={checkCanSelectVacancy}
        />
      )}
      {!!assignmentVacancies.length && !!emptyVacancies.length && <Divider />}
      {!!emptyVacancies.length && (
        <AssignmentsForm emptyVacancies={emptyVacancies} scenarioId={scenarioId} onAssign={handleAssign} />
      )}
    </Card>
  );
};
