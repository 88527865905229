import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { EventKey } from "PFTypes/event_key";
import { Dispatch, KeyboardEvent, MutableRefObject, SetStateAction } from "react";

import { Option, OptionOriginal, ResultOption, SelectV2Props } from "../select_v2.types";
import { getIds } from "../select_v2.utils";
import { HighlightedIndex } from "./use_highlighted_index";

type GetKeyDownHandler<T> = {
  triggerRef: MutableRefObject<HTMLDivElement | null>;
  options: ResultOption<T>[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  highlightedIndex: HighlightedIndex;
  setHighlightedIndex: Dispatch<SetStateAction<HighlightedIndex>>;
  onOptionChange: (option: Option<T>, selected: boolean) => void;
} & Pick<SelectV2Props<T>, "value">;

export type GetKeyDownHandlerReturn = { handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void };

export const getKeyDownHandler = <T extends OptionOriginal = OptionOriginal>({
  value,
  triggerRef,
  highlightedIndex,
  setHighlightedIndex,
  options,
  onOptionChange,
  setIsOpen
}: GetKeyDownHandler<T>): GetKeyDownHandlerReturn => {
  const handleKeyDown = (event) => {
    event.stopPropagation();
    const calculateNewIndex = (newIndex: number) => {
      if (newIndex < 0) {
        return Math.max(options.length - 1, 0);
      } else if (newIndex > options.length - 1) {
        return 0;
      } else {
        return newIndex;
      }
    };
    switch (event.key) {
      case EventKey.ArrowDown: {
        event.preventDefault();
        if (isEmpty(options)) {
          return;
        }
        setHighlightedIndex((prev) => {
          const newIndex = (prev ?? -1) + 1;
          return calculateNewIndex(newIndex);
        });
        return;
      }
      case EventKey.ArrowUp: {
        event.preventDefault();
        if (isEmpty(options)) {
          setIsOpen(false);
          triggerRef.current?.focus();
          return;
        }
        setHighlightedIndex((prev) => {
          const newIndex = (prev ?? 1) - 1;
          return calculateNewIndex(newIndex);
        });
        return;
      }
      case EventKey.Enter: {
        const highlightedOption =
          isNil(highlightedIndex) || highlightedIndex < 0 ? null : options[highlightedIndex];
        if (!highlightedOption || highlightedOption.disabled) {
          return;
        }
        const selectedOptionIds = getIds(value);
        const isSelected = highlightedOption.selected ?? selectedOptionIds.includes(highlightedOption.id);
        const handleChange = highlightedOption.onChange || onOptionChange;
        handleChange(highlightedOption, !isSelected);
        return;
      }
      case EventKey.Escape:
        setIsOpen(false);
        triggerRef.current?.focus();
        return;
    }
  };

  return { handleKeyDown };
};
