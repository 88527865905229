import { Radios } from "PFComponents/radios/radios";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BinaryAnswer, FormValues } from "../questions_modal.types";
import { createLabel } from "../questions_modal.utils";

type QuestionProps = {
  id: number;
  name: keyof FormValues;
  label: string;
  control: Control<FormValues>;
};

export const Question = ({ id, name, control, label }: QuestionProps) => {
  const { t } = useTranslation("translation");

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Radios
          name={name}
          label={createLabel(id, label)}
          value={field.value}
          items={[
            { id: BinaryAnswer.Yes, label: t("yes") },
            { id: BinaryAnswer.No, label: t("no") }
          ]}
          onChange={(id) => field.onChange(id)}
          required
        />
      )}
    />
  );
};
