import { useTemplate } from "PFApp/hooks";
import { useReopenActivity } from "PFCore/hooks/queries/activities/use_reopen_activity";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import {
  ExpiryDateModal,
  getPostUntilChangeReason,
  getPostUntilChangeReasonData,
  PostUntilData
} from "../expiry_date_modal";

type ReopenActivityModalProps = {
  activity: Pick<Activity, "id"> & {
    postUntil: Activity["post_until"];
    postUntilChangeReason: Activity["post_until_change_reason"];
    templateId: Activity["template_id"];
  };
  onSuccess?: VoidFunction;
  onClose: VoidFunction;
};

export const ReopenActivityModal = ({ activity, onSuccess, onClose }: ReopenActivityModalProps) => {
  const { t } = useTranslation("activities");

  const template = useTemplate(activity.templateId);
  const { mutateAsync: reopenActivity } = useReopenActivity();

  const postUntilChangeReason = getPostUntilChangeReason(
    activity.postUntilChangeReason ?? null,
    template?.localized_post_until_change_reasons ?? []
  );

  const handleChange = async ({ date, reason }: PostUntilData) => {
    const postUntilChangeReason = getPostUntilChangeReasonData(reason ?? null);
    await reopenActivity({
      activityId: activity.id,
      postUntil: date,
      ...(postUntilChangeReason ? { postUntilChangeReason } : {})
    });
    onSuccess?.();
  };

  if (!template) {
    return null;
  }

  return (
    <ExpiryDateModal
      title={t("reopenActivity.reopenRole")}
      reasonLabel={t("reopenActivity.reasonToReopen")}
      reason={postUntilChangeReason}
      date={activity.postUntil ?? ""}
      template={template}
      onChange={handleChange}
      onClose={onClose}
    />
  );
};
