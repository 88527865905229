import css from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityMatchesFilters } from "PFApp/activities/show/hooks/use_activity_matches_filters";
import { FiltersContextProvider } from "PFApp/components/filters/context/filters_context";
import { PagePrefix } from "PFApp/components/filters/filters_panel";

import { SideFilters } from "../../components/side_filters";
import { MatchesInformation } from "./matches_information";

export const MatchesSidePanel = () => {
  const { matchesMeta, matchesFetchState } = useActivityPageContext();
  const { setFilters, resetFilters, updateFilter } = useActivityMatchesFilters();

  return (
    <div className={css.pageCardSidePanel}>
      <MatchesInformation />
      <FiltersContextProvider
        keyPrefix={PagePrefix.ActivityMatches}
        meta={matchesMeta}
        onFilterChange={updateFilter}
        onFiltersChange={setFilters}
        clearFilters={resetFilters}
      >
        <SideFilters disabled={matchesFetchState === "pending"} separateActivityFilters />
      </FiltersContextProvider>
    </div>
  );
};
