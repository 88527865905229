import { isNil } from "lodash";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";

import css from "./table_cells.module.scss";

type ScoreValueCellProps = {
  score?: number | null;
};

export const ScoreValueCell = ({ score }: ScoreValueCellProps) => {
  const roundedValue = getMatchDisplayValue(score, { digits: 0, defaultValue: "-" });
  const tooltipValue = getMatchDisplayValue(score, {
    digits: 3,
    skipTrailingZeros: true,
    showPercentage: true
  });

  const noScore = isNil(score);

  return (
    <Tooltip content={tooltipValue} disabled={noScore}>
      <div className={css.score}>
        <Typography variant="h4" noMargin>
          {roundedValue}
        </Typography>
        {!noScore && (
          <Typography variant="bodyRegular" noMargin>
            %
          </Typography>
        )}
      </div>
    </Tooltip>
  );
};
