import classNames from "classnames";
import { compact, head, isEmpty, isEqual, isUndefined, toNumber, trimStart, words } from "lodash";
import { AccountAvailability, FeatureFlag, Id } from "PFTypes";
import { AvailabilityRange, AvailabilityRule, StandardRange, TimeRuleRange } from "PFTypes/booking";
import { Phase } from "PFTypes/phase";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import useIsFeatureEnabled from "../../../helpers/use_is_feature_enabled";
import { Tile, TileGroup } from "../../tile";
import { RANGES_ERROR_KEY } from "../constants";
import { getErrorsForRuleIndex } from "../errors_helper";
import { LoadEstimationData, useLoadEstimation } from "../hooks/use_load_estimation";
import { RulesCarouselToolbar } from "../rules_carousel_toolbar";
import { PhasesSelect } from "./phases_select";
import css from "./rules_carousel.modules.scss";
import { StandardRule } from "./standard_rule";
import { TimeRule } from "./time_rule";

type RulesCarouselProps = {
  accountAvailability: AccountAvailability;
  defaultAvailabilityRule: AvailabilityRule;
  isFilter: boolean;
  isTimeRule: boolean;
  allowedModes: string[];
  availability: Partial<AvailabilityRule>;
  minDate?: { start: string; end?: string };
  activityId?: Id;
  onChange: (availability: Partial<AvailabilityRule>) => void;
  errors?: Record<string, string>;
  portalRef?: React.RefObject<HTMLDivElement>;
};

export const RulesCarousel = ({
  availability,
  defaultAvailabilityRule,
  minDate,
  isFilter,
  isTimeRule,
  allowedModes,
  accountAvailability,
  activityId,
  errors = {},
  onChange,
  portalRef
}: RulesCarouselProps) => {
  const { t } = useTranslation();

  const hasActivityPhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases) && !isFilter;

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [slideLeft, setSlideLeft] = useState(true);
  const [rules, setRules] = useState<AvailabilityRange[]>(
    availability.ranges || defaultAvailabilityRule.ranges
  );
  const [isAnimating, setIsAnimating] = useState(false);

  const availabilityMode = availability.mode || defaultAvailabilityRule.mode;
  const { getLoadEstimationData } = useLoadEstimation(isTimeRule, availabilityMode);

  useEffect(() => {
    if (availability.ranges && !isEqual(availability.ranges, rules)) {
      setRules(availability.ranges);
      setCarouselIndex(0);
    }
  }, [availability.ranges]);

  useEffect(() => {
    if (isUndefined(availability.ranges)) {
      setRules(defaultAvailabilityRule.ranges);
      setCarouselIndex(0);
    }
  }, [availability, defaultAvailabilityRule, isTimeRule]);

  const handleChange = (rules: AvailabilityRange[]) => {
    onChange({ ...availability, ranges: rules });
  };

  useEffect(() => {
    if (errors && !isEmpty(errors)) {
      const errorKeys = Object.keys(errors);
      const errorIndexes = compact(
        errorKeys.map((key) => toNumber(head(words(trimStart(key, RANGES_ERROR_KEY)))))
      );

      if (!isEmpty(errorIndexes)) {
        setCarouselIndex(Math.min(...errorIndexes));
      }
    }
  }, [errors]);

  const handleNextRule = () => {
    setSlideLeft(true);
    setCarouselIndex((prev) => Math.min(prev + 1, rules.length - 1));
  };

  const handlePrevRule = () => {
    setSlideLeft(false);
    setCarouselIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNewRule = () => {
    setRules((prev) => {
      const newRules = [...prev];
      newRules.push(defaultAvailabilityRule.ranges[0]);
      setCarouselIndex(newRules.length - 1);

      handleChange(newRules);
      return newRules;
    });
  };

  const handleRemoveRule = (index) => {
    setRules((prev) => {
      const newRules = [...prev];
      newRules.splice(index, 1);
      setCarouselIndex(Math.min(index, newRules.length - 1));

      handleChange(newRules);
      return newRules;
    });
  };

  const handleUpdateRule = (index: number, range: AvailabilityRange, update: Partial<AvailabilityRange>) => {
    setRules((prev) => {
      const newRules = [...prev];
      newRules[index] = { ...range, ...update };

      handleChange(newRules);
      return newRules;
    });
  };

  const handleModeChange = (mode) => {
    onChange({ ...availability, mode });
  };

  const handlePhaseChange = (index, range, { sourceId }: Phase) => {
    handleUpdateRule(index, range, {
      phase_source_id: sourceId
    });
  };

  const renderTimeRule = (range: TimeRuleRange, loadData: LoadEstimationData, index: number) => (
    <TimeRule
      key={index}
      range={range}
      onChange={(update) => handleUpdateRule(index, range, update)}
      isFilter={isFilter}
      minDate={minDate}
      errors={getErrorsForRuleIndex(errors, index)}
      portalRef={portalRef}
      loadData={loadData}
    />
  );

  const renderStandardRule = (range: StandardRange, loadData: LoadEstimationData, index: number) => (
    <StandardRule
      key={index}
      range={range}
      onChange={(update) => handleUpdateRule(index, range, update)}
      minDate={minDate}
      errors={getErrorsForRuleIndex(errors, index)}
      mode={availabilityMode}
      allowedModes={allowedModes}
      accountAvailability={accountAvailability}
      onModeChange={index === 0 ? handleModeChange : undefined}
      portalRef={portalRef}
      isFilter={isFilter}
      loadData={loadData}
    />
  );

  const renderRule = (range: AvailabilityRange, loadData: LoadEstimationData, index: number) =>
    isTimeRule
      ? renderTimeRule(range as TimeRuleRange, loadData, index)
      : renderStandardRule(range as StandardRange, loadData, index);

  const carouselItems = rules.map((range: AvailabilityRange, index) => {
    if (
      (isTimeRule && !(range as TimeRuleRange).day_of_week) ||
      (!isTimeRule && !(range as StandardRange).duration)
    ) {
      return null;
    }

    const loadData = getLoadEstimationData(range);

    return (
      <CSSTransition
        key={index}
        classNames={{
          enter: classNames({ [css.enterRight]: !slideLeft, [css.enterLeft]: slideLeft }),
          enterActive: css.enterActive,
          exit: css.exit,
          exitActive: css.exitActive
        }}
        timeout={{ enter: 500, exit: 300 }}
        onEnter={() => setIsAnimating(true)}
        onEntered={() => setIsAnimating(false)}
      >
        <div className={css.transitionContainer}>
          {hasActivityPhases && (
            <PhasesSelect
              label={t("availabilityRequirement.phasesSelectLabel")}
              className={css.phasesSelect}
              selectedPhaseSourceId={range.phase_source_id}
              activityId={activityId}
              onChange={(phase) => handlePhaseChange(index, range, phase)}
              errors={getErrorsForRuleIndex(errors, index)}
              portalRef={portalRef}
            />
          )}
          {renderRule(range, loadData, index)}
        </div>
      </CSSTransition>
    );
  });

  return (
    <div className={classNames(css.rules, { [css.rulesAnimating]: isAnimating })}>
      <TileGroup>
        <Tile border>
          <RulesCarouselToolbar
            onNext={handleNextRule}
            onPrev={handlePrevRule}
            onCreate={handleNewRule}
            onRemove={handleRemoveRule}
            carouselIndex={carouselIndex}
            rulesLength={rules.length}
          />
        </Tile>
        <Tile border>
          {rules.length === 0 && <div className={css.empty}>{t("availabilityRequirement.noTimeRules")}</div>}
          {!!rules.length && <TransitionGroup>{carouselItems[carouselIndex]}</TransitionGroup>}
        </Tile>
      </TileGroup>
    </div>
  );
};
