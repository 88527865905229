import { Typography } from "PFCore/components/typography";
import { useTranslation } from "react-i18next";

import css from "./file_upload.module.scss";

export const DraggedOverlay = () => {
  const { t } = useTranslation("core");

  return (
    <div className={css.overlay} aria-hidden>
      <Typography variant="h4" noMargin>
        {t("dropTheFilesHere")}
      </Typography>
    </div>
  );
};
