import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { fetchBookings } from "PFApp/booking/services/api";
import { IsoDate } from "PFTypes/date_time";
import { useMemo } from "react";

import { Booking } from "../bookings/query_keys";
import profileKeys from "./query_keys";

export type Profile = {
  id: number;
  activityId?: number | boolean;
  bookingCategoryId?: number;
  rowKey: string;
};

export type BookingWithData = Partial<Booking> & {
  id: number;
  activity_id: number | null;
  duration: number;
  end_date: IsoDate;
  start_date: IsoDate;
  state: string;
  duration_breakdown?: Record<string, number>;
};

export type ProfileBookingsIndex = Record<number | string, Array<BookingWithData>>;

type UseProfilesBookingsFn = {
  profiles: Profile[];
  dateRange: { min: string; max: string };
  enabled?: boolean;
  withDurationBreakdown?: boolean;
};

export type UseProfilesBookingsReturn = {
  bookingsByRowKey: ProfileBookingsIndex;
  bookingsByProfileId: ProfileBookingsIndex;
  bookingsLoadingByRowKey: Record<string, boolean>;
  isFetching: boolean;
};

const groupLoadingByRowKey = (
  queries: UseQueryResult<BookingWithData[]>[],
  profiles: Profile[]
): Record<string, boolean> =>
  queries.reduce((result, { isLoading, isFetching }, index) => {
    const { rowKey } = profiles[index];
    return {
      ...result,
      [rowKey]: isLoading || isFetching
    };
  }, {});

const groupBookingsBy = (
  queries: UseQueryResult<BookingWithData[]>[],
  profiles: Profile[],
  groupBy: (profile: Profile) => string
): Record<string, BookingWithData[]> =>
  queries.reduce((result, { data }, index) => {
    if (!data) {
      return result;
    }
    const key = groupBy(profiles[index]);
    return {
      ...result,
      [key]: data
    };
  }, {});

export const useProfilesBookings = ({
  profiles,
  dateRange,
  enabled = true,
  withDurationBreakdown
}: UseProfilesBookingsFn): UseProfilesBookingsReturn => {
  const stringifiedTriggers = JSON.stringify({ profiles, dateRange, withDurationBreakdown });
  const queriesConfig = useMemo(
    () =>
      profiles.map((profile) => ({
        queryKey: profileKeys.bookings(profile.id, {
          activityId: profile.activityId,
          withDurationBreakdown,
          dateRange,
          ...(profile.activityId === false ? { bookingCategoryId: profile.bookingCategoryId } : {})
        }),
        queryFn: async (): Promise<BookingWithData[]> =>
          (
            await fetchBookings({
              profileId: profile.id,
              activityId: profile.activityId,
              bookingCategoryId: profile.bookingCategoryId,
              dateRange,
              withDurationBreakdown
            })
          ).entries,
        enabled
      })),
    [stringifiedTriggers, enabled]
  );
  const queries = useQueries({
    queries: queriesConfig
  });

  const isFetching = useMemo(() => enabled && !!queries.find(({ isFetching }) => isFetching), [queries]);

  const bookingsLoadingByRowKey = useMemo<Record<string, boolean>>(
    () => groupLoadingByRowKey(queries, profiles),
    [stringifiedTriggers, queries]
  );

  const bookingsByRowKey = useMemo<ProfileBookingsIndex>(
    () => groupBookingsBy(queries, profiles, ({ rowKey }) => rowKey),
    [stringifiedTriggers, isFetching]
  );

  const bookingsByProfileId = useMemo<ProfileBookingsIndex>(
    () => groupBookingsBy(queries, profiles, ({ id }) => String(id)),
    [stringifiedTriggers, isFetching]
  );

  return {
    bookingsByRowKey,
    bookingsByProfileId,
    bookingsLoadingByRowKey,
    isFetching
  };
};
