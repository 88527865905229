import { isEqual, map } from "lodash";
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_ASYNC_ACTIONS } from "PFReducers/notifications_reducer";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DropdownOption } from "../../core/components/dropdown/dropdown";
import { Select } from "../../core/components/select/select";
import AppContext from "../app_context";

export const NotificationsPanelFilters = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    store: {
      notifications: { notifications, target }
    }
  } = useContext(AppContext);

  const targetTypes = useMemo(
    () => notifications?.meta?.target_types || {},
    [notifications?.meta?.target_types]
  );

  const handleChange = useCallback(
    (item: string) => {
      if (targetTypes) {
        dispatch({
          type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_TARGET,
          ...(item === "all" ? {} : { payload: item })
        });
        dispatch({
          type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_SHOW_UNREAD_ONLY,
          payload: false
        });
      }
      dispatch({
        type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_PAGE,
        payload: 1
      });
      dispatch({
        type: NOTIFICATIONS_ASYNC_ACTIONS.NOTIFICATIONS_FETCH_COLLECTION,
        payload: item === "all" ? {} : { target: item }
      });
    },
    [targetTypes, dispatch]
  );

  const dropdownOptions: DropdownOption[] = useMemo(
    () => [
      {
        id: "all",
        item: "all",
        displayElement: t("notifications.all")
      },
      ...map(targetTypes, (value, key) => ({
        id: key,
        item: value,
        displayElement: key
      }))
    ],
    [t, targetTypes]
  );

  return (
    <Select
      value={
        dropdownOptions.find(({ item }) => isEqual(item, target))?.displayElement || t("notifications.all")
      }
      options={dropdownOptions}
      controlledValue
      onChange={handleChange}
      selectedIndex={dropdownOptions.findIndex((option) => option.id === target)}
    />
  );
};
