import { filter, find, map } from "lodash";
import Hr from "PFComponents/hr";
import SidePanel from "PFComponents/side_panel/side_panel";
import { Typography } from "PFComponents/typography";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { Meta, Template } from "PFTypes";
import { useEffect, useMemo, useState } from "react";

import { TableColumn } from "../../../components/table/table_types";
import { getTemplateVisibleProperties } from "../../helpers";
import { GroupBy } from "./group_by";
import { SortableColumns } from "./sortable_columns";
import { TableColumnPill } from "./table_column_pill";
import css from "./table_properties.module.scss";

type TablePropertiesProps = {
  isVisible: boolean;
  onCloseClick: () => void;
  onColumnsChange: (columns: TableColumn[]) => void;
  selectedColumns: TableColumn[];
  defaultColumns?: TableColumn[];
  selectedGroupBy?: string;
  onGroupByChange?: (group: string) => void;
  blockedProperties?: string[];
  template?: Template;
  meta?: Meta;
};

// TODO: [SP-1947] Refactor TableProperties logic
export const TableProperties = ({
  isVisible,
  onCloseClick,
  onColumnsChange,
  selectedColumns,
  defaultColumns = [],
  selectedGroupBy,
  onGroupByChange,
  blockedProperties,
  template,
  meta
}: TablePropertiesProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t } = useOptionalTranslation("workflow");

  // Optimistic update for immediate DnD feedback
  const [localSelectedColumns, setLocalSelectedColumns] = useState(selectedColumns);
  useEffect(() => {
    setLocalSelectedColumns(selectedColumns);
  }, [selectedColumns]);

  const visibleTemplateProperties = useMemo(
    () => getTemplateVisibleProperties(customTypes, currentProfile, blockedProperties!, template),
    [customTypes, currentProfile, blockedProperties, template]
  );

  const [visibleColumns, unselectedColumns] = useMemo(() => {
    const availableColumns = defaultColumns.concat(
      visibleTemplateProperties.map(({ name, type, title, kind }) => ({
        name,
        type,
        title,
        ...(type === "custom_field" && kind === "single" ? { orderKey: `custom_fields.${name}` } : {})
      })) as TableColumn[]
    );

    const columns = map(
      filter(
        localSelectedColumns,
        (column) => column.locked || find(availableColumns, ["name", column.name])
      ),
      (column: TableColumn) => ({ ...column, selected: true })
    );

    const unselectedColumns = filter(
      availableColumns,
      (column) => !find(localSelectedColumns, ["name", column.name])
    );

    return [columns, unselectedColumns];
  }, [localSelectedColumns, visibleTemplateProperties, defaultColumns]);

  const handleColumnUpdate = (columnData: TableColumn, selected: boolean) => {
    if (!selected) {
      onColumnsChange(filter([...selectedColumns], (column) => column.name !== columnData.name));
    } else {
      onColumnsChange([...selectedColumns, columnData]);
    }
  };

  const handleDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    const result = [...selectedColumns];
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    setLocalSelectedColumns(result);
    onColumnsChange(result);
  };

  return (
    <SidePanel show={isVisible} fullHeight onClose={onCloseClick} title={t("parts.tableProperties")}>
      <div className={css.tablePropertiesContainer}>
        <GroupBy meta={meta} selectedGroupBy={selectedGroupBy} onGroupByChange={onGroupByChange} />

        <Typography variant="h5" noMargin>
          {t("parts.editColumns")}
        </Typography>

        <div className={css.columnButtonsContainer} data-qa-id="ActivityInterestedTabTableProperties">
          <SortableColumns onDragEnd={handleDragEnd}>
            {visibleColumns.map((columnData, index) => (
              <TableColumnPill
                key={columnData.name}
                isDraggable
                index={index}
                columnData={columnData}
                onVisibilityChange={handleColumnUpdate}
              />
            ))}
          </SortableColumns>

          {unselectedColumns.length > 0 && <Hr className={css.hr} />}
          <div className={css.tileList}>
            {unselectedColumns.map((columnData, index) => (
              <TableColumnPill
                key={`${columnData.name}-${index}`}
                columnData={columnData}
                onVisibilityChange={handleColumnUpdate}
              />
            ))}
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
