import { groupBy, map } from "lodash";
import { SkillRadial } from "PFApp/components/skill_radial";
import { Tile, TileGroup } from "PFComponents/tile";
import { Typography } from "PFComponents/typography";
import { Importance, MatchCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./match_attributes.module.scss";

type AttributeTileProps = {
  matchKeywords: MatchCustomValue[];
};

const ORDERED_IMPORTANCES = [Importance.Essential, Importance.Supporting, Importance.Required];

export const AttributeTile = ({ matchKeywords }: AttributeTileProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "matchAttributes" });
  const groupedKeywords = groupBy(matchKeywords, "importance");

  const importanceLabelMap = {
    [Importance.Supporting]: t("supporting"),
    [Importance.Essential]: t("essential"),
    [Importance.Required]: t("hardFilter")
  };

  return (
    <TileGroup>
      {map(ORDERED_IMPORTANCES, (importance) => {
        const keywords = groupedKeywords[importance] || [];

        if (keywords.length === 0) {
          return null;
        }

        return (
          <Tile className={css.attributeTile} light border paddingVariant="md">
            <Typography variant="labelRegular">{importanceLabelMap[importance]}</Typography>
            {map(keywords, (keyword) => (
              <SkillRadial name={keyword.text} experience={keyword.required_experience} />
            ))}
          </Tile>
        );
      })}
    </TileGroup>
  );
};
