import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Card } from "PFComponents/card";
import Table from "PFComponents/table";

import { MatchTableEntry } from "../../../activity_tabs.utils";
import { useMatchesShortlistTableColumns } from "../../../hooks/use_matches_shortlist_table_columns";

type MatchesCardsViewProps = {
  matchesEntries: MatchTableEntry[];
  isLoading: boolean;
};

export const MatchesTableView = ({ matchesEntries, isLoading }: MatchesCardsViewProps) => {
  const { matchesMeta, matchesParams, updateMatchesParams } = useActivityPageContext();

  const { generateColumns } = useMatchesShortlistTableColumns<MatchTableEntry>({ isLoading });

  return (
    <Card shadowVariant="none" qaId="ActivityMatchesTabTableContainer">
      <Table<MatchTableEntry>
        tableData={matchesEntries}
        generateColumns={generateColumns}
        paginationOptions={{
          currentPage: matchesParams?.page || 1,
          onClick: (page) => updateMatchesParams({ page }),
          totalPages: matchesMeta.totalPages
        }}
        compact
      />
    </Card>
  );
};
