import { isEqual, pick } from "lodash";
import { useCallback, useMemo } from "react";

import { ModalKey, useActivityEditFormContext } from "../activity_edit_form_context";
import { Question } from "../activity_edit_form_context/hooks";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { getPayloadCustomFields } from "../utils/activity_edit_form.utils";
import { getMetadataToSave, useGenerateActivityEditFormData } from "./use_generate_activity_edit_form_data";

export const useActivityEditForm = () => {
  const { activity, attachments: activityAttachments } = useActivityEditPageContext();

  const { isNew, isRole } = useActivityEditPageFlags();

  const {
    name,
    description,
    postUntil,
    errors,
    isPrivate,
    setIsPrivate,
    assignee,
    communicationType,
    selectedParentActivity,
    metadata,
    customFields,
    attachments,
    setAttachments,
    minRequiredCount,
    audiences,
    setAudiences,
    isModalConfirmed,
    setModalDisplayed,
    questions,
    setQuestions,
    setQuestionsToggleValue,
    removeErrorIfPresent
  } = useActivityEditFormContext();

  const generateFormData = useGenerateActivityEditFormData();

  const handleUploadRemove = (removedAttachment) =>
    setAttachments(attachments.filter((attachment) => attachment.id !== removedAttachment.id));

  const handleQuestionChange = useCallback(
    ({ id, value }: Question) => {
      removeErrorIfPresent("interestQuestions");
      const changedQuestionIndex = questions.findIndex((el) => el.id === id);
      const newQuestions = [...questions];
      newQuestions[changedQuestionIndex].value = value;
      setQuestions(newQuestions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors.interestQuestions, questions]
  );

  const handleQuestionsToggleChange = useCallback(
    (newValue: boolean) => {
      removeErrorIfPresent("interestQuestions");
      setQuestionsToggleValue(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors.interestQuestions]
  );

  const handlePrivateChange = useCallback(() => {
    if (!!activity.private && !isModalConfirmed[ModalKey.Public]) {
      setModalDisplayed(ModalKey.Public, true);
    } else {
      if (isPrivate) {
        setAudiences([]);
      } else {
        setAudiences(activity.audiences || []);
      }
      setIsPrivate(!isPrivate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRole, isPrivate, isModalConfirmed.PUBLIC]);

  const checkChanged = () => {
    if (isNew) {
      return false;
    }
    const currentData = generateFormData();
    const originalData = pick(
      {
        ...activity,
        custom_fields: getPayloadCustomFields(activity.custom_fields),
        attachment_ids: activityAttachments.map(({ id }) => id),
        metadata: getMetadataToSave(activity.metadata)
      },
      Object.keys(currentData)
    );

    return !isEqual(currentData, originalData);
  };

  const isChanged = useMemo(
    () => checkChanged(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      name,
      description,
      postUntil,
      metadata,
      customFields,
      assignee,
      selectedParentActivity,
      minRequiredCount,
      attachments,
      audiences,
      communicationType
    ]
  );

  return {
    handleUploadRemove,
    setQuestionsToggleValue,
    handleQuestionChange,
    handleQuestionsToggleChange,
    handlePrivateChange,
    isChanged
  };
};
