import { getCustomFieldValuesOfImportance } from "PFApp/hooks/matches";

import { useMatchCardContext } from "../match_card_context";

export const useCustomFieldValuesOfImportance = () => {
  const { custom_values: customValues, role, shortlist } = useMatchCardContext();

  return (customFieldName: string) =>
    getCustomFieldValuesOfImportance({
      customFieldName,
      matchCustomValues: customValues,
      role,
      applicableCustomValues: shortlist?.applicable_custom_values
    });
};
