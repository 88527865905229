import Color from "color";

import { variables as deloitteVariables } from "./themes/deloitte";
import { variables as kpmgVariables } from "./themes/kpmg";
import { loadDesignTokens } from "./utilities/load_design_tokens";

const colorPalette = {
  "--Palette-base": "#0D2976", // default: navy
  "--Palette-base-main": "#2358F8", // default: blue
  "--Palette-base-light": "#4B75FF", // default: light blue

  "--Palette-shadow": "#1B48C3", // default to blue (another shade)

  "--Palette-nav": "#0C1457", // default: navy (different then the base)

  "--Palette-action": "#6EF29C", // default: green
  "--Palette-info": "#B47DF4", // default: purple
  "--Palette-alert": "#EEF755", // default: yellow
  "--Palette-warning": "#FFB146", // default: orange
  "--Palette-warning-light": "#FFE8AD",
  "--Palette-success": "#00B9AA",

  "--Palette-neutral": "#CFDAF7",
  "--Palette-neutral-mid": "#E7EAFC",
  "--Palette-neutral-light": "#F8F9FD",
  "--Palette-neutral-light-darker": "#F6F6F6",

  "--Palette-status-warning": "#FFD8A2", // default: light orange
  "--Palette-status-alert": "#F6FBA9", // default: light yellow
  "--Palette-status-action": "#B6F8CD", // default: light green
  "--Palette-status-base": "#C8D5FF", // default: light blue
  "--Palette-status-success": "#B2DF8A", // default: light green
  "--Palette-status-rejected": "#CE0000",

  "--Palette-background": "#FFFFFF", // default: white

  "--BorderRadius-50": "50px",
  "--BorderRadius-30": "30px",
  "--BorderRadius-20": "20px",
  "--BorderRadius-10": "10px",
  "--BorderRadius-5": "5px",
  "--BorderRadius-2": "2px",

  "--Tablet-breakpoint": "767px",

  // legacy:
  "--Color-primary": "#2358F8", // chaanged to base-main for now (mind themes!)
  "--Color-secondary": "#BFE7FC",
  "--Color-highlight": "#FFC107",
  "--Color-success": "#5cb85c",

  "--Color-gray-0": "#000000",
  "--Color-gray-1": "#333333",
  "--Color-gray-2": "#53627c",
  "--Color-gray-3": "#A6AEBC",
  "--Color-gray-4": "#CCCCCC",
  "--Color-gray-5": "#E0E0E0",
  "--Color-gray-6": "#F7F7F8",
  "--Color-gray-7": "#D5D5D5",
  "--Color-gray-8": "#D9D9D9",
  "--Color-gray-9": "#A5A5A5"
};

const baseVars = {
  ...colorPalette,
  "--Border-radius": "4px"
};

const themes = {
  profinda: {}, // profind theme is now the same as the default theme!
  kpmg: kpmgVariables,
  deloitte: deloitteVariables
};

export const getBaseVars = () => baseVars;

export const getTheme = (
  theme: string = "profinda",
  themeConfig: Record<string, string> = {}
): Record<string, string> => {
  loadDesignTokens();

  const vars = {
    ...baseVars,
    ...themes[theme],
    ...themeConfig
  };

  const makeOpacityVariants = (colorKey) => {
    for (let alpha = 1; alpha <= 9; alpha++) {
      vars[`${colorKey}-opacity-0-${alpha}`] = Color(vars[colorKey])
        .alpha(0.1 * alpha)
        .toString();
    }
  };

  const makeLightenedVariants = (colorKey) => {
    for (let ratio = 2; ratio <= 8; ratio += 2) {
      vars[`${colorKey}-lighten-${ratio}`] = Color(vars[colorKey])
        .lighten(0.1 * ratio)
        .hex();
    }
  };

  makeOpacityVariants("--Palette-base");
  makeOpacityVariants("--Palette-base-main");
  makeOpacityVariants("--Palette-base-mid");
  makeOpacityVariants("--Palette-base-light");
  makeOpacityVariants("--Palette-shadow");
  makeOpacityVariants("--Palette-neutral");
  makeOpacityVariants("--Palette-status-base");
  makeOpacityVariants("--Palette-action");

  makeLightenedVariants("--Palette-base-light");
  makeLightenedVariants("--Palette-info");

  const primaryColors = ["primary", "secondary", "secondary", "highlight", "success"];
  primaryColors.forEach((colorName) => {
    const color = Color(vars[`--Color-${colorName}`]);

    if (color.isLight()) {
      vars[`--Color-${colorName}-transparent`] = color.alpha(0.8).toString();
      vars[`--Color-${colorName}-2`] = color.darken(0.1).hex();
      vars[`--Color-${colorName}-3`] = color.darken(0.3).hex();
      vars[`--Color-${colorName}-4`] = color.darken(0.6).hex();
      vars[`--Color-${colorName}-4-transparent`] = color.darken(0.6).alpha(0.8).toString();
    } else {
      vars[`--Color-${colorName}-transparent`] = color.alpha(0.8).toString();
      vars[`--Color-${colorName}-2`] = color.lighten(0.1).hex();
      vars[`--Color-${colorName}-3`] = color.lighten(0.3).hex();
      vars[`--Color-${colorName}-4`] = color.lighten(0.6).hex();
      vars[`--Color-${colorName}-4-transparent`] = color.lighten(0.6).alpha(0.8).toString();
    }
  });

  const defaultsTo = (name, value) => {
    vars[name] = vars[name] || value;
  };

  // <LandingLayout />
  defaultsTo("--LandingLayout-bg", vars["--Palette-base"]);

  // <ProfileShowPage>'s sidebar
  defaultsTo("--SidePanel-bg", "#fff");

  // <AvailabilityPeriod>
  defaultsTo("--AvailabilityPeriod-fg", vars["--Color-gray-1"]);
  defaultsTo("--AvailabilityPeriod-remove-fg", vars["--Color-gray-2"]);

  // <Button>
  defaultsTo("--Button-border-radius", "50px");

  // <Button> kind:primary
  defaultsTo("--Button-primary-fg", vars["--Palette-base"]);
  defaultsTo("--Button-primary-bg", vars["--Palette-action"]);
  defaultsTo("--Button-primary-fg-hover", "#fff");
  defaultsTo("--Button-primary-bg-hover", vars["--Palette-base-light"]);
  defaultsTo("--Button-primary-border-color", "transparent");

  // <Button> kind:landing-blue
  defaultsTo("--Button-landing-blue-fg", "#fff");
  defaultsTo("--Button-landing-blue-bg", vars["--Palette-base-main"]);
  defaultsTo("--Button-landing-blue-fg-hover", "#fff");
  defaultsTo("--Button-landing-blue-bg-hover", vars["--Palette-base-light"]);
  defaultsTo("--Button-landing-blue-border-color", "transparent");

  // <Button> kind:green
  defaultsTo("--Button-green-bg", "#00BB44");

  // <Button> - legacy:
  defaultsTo("--Button-radius", vars["--Border-radius"]);

  // <Onboarding>
  defaultsTo("--Onboarding-bg", vars["--Color-primary-4-transparent"]);

  // <SmartSuggestions>
  defaultsTo("--SmartSuggestions-bg", vars["--Color-secondary"]);

  // <Sidebar>
  defaultsTo("--Sidebar-counter", vars["--Palette-warning"]);
  defaultsTo("--Sidebar-avatar-progress", vars["--Palette-warning"]);

  // <CarouselCard>
  defaultsTo("--CarouselCard-arrow-bg", vars["--Palette-base-light-opacity-0-3"]);
  defaultsTo("--CarouselCard-arrow-bg-hover", vars["--Palette-base-light-opacity-0-6"]);

  // <Chat>
  defaultsTo("--ChatList-header-fg", "#fff");
  defaultsTo("--ChatList-header-bg", vars["--Palette-base-main"]);

  // <ScrollableBadgesRow>
  defaultsTo("--ScrollableBadgesRow-item-bg-hover", vars["--Palette-status-base"]);

  // <SkillRank>
  defaultsTo("--SkillRank-primary", vars["--Palette-action"]);

  // <DevelopSkills>
  defaultsTo("--DevelopSkills-bg", vars["--Palette-base-main"]);

  // <CardTx>
  defaultsTo("--CardTx-bubble-bg", vars["--Palette-base-light"]);
  defaultsTo("--CardTx-event-bg", vars["--Palette-base"]);

  // Workflow states
  defaultsTo("--WorkflowState-booked", "#1F78B4");
  defaultsTo("--WorkflowState-filled", vars["--Palette-base-main"]);
  defaultsTo("--WorkflowState-partially-filled", vars["--Palette-base-main-opacity-0-5"]);
  defaultsTo("--WorkflowState-shortlisting", vars["--Palette-action"]);
  defaultsTo("--WorkflowState-auto-shortlisting", vars["--Palette-action-0-3"]);
  defaultsTo("--WorkflowState-manual-shortlisting", vars["--Palette-action-0-5"]);
  defaultsTo("--WorkflowState-not-filled", vars["--Palette-base-main-opacity-0-5"]);
  defaultsTo("--WorkflowState-auto-filling", vars["--Palette-base-main-opacity-0-3"]);

  defaultsTo("--SimpleBadge-primary-background", vars["--Color-secondary"]);
  if (Color(vars["--SimpleBadge-primary-background"]).isLight()) {
    defaultsTo("--SimpleBadge-primary-text", vars["--Color-gray-1"]);
  } else {
    defaultsTo("--SimpleBadge-primary-text", vars["--Color-gray-6"]);
  }
  defaultsTo("--SimpleBadge-topSkillBadge-borderColor", vars["--SimpleBadge-primary-background"]);

  defaultsTo("--SimpleBadge-primaryColor-background", vars["--Color-primary"]);
  if (Color(vars["--SimpleBadge-primaryColor-background"]).lightness() > 60) {
    defaultsTo("--SimpleBadge-primaryColor-text", vars["--Color-gray-1"]);
  } else {
    defaultsTo("--SimpleBadge-primaryColor-text", vars["--Color-gray-6"]);
  }

  defaultsTo("--SimpleBadge-secondary-background", vars["--Color-secondary-2"]);
  if (Color(vars["--SimpleBadge-secondary-background"]).isLight()) {
    defaultsTo("--SimpleBadge-secondary-text", vars["--Color-gray-1"]);
  } else {
    defaultsTo("--SimpleBadge-secondary-text", vars["--Color-gray-6"]);
  }

  defaultsTo("--SimpleBadge-text", vars["--Color-gray-1"]);
  defaultsTo("--SimpleBadge-border-radius", "15px");
  defaultsTo("--SimpleBadge-background", vars["--Color-secondary"]);
  defaultsTo("--SimpleBadge-highlight-background", vars["--Color-highlight"]);
  defaultsTo("--SimpleBadge-faded-background", vars["--Color-secondary-transparent"]);
  defaultsTo("--SimpleBadge-faded-text", vars["--Color-gray-1"]);
  defaultsTo("--SimpleBadge-disabled-background", "#f4f4f4");
  defaultsTo("--SimpleBadge-disabled-text", vars["--Color-gray-3"]);

  return vars;
};
