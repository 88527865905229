import classNames from "classnames";
import { Icon } from "PFComponents/icon";

import css from "./experience_radial.module.scss";

export enum ExperienceColorVariant {
  Neutral = "neutral",
  Active = "active",
  Missing = "missing",
  Extra = "extra"
}

export type CustomColors = Partial<Record<ExperienceColorVariant, string>>;

export type ExperienceRadialProps = {
  colorVariants: [ExperienceColorVariant, ExperienceColorVariant, ExperienceColorVariant];
  icon?: "arrow-up" | "arrow-down" | "cross" | "check" | null;
  dark?: boolean;
};

const getIconColor = (dark: boolean, isCrossIcon: boolean) => {
  if (dark) {
    return isCrossIcon ? "paletteRed3" : "paletteWhite0";
  }

  return isCrossIcon ? "paletteRed0" : "paletteBlue0";
};

export const ExperienceRadial = ({
  colorVariants,
  icon = null,
  dark = false
}: ExperienceRadialProps): JSX.Element => (
  <div className={css.circle}>
    <div className={classNames(css.segment, css.first, css[colorVariants[0]], { [css.dark]: dark })} />
    <div className={classNames(css.segment, css.second, css[colorVariants[1]], { [css.dark]: dark })} />
    <div className={classNames(css.segment, css.third, css[colorVariants[2]], { [css.dark]: dark })} />
    {icon && (
      <Icon name={icon} size="xxs" color={getIconColor(dark, icon === "cross")} className={css.icon} />
    )}
  </div>
);
