import first from "lodash/first";
import {
  MatchesNamedResourcesSelector,
  Option,
  ProfilesSelector
} from "PFApp/components/matches_named_resources_selector";
import { useGrowl } from "PFApp/use_growl";
import { Divider } from "PFComponents/divider";
import { Icon } from "PFComponents/icon";
import { useActivityEconomicsScenarioAssign } from "PFCore/hooks/queries/activity";
import {
  fetchScenarioMatchesOptions,
  fetchScenarioProfilesOptions
} from "PFCore/services/activity/activity_economics_profiles";
import { EconomicsVacancy, EconomicsVacancyProfile, Id } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCostFieldLabel } from "../../use_cost_field_label";
import css from "./assignments_form.module.scss";

type AssignmentsFormProps = {
  emptyVacancies: EconomicsVacancy[];
  scenarioId: Id;
  onAssign: (vacancyId: Id, profile: EconomicsVacancyProfile) => void;
};

export const AssignmentsForm = ({ emptyVacancies, scenarioId, onAssign }: AssignmentsFormProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.economics" });
  const { mutate: assignVacancy } = useActivityEconomicsScenarioAssign(scenarioId);
  const costFieldLabel = useCostFieldLabel();

  const [profilesSelector, setProfilesSelector] = useState<ProfilesSelector>(ProfilesSelector.NamedResource);

  const handleAssign = (profiles: Option[], vacancyId: Id) => {
    const profileId = first(profiles)?.id;
    if (!profileId) {
      return;
    }

    assignVacancy(
      { profileId, vacancyId },
      {
        onSuccess: (response) => {
          onAssign(vacancyId, response!.profile as EconomicsVacancyProfile);
          growl({
            kind: "success",
            message: t("scenario.assignSuccess")
          });
        },
        onError: () => {
          growl({
            kind: "error",
            message: t("scenario.assignError")
          });
        }
      }
    );
  };

  const renderFormRow = (vacancy: EconomicsVacancy, index) => (
    <>
      <div className={css.formRow}>
        <Icon name="warning" size="md" className={css.icon} />
        <MatchesNamedResourcesSelector
          profilesSelector={profilesSelector}
          onProfilesSelectorChange={setProfilesSelector}
          onNamedResourcesChange={(profiles) => handleAssign(profiles, vacancy.id)}
          onMatchesChange={(profiles) => handleAssign(profiles, vacancy.id)}
          fetchNamedResourcesOptions={(term) =>
            fetchScenarioProfilesOptions({ activityId: vacancy.activity.id, scenarioId, term })
          }
          fetchMatchesOptions={(term) =>
            fetchScenarioMatchesOptions({ activityId: vacancy.activity.id, scenarioId, term })
          }
          matchesDisabled={!vacancy.activity.matchedAt}
          matchesNotReady={!vacancy.activity.matchedAt}
          classes={{ select: css.select, namedResourceDropdown: css.namedResourceDropdown }}
          costField={{
            label: costFieldLabel,
            getValue: (option) => option.grade
          }}
        />
      </div>
      {index !== emptyVacancies.length - 1 && <Divider />}
    </>
  );

  return <div className={css.form}>{emptyVacancies.map(renderFormRow)}</div>;
};
