import { trim } from "lodash";
import { Option } from "PFApp/components/matches_named_resources_selector";
import { Collection, Id } from "PFTypes";

import api from "../../api";
import { getProfileName } from "../../helpers/profile";

type ScenarioOptionsParams = { activityId: Id; scenarioId: Id; term?: string };

export const fetchScenarioProfilesOptions = ({ activityId, scenarioId, term }: ScenarioOptionsParams) =>
  api<Collection<Option[]>>({
    url: `options/engagements/scenarios/${scenarioId}/profiles`,
    params: {
      activityId,
      ...(term ? { term: trim(term) } : {})
    }
  }).then((response) => ({
    ...response,
    entries: response.entries.map((option) => ({
      ...option,
      text: getProfileName(option)
    }))
  }));

export const fetchScenarioMatchesOptions = ({ activityId, scenarioId, term }: ScenarioOptionsParams) =>
  api<Collection<Option[]>>({
    url: `options/engagements/scenarios/${scenarioId}/matches`,
    params: {
      activityId,
      ...(term ? { term: trim(term) } : {})
    }
  }).then((response) => ({
    ...response,
    entries: response.entries.map((option) => ({
      ...option,
      text: getProfileName(option)
    }))
  }));
