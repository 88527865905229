import { each } from "lodash";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { gidFor } from "PFCore/helpers/gid";
import { getProfileName } from "PFCore/helpers/profile";
import useClickOutside from "PFCore/helpers/use_click_outside";
import { GIdTargetType } from "PFTypes";
import { ForwardedRef, forwardRef, ReactNode, useImperativeHandle, useRef, useState } from "react";
import { usePopper } from "react-popper";

import DropDown from "../../dropdown/dropdown";
import { TextAreaProps } from "../text_area";
import css from "./chat_input_field_set.module.scss";
import { useAutcompleteData } from "./use_autocomplete_data";
import { useChatInputFieldSet } from "./use_chat_input_field_set";

export type ChatInputFieldSetProps = Omit<TextAreaProps, "value"> & {
  value?: string;
  profileId: number;
  conversationId: number;
  displayEmojis?: boolean;
  displayMentions?: boolean;
  displayAbove?: boolean;
  minVisibleMaxLengthFactor?: number;
  maxLengthLabelClassName?: string;
  controlledValue?: boolean;
  actions?: ReactNode;
};

type DropdownRef = {
  handleKeyUp?: (event: KeyboardEvent) => void;
  handleKeyDown?: (event: KeyboardEvent) => void;
};

export const ChatInputFieldSet = forwardRef(
  (
    {
      profileId,
      conversationId,
      displayAbove,
      displayEmojis = true,
      displayMentions,
      onBlur,
      onChange,
      onClick,
      onKeyUp,
      onKeyDown,
      value,
      actions,
      ...props
    }: ChatInputFieldSetProps,
    ref: ForwardedRef<{ toggleEmojIsOpen: () => void }>
  ) => {
    const [selection, setSelection] = useState({ selectionStart: 0, selectionEnd: 0 });

    const inputRef = useRef<HTMLTextAreaElement>(null);
    const dropdownRef = useRef<DropdownRef>({});

    const { autoCompleteDropdowns, mentions, autoCompleteReplace } = useAutcompleteData({
      displayEmojis,
      displayMentions,
      profileId,
      conversationId,
      value
    });
    const { dropdownOptions, closeDropdown, dropdownTrigger, toggleEmojIsOpen, setEmojIsOpen } =
      useChatInputFieldSet({
        autoCompleteDropdowns,
        value,
        selection,
        inputRef
      });

    const updateSelectionFromTarget = (target) => {
      const { selectionStart, selectionEnd } = target;
      setSelection({ selectionStart, selectionEnd });
    };

    const postReplace = (value) => {
      const sortedMentions = Object.keys(mentions).sort((keyA, keyB) => keyB.length - keyA.length);

      sortedMentions.forEach((key) => {
        const mention = mentions[key];
        const gid = gidFor(GIdTargetType.Profile, mention.id);
        const mentionDisplay = getProfileName(mention);

        value = value.split(`@${key}`).join(`@[${mentionDisplay}](${gid})`);
      });

      return value;
    };

    const applyChange = ({ value, selectionStart, selectionEnd }) => {
      let head = value.substr(0, selectionStart);
      let body = value.substr(selectionStart, selectionEnd - selectionStart);
      let tail = value.substr(selectionEnd, value.length - selectionEnd);

      //Replace
      each(autoCompleteReplace, (replaceLogic) => {
        head = head.replace(replaceLogic.pattern, replaceLogic.replace);
        body = body.replace(replaceLogic.pattern, replaceLogic.replace);
        tail = tail.replace(replaceLogic.pattern, replaceLogic.replace);
      });

      const nextValue = head + body + tail;

      setSelection({
        selectionStart: head.length,
        selectionEnd: head.length + body.length
      });

      onChange?.(nextValue, postReplace(nextValue));
    };

    const handleClick = (event) => {
      updateSelectionFromTarget(event.target);

      onClick?.(event);
    };

    const handleKeyDown = (event) => {
      dropdownRef.current?.handleKeyDown?.(event);

      onKeyDown?.(event);
    };

    const handleKeyUp = (event) => {
      updateSelectionFromTarget(event.target);

      onKeyUp?.(event);
    };

    const handleChange = (value, event) => {
      const { selectionStart, selectionEnd } = event.target;
      setEmojIsOpen(false);
      applyChange({ value, selectionStart, selectionEnd });
    };

    const handleBlur = (event) => {
      closeDropdown();

      onBlur?.(event);
    };

    const handleDropdownClick = (item) => {
      const { selectionStart, selectionEnd } = selection;

      const head = value?.substr(0, selectionStart).replace(dropdownTrigger || "", "") + item();
      const tail = value?.substr(selectionEnd, value.length - selectionEnd);

      const nextValue = head + tail;

      const newSelection = { selectionStart: head.length, selectionEnd: head.length };
      setSelection(newSelection);
      closeDropdown();
      inputRef.current?.focus();

      applyChange({ value: nextValue, ...newSelection });
    };

    useImperativeHandle(ref, () => ({
      toggleEmojIsOpen
    }));

    const containerRef = useRef(null);
    useClickOutside(containerRef, handleBlur);

    const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
      placement: "auto-end",
      strategy: "fixed"
    });

    return (
      <div ref={containerRef} className={css.inputTextInner} style={{ position: "relative" }}>
        <div ref={setPopperReferenceElement} className={css.inputInner}>
          <InputFieldSet
            {...props}
            ref={inputRef}
            value={value}
            onChange={handleChange}
            onClick={handleClick}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            maxHeight={80}
            inputType="textarea"
          />
        </div>
        <div className={css.inputActions} style={{ right: 0 }}>
          {actions}
        </div>
        {dropdownOptions.length > 0 && (
          <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            <DropDown
              style={{ margin: 0 }}
              ref={dropdownRef}
              options={dropdownOptions}
              selectedIndex={0}
              displayAbove={!!displayAbove}
              handleChange={handleDropdownClick}
              handleClose={closeDropdown}
            />
          </div>
        )}
      </div>
    );
  }
);

ChatInputFieldSet.displayName = "ChatInputFieldSet";
