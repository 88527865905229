import { Checkbox } from "PFComponents/checkbox";

import css from "./table_bulk_select.module.scss";

type BulkSelectProps = {
  isAllSelected: boolean;
  isPartiallySelected: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
};

export const BulkSelect = ({ isAllSelected, isPartiallySelected, onChange }: BulkSelectProps) => (
  <div className={css.mainContainer}>
    <Checkbox
      checked={isAllSelected}
      onChange={(val, ev) => onChange(ev)}
      indeterminate={isPartiallySelected}
    />
  </div>
);
