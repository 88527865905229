import { Icon } from "PFComponents/icon";
import { Slider } from "PFComponents/slider";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "../../tooltip/tooltip";
import { Typography } from "../../typography";
import css from "./threshold_slider.module.scss";

enum ThresholdCap {
  Min = 50,
  Max = 80
}

type ThresholdSliderProps = {
  threshold: number;
  actions?: ReactNode;
  disabled?: boolean;
  tooltip?: string;
  onChange: (threshold: number) => void;
};

export const ThresholdSlider = ({
  threshold = ThresholdCap.Min,
  actions,
  disabled = false,
  tooltip,
  onChange
}: ThresholdSliderProps) => {
  const { t } = useTranslation("translation");

  const showToLowValueWarning = threshold <= ThresholdCap.Min;
  const showToHighValueWarning = threshold >= ThresholdCap.Max;

  return (
    <>
      <div className={css.wrapper}>
        {tooltip && (
          <Tooltip
            content={
              <Typography variant="bodyRegular" noMargin>
                {tooltip}
              </Typography>
            }
          >
            {/* Tooltip should not wrap slider to avoid its rerender (visual issues) */}
            <div className={css.tooltip} />
          </Tooltip>
        )}
        <Slider
          title={t("availabilityRequirement.description")}
          min={0}
          max={100}
          step={10}
          unit="%"
          onChange={onChange}
          value={threshold}
          actions={actions}
          disabled={disabled}
        />
      </div>
      {(showToLowValueWarning || showToHighValueWarning) && (
        <div className={css.warning}>
          <Icon name="warning" color="paletteOrange0" size="sm" />
          {showToLowValueWarning && t("availabilityRequirement.lowThresholdWarning")}
          {showToHighValueWarning && t("availabilityRequirement.highThresholdWarning")}
        </div>
      )}
    </>
  );
};
