import isEmpty from "lodash/isEmpty";
import uniqueId from "lodash/uniqueId";
import { ActionIcon } from "PFComponents/action_icon";
import { SyntheticEvent } from "react";

import css from "./action_icons.module.scss";
import { ActionIconData } from "./use_action_icons";

type ActionIconsProps = {
  actionIcons: ActionIconData[];
};

export const ActionIcons = ({ actionIcons }: ActionIconsProps) => {
  if (isEmpty(actionIcons)) {
    return null;
  }

  const onKeyDown = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  return (
    <div className={css.root}>
      {actionIcons.map(({ icon, onClick, title, disabled }) => (
        <ActionIcon
          disabled={disabled}
          key={uniqueId(icon)}
          name={icon}
          size="sm"
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
          onKeyDown={onKeyDown}
          color="paletteBlue0"
          title={title}
          aria-label={title}
        />
      ))}
    </div>
  );
};
