import { isNil } from "lodash";
import compact from "lodash/compact";
import filter from "lodash/filter";
import first from "lodash/first";
import includes from "lodash/includes";
import { Profile } from "PFTypes";
import { RequireAtLeastOne } from "type-fest";

enum ProfileRoles {
  Admin = "admin",
  SaasAdmin = "saas_admin"
}

export type ObjectWithProfileName = RequireAtLeastOne<{
  first_name: string;
  firstName: string;
}> &
  Partial<{
    lastName: string | null;
    last_name: string | null;
  }> & {
    [key: string]: any;
  };

export const getProfileName = (profile?: Partial<ObjectWithProfileName> | null | undefined): string => {
  const firstName = profile?.firstName || profile?.first_name;
  const lastName = profile?.lastName || profile?.last_name;

  return compact([firstName, lastName]).join(" ");
};

export const getMainPosition = (profile: Pick<Profile, "positions">) => {
  const positions = profile.positions || [];
  return filter(positions, "main")[0] || {};
};

export const getJobTitle = (profile: Pick<Profile, "positions">) => {
  const { jobTitle, job_title } = getMainPosition(profile);
  return first(jobTitle || job_title)?.value || null;
};

export const isAdmin = (profile?: Profile) => {
  if (!profile) {
    return false;
  }

  return includes(profile.roles, ProfileRoles.Admin);
};

export const isSaasAdmin = (profile?: Profile) => {
  if (!profile) {
    return false;
  }

  return includes(profile.roles, ProfileRoles.SaasAdmin);
};

export const isProfileActive = (profile?: Partial<Profile>): boolean => {
  if (!profile) {
    return false;
  }

  // We assume that profile is active in case of no data (e.g. /api/options/profiles)
  if (isNil(profile.status) && isNil(profile.active)) {
    return true;
  }

  // Current Profile doesn't have status, but has active boolean
  return profile.status === "active" || !!profile.active;
};
