import { isEmpty } from "lodash";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import {
  AvailabilityFilterItemCompressed,
  useAvailabilityState
} from "PFApp/components/filters/filter_items";
import { getFilterDefault, letFilterRestore } from "PFApp/use_filtered_collection";
import { Accordion } from "PFComponents/accordion";
import { AvailabilityRule, FilterWithDefaults, StandardFilter } from "PFTypes";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import commonCss from "../../side_filters.module.scss";
import { AccordionHeader } from "../accordion_header";
import { AvailabilitySidePanel } from "./availability_side_panel";

type AvailabilityFilterProps = {
  disabled: boolean;
};

const AvailabilityFilterContent = ({ disabled }: AvailabilityFilterProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });
  const template = useActivityTemplate();
  const [isAvailabilityPanelOpen, toggleIsAvailabilityPanelOpen] = useToggle(false);

  const { meta, handleFilterChange: onFilterChange, isEdited } = useFiltersContext();

  const metaAvailabilityFilterWithDefault: FilterWithDefaults = useMemo(
    () => ({
      ...(meta?.filters?.availability as StandardFilter),
      defaultValue: getFilterDefault(meta?.filters?.availability, meta)
    }),
    [meta]
  );

  const scope = (meta?.counters?.filtered_profiles_count as number) || 0;

  const handleFilterChange = useCallback(
    (value: AvailabilityRule | null) => {
      onFilterChange?.(metaAvailabilityFilterWithDefault!, value, { children: false });
    },
    [metaAvailabilityFilterWithDefault, onFilterChange]
  );

  const { availability, updateAvailability, restoreAvailability, cancelAvailabilityChanges } =
    useAvailabilityState(metaAvailabilityFilterWithDefault, handleFilterChange);

  useEffect(() => {
    if (!isEdited) {
      // We need to react on Cancel button click and reset availability state to initial value
      cancelAvailabilityChanges();
    }
  }, [isEdited, cancelAvailabilityChanges]);

  const handleRestore = useCallback(
    (e) => {
      e.stopPropagation();
      restoreAvailability();
    },
    [restoreAvailability]
  );

  const handleClear = useCallback(
    (e) => {
      e.stopPropagation();
      updateAvailability(null);
    },
    [updateAvailability]
  );

  const currentAvailabilityFilterWithDefault: FilterWithDefaults = {
    ...metaAvailabilityFilterWithDefault,
    value: availability as AvailabilityRule
  };

  const canRestoreAvailability = letFilterRestore(currentAvailabilityFilterWithDefault);
  const availabilityIsClear = isEmpty(currentAvailabilityFilterWithDefault.value);

  return (
    <section className={commonCss.section}>
      <Accordion
        header={
          <AccordionHeader
            title={t("availability")}
            canClear={!availabilityIsClear}
            canRestore={canRestoreAvailability}
            disabled={disabled}
            clearTitle={t("clearAvailabilityFilter")}
            restoreTitle={t("restoreAvailabilityFilter")}
            onClear={handleClear}
            onRestore={handleRestore}
          />
        }
        headerVariant="bodyBold"
        headerFullWidth
        iconSet="chevron"
        animated={false}
      >
        <AvailabilityFilterItemCompressed
          availability={currentAvailabilityFilterWithDefault.value as AvailabilityRule}
          scope={scope}
          templateKey={template!.key}
          disabled={disabled}
          onAvailabilityChange={updateAvailability}
          onEditAvailability={toggleIsAvailabilityPanelOpen}
        />
      </Accordion>
      <AvailabilitySidePanel
        show={isAvailabilityPanelOpen}
        availabilityFilterWithDefault={currentAvailabilityFilterWithDefault}
        disabled={disabled}
        onRestore={handleRestore}
        onClear={handleClear}
        onClose={() => toggleIsAvailabilityPanelOpen(false)}
        onAvailabilityChange={updateAvailability}
      />
    </section>
  );
};

export const AvailabilityFilter = ({ disabled }: AvailabilityFilterProps) => {
  const { meta } = useFiltersContext();
  const template = useActivityTemplate();

  if (!template || !meta?.filters?.availability) {
    return null;
  }

  return <AvailabilityFilterContent disabled={disabled} />;
};
