import { Typography } from "PFComponents/typography";
import { PropsWithChildren } from "react";

import css from "./h5.module.scss";

export const H5 = ({ children }: PropsWithChildren) => (
  <Typography className={css.h5} variant="h5" noMargin>
    {children}
  </Typography>
);
