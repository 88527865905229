import classNames from "classnames";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import Toggle from "PFComponents/toggle/toggle";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import { PageTarget } from "PFTypes/saved_filters";
import { useTranslation } from "react-i18next";

import { useFiltersContext } from "../context/filters_context";
import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import css from "./filters_panel.module.scss";

type FiltersHeaderProps = {
  className?: string;
  showTitle?: boolean;
  disabled?: boolean;
  disabledLabel?: string;
  letRestoreAll?: boolean;
  restoreAllTooltipContent?: React.ReactNode;
  lastFocusedFilterNameRef?: React.MutableRefObject<string | null>;
  viewsKey?: PageTarget | null;
};

export const FiltersHeader = ({
  className,
  disabled,
  disabledLabel,
  letRestoreAll,
  restoreAllTooltipContent,
  lastFocusedFilterNameRef,
  viewsKey
}: FiltersHeaderProps) => {
  const { t } = useTranslation("translation");

  const {
    meta,
    setIsAutoRefresh,
    isAutoRefresh,
    noFiltersSelected: filtersAreClear,
    clearFilters
  } = useFiltersContext();

  const { setSelectedViewId } = useSavedViewsContext(viewsKey);

  return (
    <div className={classNames(css.header, className)}>
      <div className={css.headerText}>
        <Typography variant="h4" noMargin>
          {t("filters.filters")}
        </Typography>
        {disabled && disabledLabel && <Typography variant="labelRegular">{disabledLabel}</Typography>}
      </div>

      {!filtersAreClear && (
        <Button
          className={css.actionAllButton}
          kind="text"
          onClick={() => {
            if (lastFocusedFilterNameRef?.current) {
              lastFocusedFilterNameRef.current = null;
            }
            setSelectedViewId?.(null);
            clearFilters?.();
          }}
        >
          <Icon name="filter-clean" />
          <span>{t("filters.clearAll")}</span>
        </Button>
      )}

      {letRestoreAll && (
        <>
          <Button
            icon="history"
            text={t("filters.restoreAll")}
            className={css.actionAllButton}
            kind="text"
            onClick={() => {
              clearFilters?.(meta?.default_filters);
            }}
          />
          {restoreAllTooltipContent && (
            <TooltipIcon
              content={restoreAllTooltipContent}
              IconComponent={(props) => <Icon name="info" {...props} />}
              width={20}
              height={20}
            />
          )}
        </>
      )}
      <Toggle
        inline
        className={css.autoRefreshToggle}
        label={t("filters.autoRefresh")}
        checked={isAutoRefresh}
        onChange={(value) => setIsAutoRefresh(value)}
        reversed
        compact
      />
    </div>
  );
};
