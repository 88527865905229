import classNames from "classnames";
import { ExperienceRadial, useExperienceRadial } from "PFApp/components/experience_radial";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { useSortingOptions } from "../hooks/use_sorting_options";
import css from "../skills_modal.module.scss";
import { DraggableSkill } from "./draggable_skill";

const experienceToTextMap = {
  [Experience.Basic]: "basic",
  [Experience.Intermediate]: "intermediate",
  [Experience.Advanced]: "advanced"
};

export const Header = ({ experience, skills, skillsSortingSelected, setSkillsSortingSelected }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  const { colorVariants } = useExperienceRadial({ experience });
  const { skillsSortingOptions } = useSortingOptions();
  const experienceLabelMap = useExperienceLabelMap();

  return (
    <div className={classNames(css.header, css[experienceToTextMap[experience]])}>
      <div className={css.title}>
        <span className={css.experienceName}>
          <ExperienceRadial colorVariants={colorVariants} dark />
          <Typography variant="h4" noMargin clipOverflow>
            {t(`skillsModal.rating.${experienceToTextMap[experience]}.name`, { count: skills.length })}
          </Typography>
        </span>
        <DropdownButton
          actionIcon="sort"
          actionIconColor="paletteWhite0"
          small
          options={skillsSortingOptions}
          handleChange={(item) =>
            setSkillsSortingSelected({
              ...skillsSortingSelected,
              [experience]: skillsSortingOptions[item]
            })
          }
          dropdownProps={{
            selectedIndex: skillsSortingSelected[experience]?.item
          }}
          aria-label={t("skillsModal.changeExperienceSorting", {
            experience: experienceLabelMap[experience]
          })}
        />
      </div>
      <Typography variant="labelRegular" tag="span" className={css.info}>
        {t(`skillsModal.rating.${experienceToTextMap[experience]}.info`)}
      </Typography>
    </div>
  );
};

Header.propTypes = {
  experience: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsSortingSelected: PropTypes.object.isRequired,
  setSkillsSortingSelected: PropTypes.func.isRequired
};

export const Skills = ({ experience, skills, addToLog, coreSkillsCount, developmentalSkillsCount }) => (
  <div className={classNames(css.skills, css[experienceToTextMap[experience]])}>
    <Droppable droppableId={String(experience)}>
      {(provided) => (
        <div className={css.droppable} ref={provided.innerRef} {...provided.droppableProps}>
          <div className={css.skillsInner}>
            {skills.map((skill, index) => (
              <DraggableSkill
                key={skill.id}
                skill={skill}
                index={index}
                addToLog={addToLog}
                coreSkillsCount={coreSkillsCount}
                developmentalSkillsCount={developmentalSkillsCount}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </div>
);

Skills.propTypes = {
  experience: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  addToLog: PropTypes.func.isRequired,
  coreSkillsCount: PropTypes.number.isRequired,
  developmentalSkillsCount: PropTypes.number.isRequired
};

const SkillsGrid = ({
  basicSkills,
  intermediateSkills,
  advancedSkills,
  skillsSortingSelected,
  setSkillsSortingSelected,
  addToLog,
  coreSkillsCount,
  developmentalSkillsCount
}) => (
  <div className={css.skillRatingGrid}>
    {[
      { experience: Experience.Basic, skills: basicSkills },
      { experience: Experience.Intermediate, skills: intermediateSkills },
      { experience: Experience.Advanced, skills: advancedSkills }
    ].map(({ experience, skills }) => (
      <>
        <Header
          key={experience}
          experience={experience}
          skills={skills}
          skillsSortingSelected={skillsSortingSelected}
          setSkillsSortingSelected={setSkillsSortingSelected}
          addToLog={addToLog}
        />
        <Skills
          key={experience}
          experience={experience}
          skills={skills}
          addToLog={addToLog}
          coreSkillsCount={coreSkillsCount}
          developmentalSkillsCount={developmentalSkillsCount}
        />
      </>
    ))}
  </div>
);

SkillsGrid.propTypes = {
  basicSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  intermediateSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  advancedSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsSortingSelected: PropTypes.object.isRequired,
  setSkillsSortingSelected: PropTypes.func.isRequired,
  addToLog: PropTypes.func.isRequired,
  coreSkillsCount: PropTypes.number.isRequired,
  developmentalSkillsCount: PropTypes.number.isRequired
};

export default SkillsGrid;
