import { has } from "lodash";
import {
  AvailabilityRule,
  DateWithRangesValue,
  DateWithSingleRangeValue,
  Filter,
  IntegerValue,
  OptionsFilter,
  PeriodRange,
  Value
} from "PFTypes";

const isObject = (value: any): value is object => typeof value === "object";

export const isAvailabilityRule = (value: any): value is AvailabilityRule =>
  value && isObject(value) && "mode" in value && "ranges" in value && "availability_threshold" in value;

export const isDateWithRangesValue = (value: any): value is DateWithRangesValue =>
  value && isObject(value) && "mode" in value && "ranges" in value;

export const isDateWithSingleRangeValue = (value: any): value is DateWithSingleRangeValue | PeriodRange =>
  value && isObject(value) && "start" in value && "end" in value;

export const filterValueIsInteger = (filterValue: Value): filterValue is IntegerValue =>
  has(filterValue, "lte") || has(filterValue, "gte");

export const isFilterOptionsFilter = (filter: Filter): filter is OptionsFilter => has(filter, "options");
