import { useDetailsPanelApiContext } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context";
import { activityTypeToSidePanelTypeMap } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context_provider";
import { useActivityLink } from "PFApp/booking/parts/overview/detail_view/booking_detail/use_activity_link";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import ExternalLinkIcon from "PFIcons/external_link.svg";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BookingDetailItem } from "../booking_detail_item";
import { EntityIcon } from "../entity_icon";
import css from "./booking_detail_activity.module.scss";

export const activityTypeTranslationKeys = {
  [TemplateKey.Role]: "role",
  [TemplateKey.AuditRole]: "auditRole",
  [TemplateKey.Engagement]: "engagement",
  [TemplateKey.AuditEngagement]: "auditEngagement"
} as const;

interface BookingDetailsActivityProps {
  activity?: Activity;
  parentActivity?: Activity;
  isLoading?: boolean;
}

export const BookingDetailActivity = ({
  activity,
  parentActivity,
  isLoading
}: BookingDetailsActivityProps): JSX.Element | null => {
  const { t } = useTranslation("translation");
  const { openDetailsPanel } = useDetailsPanelApiContext();

  const { name, template_id: templateId } = activity || {};
  const { key: templateKey } = useTemplate(templateId) || {};

  const linkUrl = useActivityLink({ activity, parentActivity });

  const openActivitySidePanel = () => {
    activity?.id &&
      templateKey &&
      openDetailsPanel(
        {
          id: activity.id,
          type: activityTypeToSidePanelTypeMap[templateKey]
        },
        true
      );
  };

  if (!activity) {
    return null;
  }

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <BookingDetailItem
      label={templateKey ? t(activityTypeTranslationKeys[templateKey]) : ""}
      value={
        <>
          <EntityIcon templateId={templateId} type={templateKey || ""} />
          {name && (
            <Button kind="text" className={css.activityName} onClick={openActivitySidePanel}>
              {name}
            </Button>
          )}
          {linkUrl && (
            <Link to={linkUrl} className={css.activityLink}>
              <ExternalLinkIcon height={20} width={20} className={css.icon} />
            </Link>
          )}
        </>
      }
    />
  );
};
