import { MatchesShortlistColumnId } from "PFApp/activities/types/matches_shortlist_column_id";
import { Importance, Match, Shortlist } from "PFTypes";

export const TABLE_SIZES = {
  match: 60,
  availability: 60,
  workforceMember: 200,
  skills: 130,
  customValue: 150,
  actions: 120
};

export const SKILLS_IMPORTANCE_COLUMNS_DATA = [
  {
    id: MatchesShortlistColumnId.SupportingSkills,
    importance: Importance.Supporting
  },
  {
    id: MatchesShortlistColumnId.EssentialSkills,
    importance: Importance.Essential
  },
  {
    id: MatchesShortlistColumnId.RequiredSkills,
    importance: Importance.Required
  }
];

export type MatchTableEntry = {
  match: Match;
  shortlist?: Shortlist;
};

export type ShortlistTableEntry = {
  match?: Match;
  shortlist: Shortlist;
};

export type MatchesShortlistTableEntry = MatchTableEntry | ShortlistTableEntry;
