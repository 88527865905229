import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { useEffect, useRef } from "react";

import { Option, OptionOriginal } from "../../../select_v2.types";
import css from "./list_item.module.scss";
import { ListItemMultiple } from "./list_item_multiple";
import { ListItemSingle } from "./list_item_single";

type ListItemProps<T> = {
  id: string;
  option: Option<T>;
  selected: boolean;
  highlighted: boolean;
  onClick: VoidFunction;
  multiple?: boolean;
};

export const ListItem = <T extends OptionOriginal = OptionOriginal>({
  id,
  option,
  selected,
  highlighted,
  onClick,
  multiple
}: ListItemProps<T>) => {
  const ref = useRef<HTMLLIElement>(null);
  const listItemId = useRef(uniqueId(option.value));

  const ListItemComponent = multiple ? ListItemMultiple : ListItemSingle;

  useEffect(() => {
    if (highlighted) {
      ref.current?.scrollIntoView({ block: "nearest" });
    }
  }, [highlighted]);

  return (
    <li
      ref={ref}
      id={id}
      role="option"
      aria-selected={selected}
      className={classNames(css.listItem, {
        [css.highlighted]: highlighted,
        [css.disabled]: option.disabled
      })}
      title={option.value}
      tabIndex={-1}
    >
      <ListItemComponent id={listItemId.current} option={option} selected={selected} onClick={onClick} />
    </li>
  );
};
