import { RowType } from "PFApp/booking/types/row";
import { Icon } from "PFComponents/icon";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";

const ICONS_BY_TYPE = {
  role: "role",
  engagement: "engagement",
  audit_role: "role-audit",
  audit_engagement: "engagement-audit",
  profile: "profile",
  category: "non-demand"
};

type EntityIconProps = {
  templateId?: number | null;
  type: string;
};

export const EntityIcon = ({ templateId, type }: EntityIconProps) => {
  const { data: currentProfile } = useCurrentProfile();

  const templateKey = ![RowType.Profile as string, RowType.Category as string].includes(type)
    ? currentProfile.templates?.find(({ id }) => id === templateId)?.key
    : null;

  const iconName = ICONS_BY_TYPE[templateKey || type];

  return iconName && <Icon name={iconName} size="md" />;
};
