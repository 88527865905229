import { Option, OptionOriginal, SelectOptions } from "../select_v2.types";
import { areOptionsGrouped } from "../select_v2.utils";

export const filterOptions = <T extends OptionOriginal>(
  options: SelectOptions<T> | undefined,
  searchTerm: string
): SelectOptions<T> => {
  const filterOptionsFnc = (flatOptions?: Option<T>[]) =>
    (flatOptions ?? []).filter(({ value }) =>
      (value ?? "").toLowerCase().includes((searchTerm ?? "").trim().toLowerCase())
    );

  if (areOptionsGrouped(options)) {
    return options
      .map((option) => ({ ...option, options: filterOptionsFnc(option.options) }))
      .filter(({ options }) => options.length > 0);
  } else {
    return filterOptionsFnc(options);
  }
};
