import classNames from "classnames";
import { forIn } from "lodash";
import { useState } from "react";

import { LoadingDots } from "../loading_dots";
import { Tile } from "../tile";
import css from "./file_upload.module.scss";
import { EmptyContent, EmptyContentProps } from "./parts/empty_content";
import { ErrorContent } from "./parts/error_content";
import { UploadedContent, UploadedContentProps } from "./parts/uploaded_content";
import { useUploadFile } from "./use_upload_file";

type FileUploadProps<RESPONSE_TYPE extends object> = EmptyContentProps &
  UploadedContentProps & {
    className?: string;
    url: string;
    name: string;
    data?: object;
    isError?: boolean;
    onChange?: (file: File) => void;
    onLoad?: (response: RESPONSE_TYPE) => void;
    onError?: (response: RESPONSE_TYPE) => void;
  };

export const FileUpload = <RESPONSE_TYPE extends object>({
  className,
  url,
  name,
  data,
  disabled,
  isError,
  uploadedFile,
  inputRef,
  qaId,
  header,
  uploadButtonText,
  downloadButtonText,
  description,
  allowedFileTypes,
  acceptMediaTypes,
  hasRemoveButton,
  onLoad,
  onChange,
  onDownload,
  onError,
  onTypeError,
  onReset
}: FileUploadProps<RESPONSE_TYPE>) => {
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const { mutate: uploadFile } = useUploadFile<RESPONSE_TYPE>({
    options: {
      onSuccess: (data) => {
        onLoad?.(data);
        setIsUploadLoading(false);
      },
      onError: (error) => {
        onError?.(error);
        setIsUploadLoading(false);
      }
    }
  });

  const handleChange = (file: File) => {
    setIsUploadLoading(true);

    onChange?.(file);

    if (!url) {
      return;
    }

    const formData = new FormData();
    forIn(data, (value, key) => {
      formData.append(key, value);
    });

    formData.append(name, new Blob([file]), file.name);

    uploadFile({
      url,
      data: formData
    });
  };

  return (
    <Tile className={classNames(css.container, className)} paddingVariant="xl">
      {isUploadLoading && (
        <div className={css.loadingOverlay}>
          <LoadingDots circlesEnabled circleSize="lg" />
        </div>
      )}
      {isError && <ErrorContent disabled={disabled} onReset={onReset} />}
      {!uploadedFile && !isError && (
        <EmptyContent
          inputRef={inputRef}
          name={name}
          qaId={qaId}
          disabled={disabled}
          header={header}
          description={description}
          uploadButtonText={uploadButtonText}
          downloadButtonText={downloadButtonText}
          allowedFileTypes={allowedFileTypes}
          acceptMediaTypes={acceptMediaTypes}
          onChange={handleChange}
          onTypeError={onTypeError}
          onDownload={onDownload}
        />
      )}
      {uploadedFile && !isError && (
        <UploadedContent
          uploadedFile={uploadedFile}
          disabled={disabled}
          hasRemoveButton={hasRemoveButton}
          onReset={onReset}
        />
      )}
    </Tile>
  );
};
