import { isEmpty } from "lodash";
import { useFillAndBookAction } from "PFApp/hooks/use_fill_and_book_action";
import { Button } from "PFComponents/button";
import ButtonGroup from "PFComponents/button_group/button_group";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { DropdownButton } from "PFComponents/dropdown_button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Activity, Id } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./fill_and_book_button.module.scss";

type FillAndBookButtonProps = {
  profileId: Id;
  activity: Activity;
  shortlistId?: Id;
  isFillButtonDisabled: boolean;
  disabled?: boolean;
  small?: boolean;
};

export const FillAndBookButton = ({
  profileId,
  shortlistId,
  activity,
  isFillButtonDisabled,
  disabled,
  small
}: FillAndBookButtonProps) => {
  const { t } = useTranslation("translation");
  const { handleFillAndBook, handleCustomFillAndBook, isLoading } = useFillAndBookAction();

  const isActivityWithoutAvailability = isEmpty(activity?.metadata.availability);
  const isCompleted = activity?.state === "complete";

  const handleDropdownOptionClick = (item) => {
    if (item === "custom") {
      handleCustomFillAndBook({ activity, profileId, shortlistId });
    }
  };

  const options: DropdownOption[] = useMemo(
    () => [
      {
        id: "custom_fill_and_book",
        displayElement: t("customFillAndBook"),
        item: "custom"
      }
    ],
    [t]
  );

  const isDisabled = isCompleted || isLoading || isFillButtonDisabled || disabled;

  const renderButtons = () =>
    isActivityWithoutAvailability ? (
      <Button
        text={t("customFillAndBook")}
        onClick={(event) => {
          event.stopPropagation();
          handleCustomFillAndBook({ activity, profileId, shortlistId });
        }}
        disabled={isDisabled}
        small={small}
      />
    ) : (
      <ButtonGroup compact autoWidth>
        <Button
          text={t("fillAndBook")}
          onClick={(event) => {
            event.stopPropagation();
            handleFillAndBook({ activity, profileId, shortlistId });
          }}
          disabled={isDisabled}
          small={small}
        />
        <DropdownButton
          className={css.dropdownButton}
          icon="chevron-down"
          options={options}
          handleChange={handleDropdownOptionClick}
          popperOptions={{ placement: "bottom-end" }}
          disabled={isDisabled}
          small={small}
        />
      </ButtonGroup>
    );

  return !isCompleted ? (
    renderButtons()
  ) : (
    <Tooltip content={t("bookingNotAvailable")}>
      <div>{renderButtons()}</div>
    </Tooltip>
  );
};
