import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import { PlainFilters } from "PFApp/components/filters/plain_filters";
import { checkFiltersAreClear } from "PFApp/use_filtered_collection";
import { Accordion } from "PFComponents/accordion";
import { useTranslation } from "react-i18next";

import commonCss from "../side_filters.module.scss";
import { getFilterValuesForClear } from "../side_filters.utils";
import { AccordionHeader } from "./accordion_header";

type OtherFiltersProps = {
  title?: string;
  clearTitle?: string;
  disabled: boolean;
  filterElements: any[];
  filterBlacklist: any[];
};

export const OtherFilters = ({
  title,
  clearTitle,
  disabled,
  filterElements,
  filterBlacklist
}: OtherFiltersProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });

  const {
    meta,
    handleFilterChange: onFilterChange,
    handleFiltersChange: onFiltersChange
  } = useFiltersContext();

  const canClearMoreFilters = !checkFiltersAreClear(meta?.filters, filterBlacklist);

  const handleClear = (event) => {
    event.stopPropagation();
    onFiltersChange(getFilterValuesForClear(meta, filterElements));
  };

  if (filterElements.length === 0) {
    return null;
  }

  return (
    <section className={commonCss.section}>
      <Accordion
        header={
          <AccordionHeader
            title={title ?? t("moreFilters")}
            canClear={canClearMoreFilters}
            canRestore={false}
            disabled={disabled}
            clearTitle={clearTitle ?? t("clearMoreFilters")}
            onClear={handleClear}
          />
        }
        headerVariant="bodyBold"
        headerFullWidth
        iconSet="chevron"
        animated={false}
      >
        <PlainFilters
          narrow
          disabled={disabled}
          meta={meta}
          onFilterChange={onFilterChange}
          filters={filterElements}
        />
      </Accordion>
    </section>
  );
};
