import classNames from "classnames";
import { Icon, IconDefaultSize, IconProps } from "PFComponents/icon";
import React, { forwardRef, SyntheticEvent } from "react";

import { getAriaProps } from "../../helpers/get_aria_props";
import css from "./action_icon.module.scss";

export type ActionIconProps = React.AriaAttributes &
  IconProps & {
    classes?: {
      root?: string;
      icon?: string;
    };
    disableHover?: boolean;
    title?: string;
    onClick?: (event: SyntheticEvent) => void;
    onKeyDown?: (event: SyntheticEvent) => void;
    disabled?: boolean;
    qaId?: string;
    id?: HTMLButtonElement["id"];
    tag?: "button" | "a";
    href?: string;
    target?: string;
    rel?: string;
  };

export const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>(
  (
    {
      id,
      classes = {},
      disableHover,
      onClick,
      onKeyDown,
      size = IconDefaultSize,
      title,
      disabled,
      qaId,
      tag: Tag = "button",
      href,
      target,
      rel,
      ...props
    },
    ref
  ): JSX.Element => {
    const { ariaProps, rest: iconProps } = getAriaProps<ActionIconProps>(props);

    return (
      <Tag
        ref={ref as any}
        type="button"
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-label={title ?? iconProps.name}
        disabled={disabled}
        title={title}
        data-qa-id={qaId}
        id={id}
        className={classNames(css.actionButton, classes.root, {
          [css.disabled]: disabled,
          [css.disableHover]: disableHover,
          [css.smallActionButton]: size === "sm" || size === "xs"
        })}
        href={href}
        target={target}
        rel={rel}
        {...ariaProps}
      >
        <Icon size={size} className={classes.icon} {...iconProps} />
      </Tag>
    );
  }
);

ActionIcon.displayName = "ActionIcon";
