import classNames from "classnames";
import { Alert } from "PFComponents/alert";
import { AnimatedDiv } from "PFComponents/containers";

import { AIGeneratorProps } from "../ai_generator";
import css from "../ai_generator.module.scss";

type CollapsedBarProps<T extends object> = Pick<
  AIGeneratorProps<T>,
  "enabled" | "message" | "buttonText" | "className"
> & {
  onOpen: VoidFunction;
};

export const CollapsedBar = <T extends object>({
  enabled,
  onOpen,
  message,
  buttonText,
  className
}: CollapsedBarProps<T>) => (
  <div className={className}>
    <AnimatedDiv>
      <div className={classNames({ [css.hidden]: !enabled })}>
        <Alert
          kind="ai"
          title={message}
          action={{ onClick: onOpen, text: buttonText, kind: "ghost" }}
          iconCentered
          small
        />
      </div>
    </AnimatedDiv>
  </div>
);
