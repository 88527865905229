import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { TableColumn } from "PFApp/components/table/table_types";
import { FetchProfilesResponse } from "PFCore/services/profile";
import { FetchRolesParams, FetchRolesResponse } from "PFCore/services/roles";
import {
  Activity,
  Conversation,
  Id,
  Match,
  MatchFetchState,
  Meta,
  MetaWithPagination,
  Profile,
  Shortlist,
  VacanciesCollection,
  Vacancy
} from "PFTypes";
import { QueryParams } from "PFTypes";
import { MatchesDistribution } from "PFTypes/matches_distribution";
import { createContext, ReactElement, useContext } from "react";

import { ActivityPageTab } from "./hooks/use_activity_page_tabs";

export type ActivityContextType = {
  attachments: unknown[];
  shortlists: Shortlist[];
  suspendedShortlists: Shortlist[];
  allShortlists: Shortlist[];
  task: Activity;
  parentActivity: Activity | null;
  matches?: Match[];
  updateMatchesParams: (x: { [key: string]: any }) => void;
  isLoadingShortlists: boolean;
  rankedShortlists: Match[];
  shortlistsMeta: Meta;
  suspendedShortlistsMeta: Meta;
  updateShortlistsParams: (params: QueryParams) => void;
  clearComparisonToast: () => void;
  isProfileInComparison: (id: Id) => boolean;
  addProfileToComparison: (id: Id) => void;
  removeProfileFromComparison: (id: Id) => void;
  compareIds: Record<Id, boolean>;
  setCompareIds: React.Dispatch<React.SetStateAction<Record<Id, boolean>>>;
  primaryConversation?: Conversation;
  participantsOfConversations: { [key: number]: { id: number; profile: Profile; kind: string }[] };
  otherConversations?: Conversation[];
  isUnbiasedEnabled: boolean;
  isActivityUnbiased: boolean;
  isEngagement: boolean;
  isRole: boolean;
  isAudit: boolean;
  currentProfileIsReviewer: boolean;
  roles: FetchRolesResponse;
  fetchRolesPage: (params: FetchRolesParams) => void;
  canCreateRoles: boolean;
  matchesMeta: MetaWithPagination<{ distribution?: MatchesDistribution }>;
  matchesFetchState: MatchFetchState;
  matchesParams?: { page: number; per_page: number };
  matchesReload: () => Promise<void> | Promise<void[]>;
  canSeeMatches: boolean;
  checkActivityPending: () => boolean;
  comparisonToastComponent: ReactElement | null;
  showTabs: boolean;
  tabs: ActivityPageTab[];
  tabActiveId?: string;
  tabsIds: string[];
  handleChangeTab: (tab: ActivityPageTab["id"], navigate?: boolean | undefined) => void;
  roleKey?: string;
  vacancies: VacanciesCollection;
  existsOpenVacancy: boolean;
  vacanciesLoading: boolean;
  vacanciesProfiles: FetchProfilesResponse;
  vacanciesFilledProfiles: Vacancy[];
  fetchRolesVacanciesPage: (page?: number, perPage?: number) => void;
  closeVacancies: (
    selectedReasons: Record<Id, string>,
    selectedCandidates: Record<Id, string | number | null>
  ) => Promise<any>;
  reopenVacancies: (id: number) => Promise<void>;
  canManageBookings: boolean;
  canSeeBookingModule: boolean;
  canSeeViewInBookingModuleButton: boolean;
  lastRefresh?: string;
  setLastRefresh: (x: string) => void;
  showAsInternalExperience: boolean;
  profilesViewMode: ProfilesViewMode;
  setProfilesViewMode: React.Dispatch<React.SetStateAction<ProfilesViewMode>>;
  availableMatchesShortlistColumns: TableColumn[];
  selectedMatchesShortlistColumns: TableColumn[];
  setSelectedMatchesShortlistColumns: React.Dispatch<React.SetStateAction<TableColumn[]>>;
  isFillDisabled: boolean;
};

export const activityContextPlaceholder: ActivityContextType = {
  //@ts-ignore-line in LoadedActivity we always have Activity
  task: null,
  attachments: [],
  shortlists: [],
  suspendedShortlists: [],
  allShortlists: [],
  parentActivity: null,
  matches: [],
  updateMatchesParams: () => {},
  isLoadingShortlists: false,
  rankedShortlists: [],
  shortlistsMeta: {
    total: 0
  },
  suspendedShortlistsMeta: {
    total: 0
  },
  updateShortlistsParams: () => {},
  clearComparisonToast: () => {},
  isProfileInComparison: () => false,
  addProfileToComparison: () => {},
  removeProfileFromComparison: () => {},
  primaryConversation: undefined,
  participantsOfConversations: {},
  otherConversations: [],
  isUnbiasedEnabled: false,
  isActivityUnbiased: false,
  isEngagement: false,
  isRole: false,
  isAudit: false,
  roles: {
    entries: [],
    meta: { total: 0, page: 1, perPage: 20, totalPages: 1 }
  },
  fetchRolesPage: () => {},
  canCreateRoles: false,
  matchesMeta: {
    totalPages: 0,
    order: {
      options: [],
      value: { direction: "", text: "", name: [] },
      name: "",
      title: "",
      type: ""
    },
    filters: {},
    total: 0,
    page: 1,
    perPage: 10
  },
  matchesFetchState: MatchFetchState.Pending,
  matchesReload: () => Promise.resolve(),
  canSeeMatches: false,
  checkActivityPending: () => true,
  tabs: [],
  showTabs: false,
  tabsIds: [],
  handleChangeTab: () => {},
  vacancies: {
    entries: [],
    meta: { total: 0, total_opened: 0, page: 1, perPage: 20, totalPages: 1 }
  },
  existsOpenVacancy: false,
  vacanciesLoading: false,
  vacanciesProfiles: { entries: [], meta: { page: 1, perPage: 5, total: 0, totalPages: 1 } },
  vacanciesFilledProfiles: [],
  fetchRolesVacanciesPage: () => {},
  closeVacancies: () => Promise.resolve(),
  reopenVacancies: () => Promise.resolve(),
  canManageBookings: false,
  canSeeBookingModule: false,
  canSeeViewInBookingModuleButton: false,
  setLastRefresh: () => {},
  showAsInternalExperience: false,
  showHistorySidePanel: false,
  toggleHistorySidePanel: () => {}
};

const ActivityPageContext = createContext<ActivityContextType>(activityContextPlaceholder);

export const useActivityPageContext = () => useContext(ActivityPageContext);

export default ActivityPageContext;
