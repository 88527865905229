import { useQueryClient } from "@tanstack/react-query";
import { isNumber } from "lodash";
import {
  useRemoveInterestMutation,
  useShowInterestMutation
} from "PFCore/hooks/queries/activity/use_activity_watchers";
import { ShowInterestResponse } from "PFCore/services/activities";
import { Activity } from "PFTypes";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { INTERESTS_QUERY_KEY } from "../../profiles/show/query/query_keys";
import { useGrowl } from "../../use_growl";
import { QuestionsModalProps } from "./questions_modal";
import { BinaryAnswer, FormValues } from "./questions_modal.types";

type QuestionsModalData = {
  role: string;
  isOpened: boolean;
  questions: string[];
  showStatement: boolean;
  onSubmit: (values: FormValues) => void;
};

const initialQuestionModalState = {
  role: "",
  isOpened: false,
  questions: [],
  showStatement: false,
  onSubmit: () => {}
};

const getQuestionAnswer = (value?: BinaryAnswer): boolean | undefined => {
  if (!value) {
    return undefined;
  }

  return value === BinaryAnswer.Yes;
};

export const useQuestionsModal = () => {
  const growl = useGrowl();
  const { t } = useTranslation("activities");
  const [questionsModalData, setQuestionsModalData] = useState<QuestionsModalData>(initialQuestionModalState);

  const { mutate: mutateShowInterest, isLoading: isLoadingShowInterest } = useShowInterestMutation();
  const { mutate: mutateRemoveInterest, isLoading: isLoadingRemoveInterest } = useRemoveInterestMutation();
  const queryClient = useQueryClient();

  const handleInterestButtonClick = useCallback(
    (
      interestedId: number | null | undefined,
      activity: Pick<Activity, "id" | "name" | "metadata">,
      onSuccess?: (res?: ShowInterestResponse) => void,
      onError?: () => void
    ): void => {
      const isRemovingInterest = isNumber(interestedId);
      const questions = activity.metadata?.interest_questions || [];
      const noQuestionsProvided = !questions.length;
      const isStatementEnabled = !!activity.metadata?.interest_statement_enabled;

      const handleSuccess = (res?: ShowInterestResponse) => {
        queryClient.invalidateQueries({ queryKey: [INTERESTS_QUERY_KEY] });
        growl({
          message: t("sharedWithRoleOwner"),
          kind: "success"
        });
        onSuccess?.(res);
      };

      const options = {
        onSuccess: handleSuccess,
        onError
      };

      if (isRemovingInterest) {
        mutateRemoveInterest({ activityId: activity.id, interestedId }, options);
      } else if (noQuestionsProvided && !isStatementEnabled) {
        mutateShowInterest({ activityId: activity.id }, options);
      } else {
        setQuestionsModalData({
          isOpened: true,
          role: activity.name,
          questions,
          showStatement: isStatementEnabled,
          onSubmit: (values) => {
            const questionnaireAnswers = {
              answer1: getQuestionAnswer(values.question1),
              answer2: getQuestionAnswer(values.question2),
              answer3: getQuestionAnswer(values.question3),
              statement: values.statementOfQualification?.trim()
            };
            mutateShowInterest({ activityId: activity.id, questionnaireAnswers }, options);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const closeQuestionsModal = (): void => {
    setQuestionsModalData(initialQuestionModalState);
  };

  const questionModalProps: QuestionsModalProps = useMemo(
    () => ({
      role: questionsModalData.role,
      questions: questionsModalData.questions,
      showQuestions: questionsModalData.questions.length > 0,
      showStatement: questionsModalData.showStatement,
      onSubmit: questionsModalData.onSubmit,
      onClose: closeQuestionsModal
    }),
    [questionsModalData]
  );

  const isQuestionsModalOpened = useMemo(() => questionsModalData.isOpened, [questionsModalData]);

  return {
    isQuestionsModalOpened,
    questionModalProps,
    isLoading: isLoadingShowInterest || isLoadingRemoveInterest,
    handleInterestButtonClick
  };
};
