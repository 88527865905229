import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { AddOwnersModal } from "PFApp/activities/show/parts/add_owners_modal";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { Activity } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Label } from "../parts/label";

type OwnersProps = {
  activity: Activity;
};

export const Owners = ({ activity }: OwnersProps) => {
  const { t } = useTranslation();

  const { owners, coowned } = activity;
  const [showAddOwners, setShowAddOwners] = useState(false);

  return (
    <div className={css.item}>
      <Label text={t("owner", { count: 1 })} />
      <span className={classNames(css.strong, css.spaceBetween)}>
        {!isEmpty(owners) && (
          <Typography variant="bodyBold" noMargin>
            {owners.map(({ profile }) => getProfileName(profile)).join(", ")}
          </Typography>
        )}
        {coowned && <Button icon="add" kind="secondary" onClick={() => setShowAddOwners(true)} />}
      </span>
      {showAddOwners && <AddOwnersModal handleClose={() => setShowAddOwners(false)} activity={activity} />}
    </div>
  );
};
