import { InviteConfirmModal, InviteConfirmReasons } from "PFApp/activities/parts/invite_confirm_modal";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Button } from "PFComponents/button";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import {
  ShortlistDecisionParams,
  useShortlistDecision,
  useShortlistReset
} from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import { ProfileActionButtonsProps } from "./profile_action_buttons_props";
import css from "./shortlist_buttons.module.scss";

export const ApprovalButtons = ({ onError, onSuccess }: ProfileActionButtonsProps) => {
  const { t } = useTranslation("activities");
  const { mutateAsync: makeShortlistDecision } = useShortlistDecision();
  const { mutate: resetShortlist } = useShortlistReset();
  const { invalidate: invalidateActivities } = useActivityInvalidate();
  const { shortlist, areButtonsDisabled, small } = useRoleProfileActionButtonsContext();

  const { task, currentProfileIsReviewer } = useActivityPageContext();
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [action, setAction] = useState<"accept" | "decline" | null>(null);

  const reviewCompleted = task.review?.state === "complete";
  const disabled = areButtonsDisabled || (currentProfileIsReviewer && !task.coowned && reviewCompleted);

  const getParams = useCallback(
    (reasons: InviteConfirmReasons): ShortlistDecisionParams => ({
      shortlistId: shortlist!.id,
      action: action || "accept",
      reasons
    }),
    [action, shortlist]
  );

  const handleError = (err: any, reasons?: InviteConfirmReasons) => {
    if (err?.status === 422 && reasons) {
      resetShortlist(shortlist!.id, {
        onSuccess: () => {
          performDecisionRequest(getParams(reasons));
        },
        onError: (err) => handleError(err)
      });
    } else {
      setAction(null);
      !!action && onError?.();
    }
  };

  const handleDone = () => {
    invalidateActivities([task.id]);
    !!action && onSuccess?.(action);
  };

  const handleClick = (newAction: "accept" | "decline") => {
    setAction(newAction);
    setDisplayConfirm(true);
  };

  const performDecisionRequest = async (params: ShortlistDecisionParams) => {
    await makeShortlistDecision(params, {
      onSuccess: handleDone,
      onError: (err) => handleError(err)
    });
  };

  const handleSubmit = async (reasonValues: InviteConfirmReasons) => {
    if (!shortlist || !action) {
      return;
    }

    await performDecisionRequest(getParams(reasonValues));
  };

  return (
    <>
      <Button
        icon="check"
        text={t("show.shortlist.approve")}
        className={css.shortlistButton}
        disabled={disabled}
        small={small}
        onClick={(event) => {
          event.stopPropagation();
          handleClick("accept");
        }}
      />
      <Button
        icon="cross"
        text={t("show.shortlist.reject")}
        kind="secondary"
        className={css.shortlistButton}
        disabled={disabled}
        small={small}
        onClick={(event) => {
          event.stopPropagation();
          handleClick("decline");
        }}
      />
      {displayConfirm && <InviteConfirmModal setDisplayConfirm={setDisplayConfirm} onSubmit={handleSubmit} />}
    </>
  );
};
