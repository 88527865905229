import { getEssentialSkills } from "PFApp/hooks/matches";

import { useMatchCardContext } from "../match_card_context";

export const useEssentialSkills = () => {
  const { custom_values: customValues, role, shortlist } = useMatchCardContext();

  return getEssentialSkills({
    matchCustomValues: customValues,
    role,
    applicableCustomValues: shortlist?.applicable_custom_values
  });
};
