import { get, isEqual, isNil, mapValues, omit } from "lodash";
import useStorage from "PFCore/helpers/use_storage";
import { Filter, Filters, Value } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { checkFiltersAreClear, getValueFilters } from "../../../use_filtered_collection";
import { useFilters, UseFiltersReturn } from "../../../use_filtered_collection/use_filters";
import { FiltersPanelProps } from "../filters_panel";

type FiltersContextProps = Pick<
  FiltersPanelProps,
  | "meta"
  | "keyPrefix"
  | "onFilterChange"
  | "onFiltersChange"
  | "clearFilters"
  | "filtersAreClear"
  | "blockedList"
  | "blockedChildrenList"
  | "isChildrenEnabledDefault"
>;

type FiltersContextType = {
  clearFilters?: UseFiltersReturn["clearFilters"];
  handleFilterChange: UseFiltersReturn["updateFilter"];
  handleFiltersChange: UseFiltersReturn["setFilters"];
  noFiltersSelected?: UseFiltersReturn["noFiltersSelected"];
  meta?: FiltersPanelProps["meta"];
  applyChanges: VoidFunction;
  isAutoRefresh: boolean;
  setIsAutoRefresh: (value: boolean) => void;
  isEdited: boolean;
  showAutoRefreshWarning: boolean;
  closeAutoRefreshWarning: VoidFunction;
  setApplyChangesCallback: (id: string, callback: VoidFunction) => void;
  resetTempFiltersChanges: VoidFunction;
  isChildrenFiltersEnabled: boolean;
  setIsChildrenFiltersEnabled: Dispatch<SetStateAction<boolean>>;
};

const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType);

export const FiltersContextProvider = ({
  keyPrefix,
  meta,
  onFilterChange,
  onFiltersChange,
  clearFilters,
  filtersAreClear,
  blockedList,
  blockedChildrenList,
  isChildrenEnabledDefault,
  children
}: PropsWithChildren<FiltersContextProps>) => {
  const [isAutoRefresh, setIsAutoRefresh] = useStorage<boolean>(`${keyPrefix}_filtersBoxAutoRefresh`, false);
  const [autoRefreshWarningDiscarded, setAutoRefreshWarningDiscarded] = useStorage<boolean>(
    `filters_auto_refresh_warning_discarded`,
    false
  );

  const isChildrenEnabledDefaultValue = isNil(isChildrenEnabledDefault)
    ? !checkFiltersAreClear(meta?.filters?.children)
    : isChildrenEnabledDefault;

  const [isChildrenFiltersEnabled, setIsChildrenFiltersEnabled] = useState<boolean>(
    isChildrenEnabledDefaultValue
  );

  const entryFilters = useMemo(() => getValueFilters(meta?.filters), [meta?.filters]);

  const [tempFilters, setTempFilters] = useState<Filters<Value>>(entryFilters);
  const [applyChangesCallbacks, setApplyChangesCallbacks] = useState<Record<string, VoidFunction>>({});

  useEffect(() => {
    setTempFilters(entryFilters);
  }, [entryFilters]);

  const {
    clearFilters: clearTempFilters,
    updateFilter,
    setFilters
  } = useFilters({
    selectedFilters: tempFilters,
    setSelectedFilters: setTempFilters,
    defaultValue: meta?.defaultFilters
  });

  useEffect(() => {
    setIsChildrenFiltersEnabled(isChildrenEnabledDefaultValue);
  }, [isChildrenEnabledDefaultValue]);

  const isEdited = useMemo(
    () => !isEqual(entryFilters, tempFilters) || Object.keys(applyChangesCallbacks).length > 0,
    [entryFilters, tempFilters, applyChangesCallbacks]
  );

  const tempMeta = useMemo<FiltersContextProps["meta"]>(() => {
    const getMetaFilters = (filters, selectedFilters) =>
      mapValues(filters || {}, (filterFamilyValues, filterFamily) => {
        if (filterFamily === "availability") {
          return filters?.availability;
        }
        if (filterFamily === "children") {
          return getMetaFilters(filters.children, selectedFilters.children || {});
        }
        return mapValues(filterFamilyValues, (filter: Filter, filterName: string) => {
          const filterValue =
            typeof filter === "object" ? get(selectedFilters, [filterFamily, filterName]) : null;
          return { ...filter, value: filterValue };
        });
      }) as Filters;

    const resultFilters = getMetaFilters(meta?.filters, tempFilters);

    return { ...meta, filters: resultFilters };
  }, [tempFilters, meta]);

  const areFiltersClear = useMemo(
    () => checkFiltersAreClear(tempMeta?.filters, blockedList, blockedChildrenList),
    [tempMeta?.filters, blockedList, blockedChildrenList]
  );

  const applyChanges = useCallback(() => {
    onFiltersChange(tempFilters, tempMeta?.filters);
    Object.values(applyChangesCallbacks).map((callback) => callback());
  }, [tempFilters, tempMeta, applyChangesCallbacks, onFiltersChange]);

  const handleAutoRefreshToggleChange = useCallback(
    (value: boolean) => {
      setIsAutoRefresh(value);
      if (value && isEdited) {
        applyChanges();
      }
    },
    [applyChanges, isEdited, setIsAutoRefresh]
  );

  const closeAutoRefreshWarning = useCallback(
    () => setAutoRefreshWarningDiscarded(true),
    [setAutoRefreshWarningDiscarded]
  );

  const setApplyChangesCallback = (id: string, callbackFnc: VoidFunction) =>
    setApplyChangesCallbacks((prev) => {
      prev[id] = callbackFnc;
      return { ...prev };
    });

  const resetTempFiltersChanges = useCallback(() => {
    setTempFilters(entryFilters);
  }, [entryFilters]);

  const handleIsChildrenFiltersEnabledChange = useCallback(
    (value: boolean) => {
      setIsChildrenFiltersEnabled(value);
      if (value) {
        isAutoRefresh && onFiltersChange(entryFilters);
      } else {
        isAutoRefresh && onFiltersChange(omit(entryFilters, "children"));
        setTempFilters((prev) => omit(prev, "children"));
      }
    },
    [entryFilters, isAutoRefresh, onFiltersChange]
  );

  const contextValue = useMemo(
    () => ({
      clearFilters: isAutoRefresh ? clearFilters : clearTempFilters,
      handleFilterChange: isAutoRefresh ? onFilterChange : updateFilter,
      handleFiltersChange: isAutoRefresh ? onFiltersChange : setFilters,
      meta: isAutoRefresh ? meta : tempMeta,
      applyChanges,
      isAutoRefresh,
      setIsAutoRefresh: handleAutoRefreshToggleChange,
      isEdited,
      noFiltersSelected: isAutoRefresh ? filtersAreClear : areFiltersClear,
      showAutoRefreshWarning: !autoRefreshWarningDiscarded,
      closeAutoRefreshWarning,
      setApplyChangesCallback,
      resetTempFiltersChanges,
      isChildrenFiltersEnabled,
      setIsChildrenFiltersEnabled: handleIsChildrenFiltersEnabledChange
    }),
    [
      isAutoRefresh,
      clearFilters,
      clearTempFilters,
      onFilterChange,
      updateFilter,
      onFiltersChange,
      setFilters,
      meta,
      tempMeta,
      applyChanges,
      handleAutoRefreshToggleChange,
      isEdited,
      filtersAreClear,
      areFiltersClear,
      autoRefreshWarningDiscarded,
      closeAutoRefreshWarning,
      resetTempFiltersChanges,
      isChildrenFiltersEnabled,
      handleIsChildrenFiltersEnabledChange
    ]
  );

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error("useFiltersContext must be used within a FiltersContextProvider");
  }

  return context;
};
