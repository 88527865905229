import uniq from "lodash/uniq";
import { Checkbox } from "PFComponents/checkbox/checkbox";
import { Typography } from "PFComponents/typography";
import { Id, OptionsFilter } from "PFTypes";

import css from "./checkboxes_item.module.scss";

type CheckboxesItemProps = {
  onChange: (values: Id[]) => void;
  filter: Omit<OptionsFilter, "value"> & { value: (string | number)[] };
  disabled?: boolean;
};

export const CheckboxesItem = ({ filter, onChange, disabled }: CheckboxesItemProps) => (
  <div>
    {filter.title && <Typography variant="labelRegular">{filter.title}</Typography>}
    <div className={css.root}>
      {filter.options.map((option) => {
        const checked = !!(filter.value || []).find((value) => value === option.id);
        return (
          <Checkbox
            disabled={disabled}
            key={option.id}
            label={option.text}
            checked={checked}
            onChange={(checked) => {
              const newValues = checked
                ? uniq([...(filter.value ? filter.value : []), option.id])
                : filter.value.filter((value) => value !== option.id);
              onChange(newValues);
            }}
          />
        );
      })}
    </div>
  </div>
);
