import { cloneDeep, compact, isNil, map, mergeWith } from "lodash";
import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import { PlainFilters } from "PFApp/components/filters/plain_filters";
import { checkFiltersAreClear, getValueFilters, letFiltersRestore } from "PFApp/use_filtered_collection";
import { Accordion } from "PFComponents/accordion";
import { Filters, Value } from "PFTypes";
import { useTranslation } from "react-i18next";

import commonCss from "../side_filters.module.scss";
import { getFilterValuesForClear, removeBlacklistedFilters } from "../side_filters.utils";
import { AccordionHeader } from "./accordion_header";

type ActivityFiltersProps = {
  disabled: boolean;
  filterElements: any[];
  filterBlacklist: any[];
};

export const ActivityFilters = ({ disabled, filterElements, filterBlacklist }: ActivityFiltersProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });

  const {
    meta,
    handleFilterChange: onFilterChange,
    handleFiltersChange: onFiltersChange
  } = useFiltersContext();

  const nonActivityFiltersBlacklist = compact(map(filterElements, "name"));

  const metaValueFilters = getValueFilters(meta?.filters);
  const activityFiltersValues = removeBlacklistedFilters(metaValueFilters, filterBlacklist);
  const nonActivityFiltersValues = removeBlacklistedFilters(metaValueFilters, nonActivityFiltersBlacklist);
  const defaultActivityFiltersValues = removeBlacklistedFilters(
    meta?.default_filters as Filters<Value>,
    filterBlacklist
  );

  const canRestoreActivityFilters = letFiltersRestore(activityFiltersValues, defaultActivityFiltersValues);
  const canClearActivityFilters = !checkFiltersAreClear(meta?.filters, filterBlacklist);

  const handleRestore = (event) => {
    event.stopPropagation();
    onFiltersChange(
      mergeWith(
        cloneDeep(nonActivityFiltersValues),
        defaultActivityFiltersValues,
        (nonActivityValue, defaultValue) => {
          if (isNil(defaultValue)) {
            return nonActivityValue;
          }
        }
      )
    );
  };

  const handleClear = (event) => {
    event.stopPropagation();
    onFiltersChange(getFilterValuesForClear(meta, filterElements));
  };

  if (filterElements.length === 0) {
    return null;
  }

  return (
    <section className={commonCss.section}>
      <Accordion
        header={
          <AccordionHeader
            title={t("activityFilters")}
            canClear={canClearActivityFilters}
            canRestore={canRestoreActivityFilters}
            disabled={disabled}
            clearTitle={t("clearActivityFilters")}
            restoreTitle={t("restoreActivityFilters")}
            onClear={handleClear}
            onRestore={handleRestore}
          />
        }
        headerVariant="bodyBold"
        headerFullWidth
        iconSet="chevron"
        animated={false}
      >
        <PlainFilters
          narrow
          disabled={disabled}
          meta={meta}
          onFilterChange={onFilterChange}
          filters={filterElements}
        />
      </Accordion>
    </section>
  );
};
