import { CalendarView } from "PFApp/booking/types";
import { OrderStorage } from "PFApp/use_filtered_collection/use_storage_order";
import { OrderOption } from "PFTypes";
import { Dispatch, SetStateAction, useCallback } from "react";

import { useFiltersWithPostAction, UseFiltersWithPostActionReturn } from "./use_filters_with_post_action";
import { useOrderWithPostActions } from "./use_order_with_post_actions";
import { checkIfShouldUpdateOrderToMostRelevant, MOST_RELEVANT_ORDER } from "./use_tab_data.utils";

// TODO: [SP-1448] default order should not be hardcoded, it should come from API
export const DEFAULT_ORDER = { name: ["activity.saas_created_at"], text: "Latest first", direction: "desc" };
const VERSION = 4;
const getStoragePrefix = (view: CalendarView) => `booking_module_overview_projects_${view}-v${VERSION}`;

type ViewFiltersOrder = {
  filters: UseFiltersWithPostActionReturn;
  order: OrderStorage;
  childrenFiltersEnabled?: boolean;
  setChildrenFiltersEnabled?: Dispatch<SetStateAction<boolean>>;
};
type UseProjectsFiltersOrderReturn = {
  [key in CalendarView.RolesAndAuditRoles | CalendarView.EngagementsAndAuditEngagements]: ViewFiltersOrder;
};
type UseProjectsFiltersOrder = {
  postChangeAction: VoidFunction;
};

export const useProjectsFiltersOrder = ({
  postChangeAction
}: UseProjectsFiltersOrder): UseProjectsFiltersOrderReturn => {
  const engagementOrderState = useOrderWithPostActions({
    storagePrefix: getStoragePrefix(CalendarView.EngagementsAndAuditEngagements),
    defaultValue: DEFAULT_ORDER,
    postChangeAction
  });

  const roleOrderState = useOrderWithPostActions({
    storagePrefix: getStoragePrefix(CalendarView.RolesAndAuditRoles),
    defaultValue: DEFAULT_ORDER,
    postChangeAction
  });

  const getPostFiltersChangeAction = useCallback(
    (setSelectedOrder: Dispatch<SetStateAction<OrderOption>>) =>
      (...args: any) => {
        postChangeAction();
        if (checkIfShouldUpdateOrderToMostRelevant(args)) {
          setSelectedOrder(MOST_RELEVANT_ORDER);
          return;
        }
      },
    [postChangeAction]
  );

  const engagementFiltersState = useFiltersWithPostAction({
    storagePrefix: getStoragePrefix(CalendarView.EngagementsAndAuditEngagements),
    postChangeAction: getPostFiltersChangeAction(engagementOrderState.setSelectedOrder)
  });

  const roleFiltersState = useFiltersWithPostAction({
    storagePrefix: getStoragePrefix(CalendarView.RolesAndAuditRoles),
    postChangeAction: getPostFiltersChangeAction(roleOrderState.setSelectedOrder)
  });

  return {
    [CalendarView.EngagementsAndAuditEngagements]: {
      filters: engagementFiltersState,
      order: engagementOrderState
    },
    [CalendarView.RolesAndAuditRoles]: {
      filters: roleFiltersState,
      order: roleOrderState
    }
  };
};
