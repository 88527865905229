import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import i18n from "PFCore/i18n";
import { FocusEvent, useEffect, useRef } from "react";
import { useUnmount } from "react-use";

import { InputFieldSet } from "../../text/input_field_set";
import { GroupOption, Option, OptionOriginal, ResultOption, SelectV2Props } from "../select_v2.types";
import { getHighlightedOptionId } from "../select_v2.utils";
import css from "./dropdown.module.scss";
import { List, ListGrouped } from "./list";
import { NoItems } from "./no_items";
import { NoSearchTerm } from "./no_search_term";
import { useDebouncedSearchTerm } from "./use_debounced_search_term";
import { DropdownData } from "./use_dropdown";

type DropdownProps<T> = {
  dropdownId: string;
  onBlur: (event: FocusEvent<HTMLDivElement>) => void;
  dropdownData: DropdownData<T>;
  style?: React.CSSProperties;
} & Pick<SelectV2Props<T>, "value" | "multiple">;

/*
 * Recommended use with useDropdown hook
 */
export const Dropdown = <T extends OptionOriginal = OptionOriginal>({
  dropdownId,
  value,
  multiple,
  onBlur,
  dropdownData,
  style
}: DropdownProps<T>) => {
  const {
    resultOptions,
    isFetchingAvailableOptions,
    isFetchingOptionsEnabled,
    isSearchable,
    searchTerm,
    setSearchTerm,
    highlightedIndex,
    setHighlightedIndex,
    handleKeyDown,
    handleCreateOption,
    areOptionsGrouped,
    handleChange
  } = dropdownData;

  const { currentSearchTerm, setDebouncedSearchTerm } = useDebouncedSearchTerm({
    searchTerm,
    setSearchTerm,
    isFetchingOptionsEnabled
  });

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const highlightOption = (option: Option<T>, options: Option<T>[]) => {
    const optionIndex = options.findIndex(({ id }) => id === option.id);
    // highlights the option only in case some other option is already highlighted
    if (optionIndex > -1 && !isNil(highlightedIndex)) {
      setHighlightedIndex(optionIndex);
    }
  };

  useEffect(() => {
    searchInputRef.current?.focus({ preventScroll: true });
  }, []);

  useUnmount(() => {
    setHighlightedIndex(null);
    setSearchTerm("");
  });

  return (
    <div className={css.root} style={style}>
      {isSearchable && (
        <InputFieldSet
          ref={searchInputRef}
          value={currentSearchTerm}
          onChange={setDebouncedSearchTerm}
          icon="search"
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          role="combobox"
          aria-controls={dropdownId}
          aria-owns={dropdownId}
          aria-activedescendant={getHighlightedOptionId(highlightedIndex, resultOptions, dropdownId)}
          aria-autocomplete="list"
        />
      )}
      {!!handleCreateOption && (
        <Button
          className={css.createButton}
          text={i18n.t("core:components.selectV2.add", { value: searchTerm })}
          icon="add"
          kind="blank"
          onClick={handleCreateOption}
          onBlur={onBlur}
        />
      )}
      {!searchTerm && isFetchingOptionsEnabled && <NoSearchTerm />}
      {!!searchTerm && isEmpty(resultOptions) && !isFetchingAvailableOptions && <NoItems />}
      {isFetchingAvailableOptions && <LoadingDots />}
      {areOptionsGrouped ? (
        <ListGrouped
          id={dropdownId}
          value={value}
          optionsGroups={resultOptions as GroupOption<T>[]}
          multiple={multiple}
          onChange={handleChange}
          highlightedIndex={highlightedIndex}
          highlightOption={highlightOption}
        />
      ) : (
        <List
          id={dropdownId}
          value={value}
          options={resultOptions as ResultOption<T>[]}
          multiple={multiple}
          onChange={handleChange}
          highlightedIndex={highlightedIndex}
          highlightOption={highlightOption}
        />
      )}
    </div>
  );
};
