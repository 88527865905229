import { MultipleShortlistsResult } from "PFCore/hooks/queries/shortlists/use_activities_shortlists";
import { EconomicsVacancy, EconomicsVacancyProfile, Id } from "PFTypes";

export const groupVacanciesByRole = (vacancies: EconomicsVacancy[]) => {
  const groupedVacancies = vacancies.reduce((acc, vacancy) => {
    const { activity } = vacancy;
    return {
      ...acc,
      [activity.id]: [...(acc[activity.id] || []), vacancy]
    };
  }, {} as Record<string, EconomicsVacancy[]>);

  return groupedVacancies;
};

export const getPercentValue = (number?: number) => (number ? `${Math.round(number * 100)}%` : "-");

export const getActivityFillAndBookData = (
  roleId: number,
  shortlistsData: MultipleShortlistsResult[],
  scenarioVacancies: EconomicsVacancy[],
  selectedVacancies: Id[]
) => {
  const shortlists = shortlistsData.find(({ activityId }) => activityId === roleId)?.data?.entries || [];
  const vacancies = scenarioVacancies.filter((vacancy) => roleId === vacancy.activity.id);

  const selectedAssignees = vacancies
    .filter((vacancy) => selectedVacancies.includes(vacancy.id))
    .map((vacancy) => vacancy.assignment?.profile)
    .filter(Boolean) as EconomicsVacancyProfile[];

  const roleAssignees = vacancies
    .map((vacancy) => vacancy.assignment?.profile)
    .filter(Boolean) as EconomicsVacancyProfile[];

  const assigneesToBook = roleAssignees.filter((profile) => {
    const shortlist = shortlists.find((shortlist) => shortlist.profile.id === profile.id);

    return shortlist?.state === "filled";
  });

  const openVacancies = vacancies.filter(({ opened }) => opened);

  return {
    shortlists,
    openVacancies,
    selectedAssignees,
    assigneesToBook
  };
};
