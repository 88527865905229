import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { sumBookedChildren } from "../../../parts/helpers";
import css from "../../../parts/overview/detail_view/detail_view.module.scss";
import { Label } from "./parts/label";

type EngagementDetailChildrenRolesProps = {
  activityId: number;
  childrenWorkflowStatesTally: Record<string, number>;
};
export const EngagementDetailChildrenRoles = ({
  activityId,
  childrenWorkflowStatesTally
}: EngagementDetailChildrenRolesProps) => {
  const { t } = useTranslation();

  const rolesTotal = Object.values(childrenWorkflowStatesTally).reduce((sum, num) => sum + num, 0);
  const rolesBooked = sumBookedChildren(childrenWorkflowStatesTally);

  return (
    <>
      <span className={css.item}>
        <Label icon="role" text={t("roles")} />
        <Typography variant="bodyBold" noMargin>
          <Link to={`/activities/${activityId}/roles`}>{rolesTotal}</Link>
        </Typography>
      </span>
      <span className={css.item}>
        <Label icon="check" text={t("booked")} />
        <Typography variant="bodyBold" noMargin>
          <Link className={css.strong} to={`/activities/${activityId}/roles`}>
            {rolesBooked}
          </Link>
        </Typography>
      </span>
    </>
  );
};
