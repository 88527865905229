import { EventKey } from "PFTypes/event_key";
import { useEffect } from "react";

import { getTopModalElement } from "../modal.utils";

type UseCloseOnEscapeClick = {
  modalId: string;
  closeWithAnimation: VoidFunction;
};

export const useCloseOnEscapeClick = ({ modalId, closeWithAnimation }: UseCloseOnEscapeClick) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const topModal = getTopModalElement();
      // Avoid closing all the dialogs with single ESC click
      const isTopModal = modalId === topModal?.getAttribute("data-dialog-id");

      if (event.key === EventKey.Escape && isTopModal) {
        event.preventDefault();
        closeWithAnimation();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalId, closeWithAnimation]);
};
