import classNames from "classnames";
import inputFieldCss from "PFComponents/text/input_field_set/input_field_set.module.scss";

import { MultiToggle, MultiToggleProps } from "./multi_toggle";
import css from "./multi_toggle.module.scss";

type MultiToggleFieldProps<ID_TYPE extends string> = MultiToggleProps<ID_TYPE> & {
  errors?: string | string[];
};

export const MultiToggleField = <ID_TYPE extends string = string>({
  errors,
  ...props
}: MultiToggleFieldProps<ID_TYPE>) => (
  <div>
    <MultiToggle<ID_TYPE>
      classes={{ container: classNames({ [css.errorContainer]: !!errors }) }}
      {...props}
    />
    {errors && (
      <div className={inputFieldCss.error} data-target-id="input_field_set__error">
        {Array.isArray(errors) ? errors.map((err) => <span key={err}>{err}</span>) : <span>{errors}</span>}
      </div>
    )}
  </div>
);
