import { useCallback, useState } from "react";
import { useMount } from "react-use";

type UseModalVisibility = {
  onClose?: VoidFunction;
  onBeforeClose?: (closeFunction: VoidFunction) => boolean;
};

const ANIMATION_DURATION_MS = 500;

export const useModalVisibility = ({ onClose, onBeforeClose }: UseModalVisibility) => {
  const [fadeIn, setFadeIn] = useState(false);

  const closeWithAnimation = useCallback(
    (withBeforeClose = true) => {
      const doClose = () => {
        setFadeIn(false);
        setTimeout(() => {
          onClose?.();
        }, ANIMATION_DURATION_MS);
      };

      if (withBeforeClose && onBeforeClose?.(doClose) === false) {
        return;
      }

      doClose();
    },
    [onBeforeClose, onClose]
  );

  useMount(() => {
    setFadeIn(true);
  });

  return {
    fadeIn,
    closeWithAnimation
  };
};
