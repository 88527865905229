import { flatMap, has, isNil, map } from "lodash";
import { Id } from "PFTypes";

import { GroupOption, Option, OptionOriginal, SelectOptions } from "./select_v2.types";

export const getIds = (value: Id | Id[] | undefined): Id[] => {
  if (isNil(value)) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

export const areOptionsGrouped = <T extends OptionOriginal>(
  options?: SelectOptions<T>
): options is GroupOption<T>[] => {
  if (!options || options.length === 0) {
    return false;
  }
  const firstElement = options[0];
  return has(firstElement, "options");
};

export const flattenOptions = <T extends OptionOriginal>(options: SelectOptions<T>): Option<T>[] =>
  areOptionsGrouped(options)
    ? flatMap(options, (group) =>
        map(group.options, (flatOption) => ({ disabled: group.disabled, ...flatOption }))
      )
    : options;

export const getHighlightedOptionId = <T extends OptionOriginal>(
  index: number | null,
  options: SelectOptions<T>,
  dropdownId: string
) => (!isNil(index) ? `${dropdownId}-${flattenOptions(options)[index]?.id}` : undefined);
