import { shouldShowTerms } from "PFApp/routes/route_helpers";
import { useGrowl } from "PFApp/use_growl";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile, useCurrentProfileUpdate } from "PFCore/hooks/queries/profile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { TermsOfService } from "../components/terms_of_service";

export const TermsOfServicePage = () => {
  const { t } = useTranslation("onboarding");
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { mutateAsync: updateCurrentProfile } = useCurrentProfileUpdate();

  const growl = useGrowl();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const shouldAcceptTerms = shouldShowTerms(currentAccount, currentProfile);

  useEffect(() => {
    if (shouldAcceptTerms) {
      growl({ message: t("termsOfService.acceptPrompt") });
    }
  }, [shouldAcceptTerms]);

  const handleAcceptTerms = () => {
    setLoading(true);
    updateCurrentProfile({ agree_to_terms_and_conditions: true, id: currentProfile.id })
      .then(() => {
        setLoading(false);
        growl({ message: t("termsOfService.accepted") });
        history.push("/");
      })
      .catch(() => {
        setLoading(false);
        growl({ kind: "error", message: t("termsOfService.error") });
      });
  };

  return (
    <TermsOfService showAccept={shouldAcceptTerms} onAccept={handleAcceptTerms} acceptDisabled={loading} />
  );
};
