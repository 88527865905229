import { compact } from "lodash";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { LoadingSpinner } from "PFComponents/loading_spinner";
import { RadioItem } from "PFComponents/radios";
import { Typography } from "PFComponents/typography";
import { Booking } from "PFTypes";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useFutureBookings } from "../../hooks/use_future_bookings";
import { BookingQuestions, BookingSelectOption, Messages } from "../booking_questions/booking_questions";
import { BookingDeleteAllOption, DeleteAllBookingsQuestion } from "./delete_all_bookings_question";
import { DeleteMode } from "./delete_booking.types";
import css from "./delete_booking_modal.module.scss";

type RoleRelatedBookingsModalContentProps = {
  booking: Booking;
  deleteMode: DeleteMode;
  userName: string;
  activity: Booking["activity"];
  value: BookingSelectOption;
  onChange: (selectedOption: BookingSelectOption) => void;
  deleteAllOption: BookingDeleteAllOption;
  onChangeDeleteAllOption: (option: BookingDeleteAllOption) => void;
  isPermittedToResetShortlist: boolean;
};

export const SingleBookingsModalContent = ({
  booking,
  deleteMode,
  userName,
  activity,
  value,
  onChange,
  deleteAllOption,
  onChangeDeleteAllOption,
  isPermittedToResetShortlist
}: RoleRelatedBookingsModalContentProps) => {
  const { t } = useTranslation("bookingModule");
  const isEngagement = useTemplate(activity?.template_id)?.key === TemplateKey.Engagement;

  const { isFirstBooking, hasFutureBookings, isFetching } = useFutureBookings({
    thisBooking: { id: booking.id, startDate: booking.start_date },
    activityId: booking.activity_id ?? undefined,
    profileId: booking.profile_id
  });

  const DELETE_OPTIONS: RadioItem<BookingSelectOption>[] = compact([
    { id: BookingSelectOption.This, label: t("bookings.delete.options.this") },
    !isFirstBooking &&
      hasFutureBookings && { id: BookingSelectOption.Future, label: t("bookings.delete.options.future") },
    { id: BookingSelectOption.All, label: t("bookings.delete.options.all") }
  ]);

  const messages: Messages = {
    This: t(
      isEngagement
        ? "bookings.delete.body.deleteSingleBookingEngagement"
        : "bookings.delete.body.deleteSingleBookingRole",
      {
        userName,
        activityName: activity?.name
      }
    ),
    Future: t(
      isEngagement
        ? "bookings.delete.body.deleteThisAndFutureBookingsEngagement"
        : "bookings.delete.body.deleteThisAndFutureBookingsRole",
      { userName, activityName: activity?.name }
    ),
    All: (
      <Trans
        t={t}
        i18nKey={
          isEngagement
            ? "bookings.delete.body.deleteAllBookingsEngagement"
            : "bookings.delete.body.deleteAllBookingsRole"
        }
        values={{
          userName,
          activityName: activity?.name,
          suffixText: isPermittedToResetShortlist ? t("bookings.delete.body.deleteAllBookingsSuffix") : ""
        }}
        components={{ 1: <br /> }}
      />
    )
  };

  const modalBody = useMemo(() => {
    switch (deleteMode) {
      case DeleteMode.NoActivityBooking:
        return t("bookings.delete.body.noActivity");
      case DeleteMode.LastBooking:
        return t(
          isEngagement
            ? "bookings.delete.body.deleteSingleBookingEngagement"
            : "bookings.delete.body.deleteSingleBookingRole",
          {
            userName,
            activityName: activity?.name
          }
        );
    }
  }, [deleteMode, t, activity?.name, userName, isEngagement]);

  return (
    <>
      {deleteMode === DeleteMode.MultipleBookings && (
        <>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <BookingQuestions
              name="deleteBookingQuestions"
              text={t("bookings.delete.body.question")}
              className={css.roleRelatedOptions}
              messages={messages}
              value={value}
              items={DELETE_OPTIONS}
              onChange={onChange}
            />
          )}
          {isPermittedToResetShortlist && value === BookingSelectOption.All && (
            <DeleteAllBookingsQuestion
              value={deleteAllOption}
              onChange={onChangeDeleteAllOption}
              profileName={userName}
            />
          )}
        </>
      )}
      <Typography variant="bodyRegular">{modalBody}</Typography>
      {isPermittedToResetShortlist && deleteMode === DeleteMode.LastBooking && (
        <>
          <Typography variant="bodyRegular">
            {t("bookings.delete.body.resetShortlist.lastBookingMessage")}
          </Typography>
          <DeleteAllBookingsQuestion
            value={deleteAllOption}
            onChange={onChangeDeleteAllOption}
            profileName={userName}
          />
        </>
      )}
    </>
  );
};
