import { SubsidiaryModeAlert } from "PFApp/components/subsidiary_mode_alert";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

const useActivityPageCompare = (taskId, tabActiveId, isAudit) => {
  const history = useHistory();
  const [compareIds, setCompareIds] = useState({});
  const { t } = useTranslation("activities");
  const { tab: auditPlannerRoleTab } = useParams();

  const clearComparisonToast = useCallback(() => setCompareIds({}), []);

  const keys = Object.keys(compareIds);
  const comparisonListLength = keys.length;
  const comparisonLocation = {
    pathname: `/activities/${taskId}/compare/details/${keys.join(",")}`,
    state: { prevPath: location.pathname }
  };

  const comparisonToastComponent =
    comparisonListLength > 0 ? (
      <SubsidiaryModeAlert
        onClose={clearComparisonToast}
        title={
          comparisonListLength > 1
            ? t("show.countSelected", { count: comparisonListLength })
            : t("show.selectOneProfile")
        }
        actions={[
          {
            icon: "compare",
            onClick: () => history.push(comparisonLocation),
            disabled: comparisonListLength < 2
          }
        ]}
      />
    ) : null;

  useEffect(() => {
    const tab = isAudit ? auditPlannerRoleTab : tabActiveId;
    if (tab !== "shortlist" && Object.keys(compareIds).length > 0) {
      setCompareIds({});
    }
  }, [compareIds, tabActiveId, auditPlannerRoleTab, isAudit]);

  const isProfileInComparison = useCallback((profileId) => !!compareIds[profileId], [compareIds]);

  const addProfileToComparison = useCallback(
    (profileId) => setCompareIds({ ...compareIds, [profileId]: true }),
    [compareIds]
  );

  const removeProfileFromComparison = useCallback(
    (profileId) => {
      delete compareIds[profileId];
      setCompareIds({ ...compareIds });
    },
    [compareIds]
  );

  return {
    comparisonToastComponent,
    compareIds,
    setCompareIds,
    isProfileInComparison,
    addProfileToComparison,
    removeProfileFromComparison,
    clearComparisonToast
  };
};

export default useActivityPageCompare;
