import { Experience } from "PFTypes";
import { useMemo } from "react";

import {
  getColorVariantsForActiveExperience,
  getExperienceIcon,
  mapColorVariantsForExtraExperience,
  mapColorVariantsForMissingExperience
} from "./experience_radial.utils";

type UseExperienceRadial = {
  experience?: Experience | null;
  expectedExperience?: Experience | null;
  isMissing?: boolean;
};

export const useExperienceRadial = ({
  experience = null,
  expectedExperience = null,
  isMissing = false
}: UseExperienceRadial) => {
  const colorVariants = useMemo(() => {
    let computedColorVariants = getColorVariantsForActiveExperience(experience);

    if (expectedExperience) {
      computedColorVariants = mapColorVariantsForMissingExperience(
        computedColorVariants,
        expectedExperience,
        experience
      );

      if (experience) {
        computedColorVariants = mapColorVariantsForExtraExperience(
          computedColorVariants,
          expectedExperience,
          experience
        );
      }
    }

    return computedColorVariants;
  }, [expectedExperience, experience]);

  const icon = getExperienceIcon(isMissing, experience, expectedExperience);

  return {
    colorVariants,
    icon
  };
};
