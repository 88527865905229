import classNames from "classnames";
import { uniqueId } from "lodash";
import { ErrorMessage } from "PFComponents/error_message/error_message";
import React, { AriaAttributes, ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";

import { Typography } from "../typography";
import css from "./checkbox.module.scss";
import { CheckboxIcon } from "./checkbox_icon";

type CheckboxProps = {
  checked: boolean;
  onChange?: (value: boolean, event?: ChangeEvent<HTMLInputElement>) => void;
  // The label should be string. The ReactNode type is reserved for the MultiSelect component.
  label?: string | ReactNode;
  disabled?: boolean;
  id?: string;
  indeterminate?: boolean;
  vertical?: boolean;
  required?: boolean;
  className?: string;
  errorMessage?: string;
  tabIndex?: number;
} & AriaAttributes;

export const Checkbox = ({
  label,
  checked,
  disabled,
  onChange,
  id,
  indeterminate,
  vertical,
  required,
  className,
  "aria-label": ariaLabel,
  errorMessage,
  tabIndex
}: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const checkboxId = useRef(id || uniqueId("checkbox"));
  const [internalIndeterminate, setInternalIndeterminate] = useState(!!indeterminate);
  const [internalChecked, setInternalChecked] = useState(checked);

  const decoratedLabel = `${label}${required ? "*" : ""}`;

  useEffect(() => {
    setInternalIndeterminate(!!indeterminate);
  }, [indeterminate]);

  useEffect(() => {
    setInternalChecked(checked);
  }, [checked]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = internalIndeterminate;
    }
  }, [inputRef.current, internalIndeterminate]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalIndeterminate(false);
    setInternalChecked(event.target.checked);
    onChange && onChange(event.target.checked, event);
  };

  return (
    <div className={css.root}>
      <label
        data-qa-id={"checkbox-container"}
        className={classNames(css.label, className, {
          [css.disabled]: disabled,
          [css.vertical]: vertical
        })}
      >
        <input
          id={checkboxId.current}
          ref={inputRef}
          type="checkbox"
          checked={internalChecked}
          disabled={disabled}
          onChange={handleChange}
          className={classNames(css.input, {
            [css.checked]: internalChecked
          })}
          aria-label={ariaLabel || decoratedLabel}
          aria-checked={internalIndeterminate ? "mixed" : internalChecked}
          tabIndex={tabIndex}
        />
        <div className={css.customCheckbox}>
          <CheckboxIcon checked={internalChecked} indeterminate={internalIndeterminate} />
        </div>
        {label && (
          <div className={classNames({ [css.labelContainer]: !vertical })}>
            {typeof label === "string" ? (
              <Typography
                variant="bodyRegular"
                tag="span"
                qaId="checkbox-label"
                className={classNames({ [css.verticalLabel]: vertical })}
                clipOverflow
              >
                {decoratedLabel}
              </Typography>
            ) : (
              label
            )}
          </div>
        )}
      </label>
      {errorMessage && <ErrorMessage iconSize="sm" message={errorMessage} className={css.errorMessage} />}
    </div>
  );
};
