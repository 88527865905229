import { TemplateKey } from "PFApp/constants/templates";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag, Template } from "PFTypes";
import { useCallback } from "react";

export const useIsAssigneeRequired = () => {
  const isEnabled = useIsFeatureEnabled();

  return useCallback(
    (template: Template): boolean =>
      isEnabled(FeatureFlag.RequireAssignee) &&
      [TemplateKey.Role, TemplateKey.AuditRole, TemplateKey.Engagement, TemplateKey.AuditEngagement].includes(
        template.key
      ),
    [isEnabled]
  );
};
