import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag, Template } from "PFTypes";

export const useArePhasesRequired = (): boolean => {
  const { activity } = useBookingActivityContext();
  const roleTemplate = useTemplate(TemplateKey.Role as Template["key"]);
  const auditRoleTemplate = useTemplate(TemplateKey.AuditRole as Template["key"]);
  const isRoleOrAuditRole =
    !!activity && [roleTemplate?.id, auditRoleTemplate?.id].includes(activity.template_id);

  const isPhasesFeatureEnabled = useIsFeatureEnabled()(FeatureFlag.ActivityPhases);
  return isPhasesFeatureEnabled && isRoleOrAuditRole;
};
