import classNames from "classnames";
import { AssessmentCheckmarks } from "PFComponents/badges_list/assessment_checkmarks";
import { DropDownItem } from "PFComponents/dropdown/dropdown_item";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { ProfileCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import sharedCss from "./shared.module.scss";
import css from "./skill.module.scss";
import { useSkillActionsOptions } from "./use_skill_actions_options";

const getStaticDropdownItemClassName = (
  optionId: string,
  staticSelectedOptionId?: string
): string | undefined => {
  if (!staticSelectedOptionId) {
    return undefined;
  }
  return staticSelectedOptionId !== optionId ? css.staticDropdownItemNoHover : css.staticDropdownItem;
};

export type SkillProps = {
  addToLog?: (...changes: any[]) => void;
  skill: Partial<ProfileCustomValue>;
  coreSkillsCount?: number;
  developmentalSkillsCount?: number;
  draggableProps?: { provided: any; snapshot: any };
  /**
   * staticProps - to display static component in the info tooltip to inform user how to take an action
   */
  staticProps?: Partial<{
    cursorPointer: boolean;
    keepOptionsOpen: boolean;
    selectedOptionId: string;
  }>;
};

export const Skill = ({
  skill,
  addToLog,
  coreSkillsCount = 0,
  developmentalSkillsCount = 0,
  staticProps,
  draggableProps
}: SkillProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });
  const { data: currentAccount } = useCurrentAccount();

  const {
    cursorPointer: staticCursorPointer,
    keepOptionsOpen: staticKeepOptionsOpen,
    selectedOptionId: staticSelectedOptionId
  } = staticProps || {};

  const { actionOptions } = useSkillActionsOptions({
    skill,
    addToLog,
    coreSkillsCount,
    developmentalSkillsCount
  });

  const tooltipContent = (
    <ul className={sharedCss.actionsList}>
      {actionOptions.map((option) => (
        <DropDownItem
          key={option.id}
          value={option}
          handleChange={(fn) => fn && !option.disabled && fn()}
          selected={staticSelectedOptionId === option.id}
          dropDownItemClassName={getStaticDropdownItemClassName(option.id, staticSelectedOptionId)}
        />
      ))}
    </ul>
  );

  const credlySource = skill.assessment_source === "credly";
  const skillLabel = String(skill.text || skill.value || "");
  const isValidatedIconVisible = currentAccount.profile_view_customization.validated_skill_text;
  const { provided, snapshot } = draggableProps || {};

  return (
    <>
      <div
        className={classNames(sharedCss.skill, {
          [sharedCss.dragging]: draggableProps && snapshot.isDragging
        })}
        ref={draggableProps && provided.innerRef}
        {...(draggableProps ? provided.draggableProps : {})}
      >
        {isValidatedIconVisible && (
          <AssessmentCheckmarks title={skillLabel}>
            {skill.assessed && (
              <span title={t("assessment.verified")}>
                {credlySource ? (
                  <Icon name="verified-credly" size="sm" />
                ) : (
                  <Icon name="verified" size="sm" />
                )}
              </span>
            )}
            {skill.em_verified && (
              <span title={t("assessment.feedback")}>
                <Icon name="verified-others" size="sm" />
              </span>
            )}
          </AssessmentCheckmarks>
        )}
        <span
          className={classNames(sharedCss.icon, sharedCss.dragIcon)}
          {...(draggableProps ? provided.dragHandleProps : {})}
          aria-label={t("moveSkill", { skill: skillLabel })}
        >
          <Icon name="move" color="paletteWhite3" />
        </span>
        <Typography variant="bodyRegular" title={skillLabel} noMargin clipOverflow>
          {skillLabel}
        </Typography>
        {skill.top && (
          <span title={t("coreSkill")} className={sharedCss.icon}>
            <Icon name="core" />
          </span>
        )}
        {skill.developmental && (
          <span title={t("developmentalSkill")} className={sharedCss.icon}>
            <Icon name="development" />
          </span>
        )}
        <DropdownButton
          actionIcon="menu-vertical"
          aria-label={t("optionsForSkill", { skill: skillLabel })}
          options={actionOptions}
          handleChange={(fn) => fn?.()}
          small
        />
        {staticCursorPointer && <Icon name="mouse-cursor" className={css.staticCursorPointerIcon} />}
      </div>
      {staticKeepOptionsOpen && <div className={css.tooltipContentContainer}>{tooltipContent}</div>}
    </>
  );
};
