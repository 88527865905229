import { useActivityTemplateType } from "PFApp/activities/hooks";
import { TemplateKey } from "PFApp/constants/templates";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useTranslation } from "react-i18next";

import { useActivityLink } from "../../../parts/overview/detail_view/booking_detail/use_activity_link";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { EntityIcon } from "../../entity_icon";
import { DetailsHeader } from "./details_header";

export const RoleDetailHeader = () => {
  const { t } = useTranslation();
  const { activity } = useBookingActivityContext();
  const { data: parentActivity } = useBookingActivity(activity?.parent_activity_id);
  const linkUrl = useActivityLink({ activity, parentActivity });

  const { isAudit, isRole } = useActivityTemplateType(activity);
  const isAuditRole = isAudit && isRole;

  return activity ? (
    <DetailsHeader
      title={activity?.name}
      subtitle={isAuditRole ? t("auditRole") : t("role")}
      linkUrl={linkUrl ?? undefined}
    >
      <EntityIcon templateId={activity?.template_id} type={TemplateKey.Role} />
    </DetailsHeader>
  ) : null;
};
