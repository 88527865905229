import classNames from "classnames";
import { Button } from "PFComponents/button";
import DropDown from "PFComponents/dropdown/dropdown";
import { RecentSearch } from "PFTypes/recent_searches";
import { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";

import { useAutocompleteOptions } from "./hooks/use_autocomplete_options";
import css from "./search_autocomplete.module.scss";
import { SearchConfig, SearchDropdownItem, SearchEntityData } from "./search_autocomplete.types";

export const SEARCH_AUTOCOMPLETE_FOOTER_ID = "search-autocomplete-footer";
export const SEARCH_AUTOCOMPLETE_FOOTER_BUTTON_ID = "search-autocomplete-footer-button";

type SearchAutocompleteProps = {
  term: string;
  config: SearchConfig;
  recentSearches: RecentSearch[];
  showFooter: boolean;
  dropdownRef: MutableRefObject<null>;
  classes?: {
    dropdown?: string;
  };
  onTermChange: (term: string, entity?: SearchEntityData) => void;
  onClose: VoidFunction;
};

export const SearchAutocomplete = ({
  term,
  config,
  recentSearches,
  showFooter,
  classes = {},
  dropdownRef,
  onTermChange,
  onClose
}: SearchAutocompleteProps) => {
  const { t } = useTranslation();

  const dropdownOptions = useAutocompleteOptions({ term, config, recentSearches });

  const handleChange = ({ text, entity, onClick, useAsTerm }: SearchDropdownItem) => {
    onClick?.(text, entity);
    if (entity && !useAsTerm) {
      onTermChange("", entity);
    } else {
      onTermChange(text, entity);
    }
  };

  return (
    <DropDown
      ref={dropdownRef}
      options={dropdownOptions}
      rootClassName={classNames(css.dropdown, classes.dropdown)}
      // TODO: [PROF-6268] Fix types in DropDown handleChange
      // @ts-ignore - Related to PROF-6268
      handleChange={handleChange}
      handleClose={onClose}
      footer={
        showFooter && (
          <footer className={css.footer} id={SEARCH_AUTOCOMPLETE_FOOTER_ID}>
            <Button kind="text" onClick={() => onTermChange(term)} id={SEARCH_AUTOCOMPLETE_FOOTER_BUTTON_ID}>
              {t("seeAllResults")}
            </Button>
          </footer>
        )
      }
    />
  );
};
