import classNames from "classnames";
import React from "react";

import { Icon } from "../icon";
import css from "./checkbox_icon.module.scss";

type CheckboxIconProps = {
  checked: boolean;
  indeterminate: boolean;
};

export const CheckboxIcon = ({ checked, indeterminate }: CheckboxIconProps) => {
  if (indeterminate) {
    return (
      <Icon
        name="subtract"
        size="sm"
        color="paletteWhite0"
        className={classNames(css.icon, { [css.iconVisible]: indeterminate })}
      />
    );
  }

  return (
    <Icon
      name="check"
      size="sm"
      color="paletteWhite0"
      className={classNames(css.icon, { [css.iconVisible]: checked })}
    />
  );
};
