import { Typography } from "PFComponents/typography";
import React from "react";

import css from "../suggested_changes.module.scss";
import { useFormatBookingDates } from "../use_format_booking_dates";

type BookingPillContentProps = {
  startDate: string | undefined;
  endDate: string | undefined;
};

export const BookingPillContent = ({ startDate, endDate }: BookingPillContentProps) => {
  const { formatBookingDates } = useFormatBookingDates();

  const bookingDates = startDate && endDate ? formatBookingDates(startDate, endDate) : undefined;

  return (
    <div className={css.bookingPillContent}>
      {bookingDates && (
        <Typography variant="bodyBold" tag="span">
          {bookingDates}
        </Typography>
      )}
    </div>
  );
};
