import { ActionIcon } from "PFComponents/action_icon";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { useState } from "react";

import css from "./save_as_view.module.scss";
import { ViewAdditionalParams } from "./save_as_view.types";

type SavedViewBadgeProps = {
  name: string;
  isSelected: boolean;
  onClick: VoidFunction | null;
  onRemove: (onSuccess?: VoidFunction) => void;
  onShareClick?: VoidFunction;
} & ViewAdditionalParams;

export const SavedViewBadge = ({
  name,
  tooltipContent,
  disabled,
  isSelected,
  allowRemove,
  onClick,
  onShareClick,
  onRemove
}: SavedViewBadgeProps): JSX.Element => {
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);

  return (
    <SimpleBadge
      kind={isSelected ? "selected" : "primary"}
      text={name}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick?.();
      }}
      disabled={disabled}
      tooltipContent={tooltipContent}
      showTooltip={!!tooltipContent}
      tooltipTheme="pf"
      tooltipPlacement="top"
      icon={
        allowRemove ? (
          <ActionIcon
            name="cross"
            size="xs"
            disabled={isBeingDeleted}
            onClick={(event) => {
              event.stopPropagation();
              setIsBeingDeleted(true);
              onRemove(() => {
                setIsBeingDeleted(false);
              });
            }}
          />
        ) : null
      }
    >
      {onShareClick && (
        <ActionIcon
          classes={{ root: css.shareIcon }}
          name="share"
          size="xs"
          onClick={(event) => {
            event.stopPropagation();
            onShareClick();
          }}
        />
      )}
    </SimpleBadge>
  );
};
