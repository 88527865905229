import "normalize.css";
import "./styles/application.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppContext from "PFApp/app_context";
import { SuspenseWrapper } from "PFApp/components/suspense_wrapper";
import { CoreContextProvider } from "PFApp/core_context_provider";
import Routes from "PFApp/routes";
import { useBootstrapper } from "PFApp/use_bootstrapper";
import { ErrorBoundary } from "PFComponents/error_boundary";
import initialState from "PFCore/initial_state/index";
import { mainDispatch } from "PFCore/reducers/main_dispatch";
import { mainReducer } from "PFCore/reducers/main_reducer";
import { ONE_DAY_MS } from "PFCore/utilities/time_consts";
import React, { useCallback, useReducer } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import ReactAppLayout from "./react_app_layout";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: ONE_DAY_MS,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  }
});

const ReactApp = () => {
  const [store, reducerDispatch] = useReducer(mainReducer, initialState());
  const dispatch = useCallback(mainDispatch(reducerDispatch, store), []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ store, dispatch }}>
        <CoreContextProvider>
          <Router
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt, use the RouterPrompt component instead */
            }}
          >
            <Bootstrapper>
              <ReactAppLayout>
                <ErrorBoundary>
                  <SuspenseWrapper>
                    <Routes />
                  </SuspenseWrapper>
                </ErrorBoundary>
              </ReactAppLayout>
            </Bootstrapper>
          </Router>
        </CoreContextProvider>
      </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

// We want to be within the router so we have access to the history object
const Bootstrapper = ({ children }) => {
  const { initialized } = useBootstrapper();

  if (!initialized) {
    return;
  }

  return children;
};

export default ReactApp;
