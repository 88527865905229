import { Row } from "@tanstack/react-table";

import { TableData } from "./table";
import { OnRowClickFn } from "./table.types";
import { TableRow } from "./table_row";

type TableBodyProps<TABLE_DATA extends TableData> = {
  rows: Row<TABLE_DATA>[];
  onRowClick?: OnRowClickFn<TABLE_DATA>;
  compact?: boolean;
  noHover?: boolean;
  lastRowRef: React.RefObject<HTMLTableRowElement> | undefined;
};

export const TableBody = <TABLE_DATA extends TableData>({
  rows,
  onRowClick,
  compact,
  noHover,
  lastRowRef
}: TableBodyProps<TABLE_DATA>): JSX.Element => (
  <tbody>
    {rows.map((row, index) => (
      <TableRow
        key={row.id}
        row={row}
        onRowClick={onRowClick}
        compact={compact}
        noHover={noHover}
        lastRowRef={rows.length - 1 === index ? lastRowRef : undefined}
      />
    ))}
  </tbody>
);
