import { ColumnHelper } from "@tanstack/react-table";
import { EconomicsVacancy } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getPercentValue } from "../../../utils";
import css from "../vacancies_table.module.scss";

export const useAvailabilityColumn = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor(({ scores }) => scores?.availabilityScore, {
        id: "availability",
        header: () => <span className={css.rightAlign}>{t("availability")}</span>,
        cell: (info) => <span className={css.rightAlign}>{getPercentValue(info.getValue())}</span>,
        size: 100,
        enableSorting: true
      }),
    [t]
  );
};
