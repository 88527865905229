import api from "PFCore/api";
import { Id } from "PFTypes";

export type ResetActivityEconomicsScenarioPayload = {
  scenarioId: Id;
  activityId?: Id;
};

export const resetActivityEconomicsScenario = ({
  scenarioId,
  activityId
}: ResetActivityEconomicsScenarioPayload) =>
  api<void>({
    url: `scenarios/${scenarioId}/reset_assignments`,
    method: "POST",
    data: {
      activityId
    }
  });
