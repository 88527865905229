import { keyBy, merge, toString, values } from "lodash";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import {
  ActivityCustomValue,
  ActivityPureCustomValue,
  CustomValue,
  MatchCustomValue,
  ProfileCustomValue
} from "PFTypes";
import { CustomType } from "PFTypes/custom_type";
import { useCallback } from "react";

import { requiresApproval as typeRequiresApproval, requiresCuration } from "./custom_type";

export const useCustomValueToString = () => {
  const { formatDate, utc } = useDateFormatter();
  const getCustomValueString = useCallback(
    (customValue: CustomValue, valueType: CustomType["value_type"]): string => {
      const { value } = customValue;
      if (value && valueType === "date") {
        return formatDate(utc(value));
      }

      return toString(value);
    },
    [formatDate, utc]
  );

  const getCustomValuesListString = useCallback(
    // For list endpoints (e.g. api/matches/index) API limits Custom Values to 10.
    // For some others (e.g. api/profiles/:id) - we receive all Custom Values, so we limit them in the UI
    (customValues: CustomValue[], valueType: CustomType["value_type"], limit = 10) =>
      customValues
        .slice(0, limit)
        .map((customValue) => getCustomValueString(customValue, valueType))
        .join(", "),
    [getCustomValueString]
  );

  return { getCustomValueString, getCustomValuesListString };
};

export const requiresApproval = (type, customValue: CustomValue): boolean => {
  const ctRequiresAdminApproval = typeRequiresApproval(type);
  const ctRequiresCuration = requiresCuration(type);
  const globalId = customValue.globalId ?? customValue.global_id;
  const approved = customValue.state === "approved";

  if (ctRequiresAdminApproval && ctRequiresCuration) {
    return !globalId && !approved;
  }

  return ctRequiresAdminApproval && !approved;
};

export const convertProfileToActivityCustomValues = (profileValues: ProfileCustomValue[]) =>
  profileValues.map(({ experience, ...profileValue }) => ({
    ...profileValue,
    requiredExperience: experience
  }));

export const convertActivityToProfileCustomValues = (activityValues: ActivityCustomValue[]) =>
  activityValues.map(({ requiredExperience, ...activityValue }) => ({
    ...activityValue,
    experience: requiredExperience
  }));

export const generateMatchCustomValues = (
  profileValues: MatchCustomValue[],
  activityValues: ActivityPureCustomValue[]
) =>
  values<MatchCustomValue | ActivityPureCustomValue>(
    merge(keyBy(profileValues, "id"), keyBy(activityValues, "id"))
  );
