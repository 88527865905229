import classNames from "classnames";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useCertificateCreate, useCertificateUpdate } from "PFCore/hooks/queries/certificates";
import { FetchUrlMetadataResponse } from "PFCore/services/common";
import { Certificate, CertificatePayload } from "PFTypes/certificate";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import UploadAccomplishment, { UploadPayload } from "../upload_accomplishment";
import DeleteCertificateModal from "./delete_certificate_modal";
import css from "./edit_certificate.module.scss";
import { EditCertificateForm } from "./edit_certificate_form";

type EditCertificateProps = {
  certificate?: Certificate | null;
  onClose: () => void;
  onDelete?: () => void;
  fetchProfile: () => void;
};

export const EditCertificate = ({ certificate, onClose, onDelete, fetchProfile }: EditCertificateProps) => {
  const { t } = useTranslation(["profiles", "translation"]);
  const growl = useGrowl();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [uploadPayload, setUploadPayload] = useState<UploadPayload | null>({
    url: certificate?.attachmentId ? null : certificate?.url,
    attachmentId: certificate?.attachmentId,
    imageUrl: certificate?.attachmentId ? null : certificate?.imageUrl
  });
  const [urlRetrievedData, setUrlRetrievedData] = useState<FetchUrlMetadataResponse | null>(null);
  const [formChanged, setFormChanged] = useState(false);
  const [formData, setFormData] = useState<CertificatePayload>({} as CertificatePayload);

  const saveCertificateQuery = certificate ? useCertificateUpdate : useCertificateCreate;

  const {
    mutate: saveCertificate,
    isLoading,
    error
  } = saveCertificateQuery({
    onError: () => {
      growl({
        message: t("profiles:common.certificates.growl.formError"),
        kind: "error"
      });
    },
    onSuccess: () => {
      growl({
        message: t("profiles:common.certificates.growl.saved"),
        kind: "success"
      });
      fetchProfile();
      onClose();
    }
  });

  const handleSubmit = () => {
    setFormChanged(false);

    const data = {
      ...uploadPayload,
      ...formData,
      id: certificate?.id,
      source: certificate?.source || "user"
    } as CertificatePayload;
    saveCertificate(data);
  };

  const handleUploadPayloadChange = (payload: UploadPayload | null) => {
    setFormChanged(true);
    setUploadPayload(payload);
  };

  const handleUrlRetrievedDataChange = (data: FetchUrlMetadataResponse | null) => {
    setFormChanged(true);
    setUrlRetrievedData(data);
  };

  const handleFormChange = (isChanged: boolean, payload: CertificatePayload) => {
    setFormChanged(isChanged);
    setFormData(payload);
  };

  const errors = error?.response?.data.errors;

  const renderFooter = () => (
    <div className={classNames(css.footer)}>
      <Button text={t("translation:cancel")} kind="secondary" className={css.button} onClick={onClose} />
      {!!certificate?.id && (
        <Button
          className={css.removeCertButton}
          kind="danger"
          text={t("translation:remove")}
          onClick={() => setShowDeleteModal(true)}
        />
      )}

      <Button className={css.button} disabled={isLoading || !formChanged} onClick={handleSubmit}>
        {certificate?.id ? t("translation:save") : t("translation:add")}
      </Button>
    </div>
  );

  return (
    <SidePanel
      id="edit-certificate"
      title={t(
        certificate
          ? "profiles:common.certificates.editCertificate"
          : "profiles:common.certificates.addCertificate"
      )}
      subTitle={certificate?.title}
      onClose={onClose}
      show
      isOnTop
      fullHeight
      size="medium"
      footerRenderer={renderFooter}
    >
      {isLoading && (
        <div className={css.loadingOverlay}>
          <LoadingDots circlesEnabled circleSize="lg" />
        </div>
      )}
      {!certificate?.id && (
        <UploadAccomplishment
          setUploadPayload={handleUploadPayloadChange}
          setUrlRetrievedData={handleUrlRetrievedDataChange}
          errors={errors || []}
        />
      )}
      <EditCertificateForm
        certificate={certificate}
        onSubmit={handleSubmit}
        onClose={onClose}
        urlRetrievedData={urlRetrievedData}
        errors={errors}
        isSubmitting={isLoading}
        onFormChange={handleFormChange}
      />

      {certificate && showDeleteModal && onDelete && (
        <DeleteCertificateModal
          certificate={{ ...certificate!, id: certificate.id }}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onDeleted={onDelete}
        />
      )}
    </SidePanel>
  );
};
