import { useTranslation } from "react-i18next";

import { Button } from "../../button";
import { Icon } from "../../icon";
import { Typography } from "../../typography";
import css from "../file_upload.module.scss";

type ErrorContentProps = {
  disabled?: boolean;
  onReset?: () => void;
};

export const ErrorContent = ({ disabled, onReset }: ErrorContentProps) => {
  const { t } = useTranslation("core", { keyPrefix: "components.fileUpload" });

  return (
    <div className={css.wrapper}>
      <Icon name="error" size="xxl" color="paletteRed0" />
      <Typography variant="h4" noMargin clipOverflow>
        {t("importFailed")}
      </Typography>
      <span className={css.description}>
        <Typography variant="bodyRegular" noMargin clipOverflow>
          {t("importFailedMessage")}
        </Typography>
        <Typography variant="bodyRegular" noMargin clipOverflow>
          {t("importFailedReview")}
        </Typography>
      </span>
      <Button text={t("retry")} kind="secondary" disabled={disabled} onClick={onReset} />
    </div>
  );
};
