import { Match } from "PFTypes";

import { RoleMatchSkillsData } from "./get_skills.types";

export const getRelatedSkills = (customValues: Match["customValues"]): RoleMatchSkillsData => {
  const matchSkills = customValues.related.map((related) => {
    const { custom_value: customValue, ...other } = related;

    return {
      ...other,
      ...customValue
    };
  });

  return {
    matchSkills,
    roleSkills: []
  };
};
