import { AvailabilityFilterBase, UseAvailabilityStateReturn } from "PFApp/components/filters/filter_items";
import SidePanel from "PFComponents/side_panel/side_panel";
import { FilterWithDefaults } from "PFTypes";
import { SyntheticEvent } from "react";

import css from "./availability_side_panel.module.scss";
import { AvailabilitySidePanelHeader } from "./availability_side_panel_header";

type AvailabilitySidePanelProps = {
  show: boolean;
  availabilityFilterWithDefault: FilterWithDefaults;
  disabled: boolean;
  onAvailabilityChange: UseAvailabilityStateReturn["updateAvailability"];
  onClose: VoidFunction;
  onRestore: (event: SyntheticEvent) => void;
  onClear: (event: SyntheticEvent) => void;
};

export const AvailabilitySidePanel = ({
  show,
  availabilityFilterWithDefault,
  disabled,
  onAvailabilityChange,
  onClose,
  onClear,
  onRestore
}: AvailabilitySidePanelProps) => (
  <SidePanel
    show={show}
    title={
      <AvailabilitySidePanelHeader
        availabilityFilterWithDefault={availabilityFilterWithDefault}
        onClear={onClear}
        onRestore={onRestore}
      />
    }
    onClose={onClose}
    size="medium"
    fullHeight
  >
    <div className={css.contentWrapper}>
      {disabled && <div className={css.loadingOverlay} />}
      <AvailabilityFilterBase
        availability={availabilityFilterWithDefault.value}
        filter={availabilityFilterWithDefault}
        allowClearRestore={false}
        onChange={onAvailabilityChange}
        onRestore={onRestore}
      />
    </div>
  </SidePanel>
);
