import isEmpty from "lodash/isEmpty";
import { Option, OptionsProps } from "PFComponents/select_v2";
import { Collection, Filter, Id, OptionsFilter } from "PFTypes";
import { useCallback } from "react";

import { ProfileItem } from "../profile_item";

export type OptionOriginal = { id: Id; text: string };
export type FilterOption = Option<OptionOriginal>;

type UseFiltersOptionsReturn = OptionsProps<OptionOriginal>;

export const useFiltersOptions = (filter: Filter): UseFiltersOptionsReturn => {
  const formatOptions = useCallback(
    (originalOptions) =>
      originalOptions.map((option) => ({
        id: option.id,
        value: option.text,
        original: option,
        displayElement: filter.name === "workforce_member_id" ? <ProfileItem profile={option} /> : undefined
      })),
    [filter.name]
  );

  const fetchOptions = useCallback(
    ({ ids, searchTerm }) => {
      if ((isEmpty(ids) && isEmpty(searchTerm)) || !filter.query) {
        return Promise.resolve([]);
      }
      return (filter.query({ value: ids, term: searchTerm }) as Promise<Collection<OptionOriginal>>).then(
        (options) => formatOptions(options.entries)
      );
    },
    [filter, formatOptions]
  );

  return (filter as OptionsFilter).options
    ? { options: formatOptions((filter as OptionsFilter).options) }
    : { fetchOptions };
};
