import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import css from "./filters_footer.module.scss";

export const FiltersFooter = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });
  const { isAutoRefresh, isEdited, applyChanges, resetTempFiltersChanges } = useFiltersContext();

  if (isAutoRefresh) {
    return null;
  }

  return (
    <footer className={css.applyFooter}>
      <Button kind="secondary" text={t("cancel")} disabled={!isEdited} onClick={resetTempFiltersChanges} />
      <Button kind="primary" text={t("apply")} disabled={!isEdited} onClick={applyChanges} />
    </footer>
  );
};
