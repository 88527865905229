import classNames from "classnames";

import css from "./hr.module.scss";

type HrProps = {
  className?: string;
  noMargin?: boolean;
};

export default function Hr({ className, noMargin }: HrProps) {
  return <hr className={classNames(css.hr, className, { [css.noMargin]: noMargin })} />;
}
