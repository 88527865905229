import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCertificate } from "PFApp/profiles/services/api";
import { MutationOptions } from "PFTypes";
import { CertificatePayload } from "PFTypes/certificate";

import { certificateKeys } from "./query_keys";

type Error = { response: { data: { errors: unknown[] } } };

export const useCertificateCreate = ({ onSuccess, ...queryParams }: MutationOptions<void>) => {
  const queryClient = useQueryClient();

  return useMutation<CertificatePayload, Error, any>({
    mutationFn: (payload: CertificatePayload): any => createCertificate(payload),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.all() });
      onSuccess?.(...args);
    },
    ...queryParams
  });
};
