import { Icon } from "PFComponents/icon";
import { MultiToggle } from "PFComponents/multi_toggle";
import { Typography } from "PFComponents/typography";
import { RequirementType } from "PFTypes";
import { useEffect } from "react";
import { Controller, useFormContext, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingFormValues } from "../booking_form_provider";
import { RequirementOption } from "./requirement_option";
import css from "./requirement_selector.module.scss";
import { useInitialRequirementValues } from "./use_initial_requirement_values";

const HOURS_PER_DAY_MAX_VALUE = 24;
const LOAD_MAX_VALUE = 500;

interface RequirementSelectorProps {
  itemIndex: number;
  withTime: boolean;
  onWithTimeChange: () => void;
  startEndHoursDiff: number;
}

export const RequirementSelector = ({
  itemIndex,
  startEndHoursDiff,
  withTime,
  onWithTimeChange
}: RequirementSelectorProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });
  const { control, setError, clearErrors } = useFormContext<BookingFormValues>();
  const { errors } = useFormState<BookingFormValues>();

  const value = useWatch<BookingFormValues>({ name: `bookings.${itemIndex}.requirement.value` });

  const valueError = errors?.bookings?.[itemIndex]?.requirement?.value?.message;

  const initialRequirementValues = useInitialRequirementValues(itemIndex);

  useEffect(() => {
    if (value === 0) {
      setError(`bookings.${itemIndex}.requirement.value`, { message: t("requirementError") });
    } else {
      clearErrors(`bookings.${itemIndex}.requirement.value`);
    }
  }, [value]);

  const maxByRequirementType = {
    [RequirementType.Load]: LOAD_MAX_VALUE,
    [RequirementType.TotalHours]: startEndHoursDiff,
    [RequirementType.HoursPerDay]: HOURS_PER_DAY_MAX_VALUE
  };
  const labelByRequirementType = {
    [RequirementType.Load]: t(`requirement.${RequirementType.Load}`),
    [RequirementType.TotalHours]: t(`requirement.${RequirementType.TotalHours}`),
    [RequirementType.HoursPerDay]: t(`requirement.${RequirementType.HoursPerDay}`)
  };
  const requirementTypeOptions = [
    RequirementType.Load,
    RequirementType.TotalHours,
    RequirementType.HoursPerDay
  ].map((id) => ({ id, value: labelByRequirementType[id] }));

  return (
    <div className={css.root}>
      <Controller
        name={`bookings.${itemIndex}.requirement`}
        control={control}
        render={({ field }) => (
          <>
            <MultiToggle<RequirementType>
              options={requirementTypeOptions}
              defaultSelected={field.value.type}
              onChange={(requirementType) => {
                field.onChange({
                  type: requirementType,
                  value: initialRequirementValues.current[requirementType]
                });
                if (requirementType !== RequirementType.Load && withTime) {
                  onWithTimeChange();
                }
              }}
            />
            <RequirementOption
              key={field.value.type} //to refresh default value in input
              label={labelByRequirementType[field.value.type]}
              onChange={(requirementValue) => {
                field.onChange({ ...field.value, value: requirementValue });
              }}
              defaultValue={field.value.value}
              max={maxByRequirementType[field.value.type]}
              autofocus
              useTimeVisible={field.value.type === RequirementType.Load}
              withTime={withTime}
              onWithTimeChange={onWithTimeChange}
            />
          </>
        )}
      />
      {!!valueError && (
        <div className={css.errorContainer}>
          <Icon name="error" size="sm" color="paletteRed1" />
          <Typography tag="span" variant="labelRegular" noMargin wrapText className={css.error}>
            {valueError}
          </Typography>
        </div>
      )}
    </div>
  );
};
