import { useProfilePreview } from "PFApp/use_profile_preview";
import { ObjectWithProfileName } from "PFCore/helpers/profile";
import { Id, Profile } from "PFTypes";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

import { getProfileName } from "./profile";

type ProfileNameLinkProps = {
  profile: ObjectWithProfileName & { id: Id; viewable: boolean };
  className?: string;
  style?: CSSProperties;
  options?: { beforeShowPreview: () => void };
};

export const ProfileNameLink = ({ profile, className, style, options }: ProfileNameLinkProps) => {
  const { showPreview } = useProfilePreview();

  const name = getProfileName(profile);

  return profile.viewable ? (
    <Link
      style={style}
      className={className}
      to={`/profiles/${profile.id}`}
      onClick={(e) => {
        e.preventDefault();

        options?.beforeShowPreview?.();
        showPreview({ id: Number(profile.id), profile: profile as Profile });
      }}
    >
      {name}
    </Link>
  ) : (
    <span className={className} style={style}>
      {name}
    </span>
  );
};
