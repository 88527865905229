import { datadogRum } from "@datadog/browser-rum";
import { LandingLayoutWithLandingDescription } from "PFApp/components/landing_layout";
import { useSession } from "PFApp/use_session";
import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import ButtonLink from "PFComponents/button/button_link";
import { LoadingDots } from "PFComponents/loading_dots";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { ApiRoute, UiRoute } from "PFCore/utilities/routes";
import LinkedInLogotypeIcon from "PFIcons/linkedin_logotype.svg";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import css from "./sigin_in_page.module.scss";

const SignInPage = ({ adminPath }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [redirectingToSamlUrl, setRedirectingToSamlUrl] = useState(false);

  const { data: currentAccount } = useCurrentAccount();
  const isEnabled = useIsFeatureEnabled();
  const { signIn } = useSession();
  const queryParams = useQueryParams();
  const errorParam = queryParams.get("error");
  const urlErrorMessageParam = errorParam ? decodeURIComponent(errorParam)?.split("=") : null;

  useEffect(() => {
    if (!urlErrorMessageParam) {
      return;
    }
    const errorValue = urlErrorMessageParam?.[1];
    const errorKey = urlErrorMessageParam?.[0];
    const urlErrorMessage =
      errorValue || (errorKey ? t(`signUpSignIn.api.${errorKey}`, { defaultValue: errorKey }) : "");
    if (urlErrorMessage && urlErrorMessage.length > 0) {
      const errorMessage = urlErrorMessage.split("+").join(" ");
      setError(errorMessage);
      setTimeout(() => {
        window.history.replaceState(null, null, window.location.pathname);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (redirectingToSamlUrl) {
      history.push("/authsaml");
    }
  }, [redirectingToSamlUrl]);

  if (redirectingToSamlUrl) {
    return null;
  }

  if (
    !adminPath &&
    isEnabled(FeatureFlag.AutoSaml) &&
    !storage.getItem("samlAttempt") &&
    !sessionStorage?.getItem("signedOut")
  ) {
    storage.setItem("samlAttempt", true, { expiresIn: 60 });
    setRedirectingToSamlUrl(true);
  }

  const signInWithPassword = (event) => {
    event.preventDefault(); // form's onSubmit
    setIsLoading(true);
    setError(null);

    signIn(email.trim(), password, { only_success: true }).fail((resp) => {
      setIsLoading(false);
      if (resp.responseJSON) {
        const { errors } = resp.responseJSON;
        if (errors && errors.length > 0) {
          setError(errors[0].detail);
        }
      } else if (navigator.onLine) {
        setError(t("signUpSignIn.errors.connectionError"));
        datadogRum?.addError(new Error("Sign in connection error"), { responseStatus: resp && resp.status });
      } else {
        setError(t("signUpSignIn.errors.networkError"));
      }
    });
  };

  const isPasswordSignInEnabled = isEnabled(FeatureFlag.PasswordSignin) || adminPath;
  const isLinkedinSignInEnabled = isEnabled(FeatureFlag.LinkedinSignin);
  const isSamlSignInEnabled = isEnabled(FeatureFlag.SamlSignin);
  const isRequestAccessEnabled = isEnabled(FeatureFlag.RequestAccess);

  return (
    <LandingLayoutWithLandingDescription>
      <strong data-qa-id="SignInPage.header" style={{ marginBottom: 24 }}>
        {isSamlSignInEnabled || isLinkedinSignInEnabled ? t("signUpSignIn.loginWith") : t("login")}
      </strong>

      {isLinkedinSignInEnabled && (
        <Button
          qaId="SignInPage.Button.linkedin"
          style={{ width: "100%" }}
          onClick={() => {
            window.location = ApiRoute(
              `/api/oauth/authorize?account=${currentAccount.full_domain}&client_id=${
                PF.config.client_id
              }&grant_type=linkedin&redirect_uri=${UiRoute("/")}`
            );
          }}
        >
          <LinkedInLogotypeIcon height={20} width={45} style={{ transform: "scale(1.5)" }} />
        </Button>
      )}

      {isSamlSignInEnabled && (
        <ButtonLink
          qaId="SignInPage.Button.saml"
          style={{ margin: "8px 0 32px 0", width: "100%" }}
          href={"/authsaml"}
        >
          {t("signUpSignIn.oneClickLogin")}
        </ButtonLink>
      )}

      {(isSamlSignInEnabled || isLinkedinSignInEnabled) && isPasswordSignInEnabled && (
        <strong>{t("signUpSignIn.orUseYourEmail")}</strong>
      )}

      {error && (
        <Alert small kind="error" qaId="SignInPage.error" style={{ marginBottom: 24 }}>
          {error}
        </Alert>
      )}

      {isPasswordSignInEnabled && (
        <form onSubmit={signInWithPassword}>
          <InputFieldSet
            qaId="SignInPage.email"
            label={t("emailAddress")}
            className={css.input}
            style={{ marginBottom: 8 }}
            onChange={setEmail}
            autofocus
          />

          <InputFieldSet
            qaId="SignInPage.password"
            label={t("password")}
            inputType="password"
            className={css.input}
            style={{ marginBottom: 24 }}
            onChange={setPassword}
          />

          <Button qaId="SignInPage.Button.emailAndPasswordSignIn" kind="primary" className={css.button}>
            {isLoading ? <LoadingDots /> : t("login")}
          </Button>

          {!adminPath && (
            <div data-qa-id="SignInPage.reset" style={{ marginBottom: 24 }}>
              <Link to="/users/reset_password">{t("signUpSignIn.resetPassword")}</Link>
            </div>
          )}
        </form>
      )}

      {isPasswordSignInEnabled && !adminPath && (
        <>
          <div style={{ marginBottom: 8 }}>
            <span>{t("signUpSignIn.dontHaveAnAccount")}</span>
          </div>
          <div>
            <Link
              data-qa-id={isRequestAccessEnabled ? "SignInPage.requestAccess" : "SignInPage.signUp"}
              to={isRequestAccessEnabled ? "/request_access" : "/users/signup"}
            >
              {t("signUpSignIn.signUpForOne")}
            </Link>
          </div>
        </>
      )}
    </LandingLayoutWithLandingDescription>
  );
};

SignInPage.propTypes = {
  /* when access with admin path the form always shows password inputs even when they are disabled */
  adminPath: PropTypes.bool
};

export default SignInPage;
