import { Tile } from "PFComponents/tile";

import { useActivityPageContext } from "../../activity_page_context";
import { OwnerFlow } from "./owner_flow";
import { ReviewerFlow } from "./reviewer_flow";

export const ActivityReviewSection = () => {
  const { task, currentProfileIsReviewer } = useActivityPageContext();

  if (!currentProfileIsReviewer && !task.coowned) {
    return null;
  }

  return (
    <Tile paddingVariant="lg" light>
      <div>
        {currentProfileIsReviewer && <ReviewerFlow />}
        {!currentProfileIsReviewer && task.coowned && <OwnerFlow />}
      </div>
    </Tile>
  );
};
