import classNames from "classnames";
import { Checkbox } from "PFComponents/checkbox";

import { Option, OptionOriginal } from "../../../select_v2.types";
import css from "./list_item.module.scss";

type ListItemMultipleProps<T> = {
  id: string;
  option: Option<T>;
  selected: boolean;
  onClick: VoidFunction;
};

export const ListItemMultiple = <T extends OptionOriginal = OptionOriginal>({
  id,
  option,
  selected,
  onClick
}: ListItemMultipleProps<T>) => (
  <Checkbox
    id={id}
    checked={selected}
    disabled={option.disabled}
    onChange={onClick}
    className={classNames(css.listComponent, css.checkbox)}
    tabIndex={-1}
    label={option.displayElement ?? option.value}
  />
);
