import { Modal } from "PFComponents/modal";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { QuestionsSection } from "./parts/questions_section";
import { StatementSection } from "./parts/statement_section";
import css from "./questions_modal.module.scss";
import { FormValues, QuestionData } from "./questions_modal.types";

export type QuestionsModalProps = {
  role: string;
  questions: string[];
  showQuestions: boolean;
  showStatement: boolean;
  onSubmit: (values: FormValues) => void;
  onClose: VoidFunction;
};

export const QuestionsModal = ({
  role,
  questions,
  showQuestions,
  showStatement,
  onSubmit,
  onClose
}: QuestionsModalProps) => {
  const { t } = useTranslation(["translation", "activities"]);

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      statementOfQualification: showStatement ? "" : undefined
    },
    mode: "onChange"
  });

  const questionFields: QuestionData[] = questions
    .filter((label) => !!label)
    .map((label, index) => ({
      id: index + 1,
      name: `question${index + 1}` as keyof FormValues,
      label
    }));

  const { isValid } = formState;

  return (
    <Modal
      title={t("activities:questionsModal.showInterest")}
      subtitle={role}
      onClose={onClose}
      onOK={handleSubmit(onSubmit)}
      labelOK={t("translation:send")}
      disableOKButton={!isValid}
      classes={{
        modal: css.questionsModal
      }}
    >
      <form className={css.form}>
        {showQuestions && <QuestionsSection questionFields={questionFields} control={control} />}
        {showStatement && <StatementSection id={questionFields.length + 1} control={control} />}
      </form>
    </Modal>
  );
};
