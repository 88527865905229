import { ECOption } from "PFCore/helpers/echarts_wrapper";
import { EconomicsScenarioDetails } from "PFTypes";

import { useCostFieldLabel } from "../use_cost_field_label";
import { BarChart } from "./parts/bar_chart";

type GradeChartProps = { data: EconomicsScenarioDetails["costFieldData"] };

const options: Partial<ECOption> = {
  xAxis: {
    type: "value",
    axisLabel: {
      formatter: (value) => (Number.isInteger(value) ? value.toString() : "")
    }
  }
};

export const GradeChart = ({ data }: GradeChartProps) => {
  const costFieldLabel = useCostFieldLabel();

  const names = data.map(({ costFieldValue }) => costFieldValue);
  const requiredValues = data.map(({ required }) => ({ value: required }));
  const filledValues = data.map(({ possessed }) => ({ value: possessed || 0 }));

  return (
    <BarChart
      title={costFieldLabel}
      hideCoverage
      hideLegend
      names={names}
      series={[
        {
          name: "required",
          data: requiredValues
        },
        {
          name: "filled",
          data: filledValues
        }
      ]}
      customOptions={options}
    />
  );
};
