import { ColumnHelper } from "@tanstack/react-table";
import { findIndex, map, orderBy } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useMatchSidebarCustomTypes } from "PFApp/activities/show/hooks/use_match_sidebar_info_custom_types";
import { MatchesShortlistColumnId } from "PFApp/activities/types/matches_shortlist_column_id";
import { getRelatedSkills } from "PFApp/hooks/matches";
import { useCustomValueToString } from "PFCore/helpers/custom_value";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  MatchesShortlistTableEntry,
  SKILLS_IMPORTANCE_COLUMNS_DATA,
  TABLE_SIZES
} from "../activity_tabs.utils";
import {
  CustomValueCell,
  RoleProfileActionsCell,
  ScoreValueCell,
  SkillsCell,
  WorkforceMemberCell
} from "../components/table_cells";
import { SkillsOfImportanceCell } from "../components/table_cells/skills_of_importance_cell";
import { TableHeader } from "../components/table_header";

type UseMatchesShortlistTableColumns = {
  isLoading: boolean;
};

export const useMatchesShortlistTableColumns = <TableEntry extends MatchesShortlistTableEntry>({
  isLoading
}: UseMatchesShortlistTableColumns) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.table" });
  const { task: activity, selectedMatchesShortlistColumns, isFillDisabled } = useActivityPageContext();
  const { getCustomValuesListString } = useCustomValueToString();

  const { matchSidebarInfoCustomTypes } = useMatchSidebarCustomTypes();

  const generateColumns = useCallback(
    (columnHelper: ColumnHelper<TableEntry>) => {
      const columns = [
        columnHelper.accessor((row) => row.match?.scores.normalized_score, {
          id: MatchesShortlistColumnId.Match,
          header: () => <TableHeader title={t("match")} />,
          cell: (info) => <ScoreValueCell score={info.getValue()} />,
          size: TABLE_SIZES.match,
          enableSorting: false
        }),
        columnHelper.accessor((row) => row.match?.scores.availability_score, {
          id: MatchesShortlistColumnId.Availability,
          header: () => <TableHeader title={t("availabilityAbbreviation")} />,
          cell: (info) => <ScoreValueCell score={info.getValue()} />,
          size: TABLE_SIZES.availability,
          enableSorting: false
        }),
        columnHelper.accessor((row) => row, {
          id: MatchesShortlistColumnId.WorkforceMember,
          header: () => <TableHeader title={t("workforceMember")} />,
          cell: (info) => {
            const { shortlist, match } = info.getValue();

            return <WorkforceMemberCell profile={match?.profile ?? shortlist!.profile} />;
          },
          size: TABLE_SIZES.workforceMember,
          enableSorting: false
        }),
        ...SKILLS_IMPORTANCE_COLUMNS_DATA.map(({ id, importance }) =>
          columnHelper.accessor((row) => row, {
            id,
            header: () => <TableHeader title={t(id)} />,
            cell: (info) => {
              const { match, shortlist } = info.getValue();

              return <SkillsOfImportanceCell importance={importance} match={match} shortlist={shortlist} />;
            },
            size: TABLE_SIZES.skills,
            enableSorting: false
          })
        ),
        columnHelper.accessor((row) => row, {
          id: MatchesShortlistColumnId.RelatedSkills,
          header: () => <TableHeader title={t("relatedSkills")} />,
          cell: (info) => {
            const { match, shortlist } = info.getValue();
            const { matchSkills, roleSkills } = getRelatedSkills(
              match?.custom_values || { direct: [], indirect: [], related: [] }
            );
            const profileId = match?.profile.id ?? shortlist!.profile.id;

            return <SkillsCell profileId={profileId} matchSkills={matchSkills} roleSkills={roleSkills} />;
          },
          size: TABLE_SIZES.skills,
          enableSorting: false
        }),
        ...matchSidebarInfoCustomTypes.map((customType) =>
          columnHelper.accessor((row) => row, {
            id: customType.name,
            header: () => <TableHeader title={customType.display_as ?? customType.name} />,
            cell: (info) => {
              const { match, shortlist } = info.getValue();
              const profile = match?.profile ?? shortlist!.profile;

              const customValues = profile[customType.name];
              const text = customValues
                ? getCustomValuesListString(customValues, customType.value_type)
                : undefined;

              return <CustomValueCell text={text} />;
            },
            size: TABLE_SIZES.customValue,
            enableSorting: false
          })
        ),
        columnHelper.accessor((row) => row, {
          id: MatchesShortlistColumnId.Actions,
          header: () => null,
          cell: (info) => {
            const { match, shortlist } = info.getValue();
            const profile = match?.profile ?? shortlist!.profile;

            return (
              <RoleProfileActionsCell
                role={activity}
                profile={profile}
                shortlist={shortlist ?? match!.shortlist}
                isFillDisabled={isFillDisabled}
                areButtonsDisabled={isLoading}
              />
            );
          },
          size: TABLE_SIZES.actions,
          enableSorting: false
        })
      ];

      const selectedColumnNames = map(selectedMatchesShortlistColumns, "name");

      return orderBy(
        columns.filter(({ id }) => selectedColumnNames.includes(id || "")),
        ({ id }) => findIndex(selectedColumnNames, (name) => id === name)
      );
    },

    [
      activity,
      getCustomValuesListString,
      isFillDisabled,
      isLoading,
      matchSidebarInfoCustomTypes,
      selectedMatchesShortlistColumns,
      t
    ]
  );

  return {
    generateColumns
  };
};
