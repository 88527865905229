import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import { useMatchesStatusText } from "PFApp/activities/show/hooks/use_matches_status_text";
import { MatchesActivityClosed } from "PFApp/activities/show/matches/matches_activity_closed";
import { MatchesEmpty } from "PFApp/activities/show/matches/matches_empty";
import { MatchesTimeout } from "PFApp/activities/show/matches/matches_timeout";
import BrainLoading from "PFComponents/brain_loading/brain_loading";

import css from "./matches_empty_state.module.scss";

type MatchesEmptyStateProps = {
  isLoading: boolean;
};

const MatchesEmptyStateContent = ({ isLoading }: MatchesEmptyStateProps) => {
  const { task: activity, checkActivityPending, matchesFetchState, matchesMeta } = useActivityPageContext();

  const template = useActivityTemplate();
  const matchingStateText = useMatchesStatusText(matchesMeta.status);

  if (activity.state === "complete") {
    return <MatchesActivityClosed activity={activity} />;
  }

  if (matchesMeta.status !== "ready") {
    return <BrainLoading text={matchingStateText} />;
  }

  if (isLoading && matchesFetchState === "timeout") {
    return <MatchesTimeout pending={checkActivityPending()} template={template || {}} />;
  }

  return <MatchesEmpty task={activity} matchesMeta={matchesMeta} />;
};

export const MatchesEmptyState = ({ isLoading }: MatchesEmptyStateProps) => (
  <div className={css.matchesInfo}>
    <MatchesEmptyStateContent isLoading={isLoading} />
  </div>
);
