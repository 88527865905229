import classNames from "classnames";
import { Alert } from "PFComponents/alert";
import { Flex } from "PFComponents/containers";
import { useState } from "react";

import css from "./ai_generator.module.scss";
import { Header } from "./parts";
import { CollapsedBar } from "./parts/collapsed_bar";
import { Footer } from "./parts/footer";

type OnGenerateParams<T> = {
  longer?: boolean;
  regenerate?: boolean;
  selectedGeneratedData?: T;
};

export type OnGenerateFunction<T extends object> = (params: OnGenerateParams<T>) => Promise<T>;

export type AIGeneratorProps<T extends object> = {
  message: string;
  buttonText: string;
  errorMessage: string;
  PreviewComponent: React.FC<{ data: T }>;
  enabled: boolean;
  allowMakeLonger?: boolean;
  className?: string;
  onGenerate: OnGenerateFunction<T>;
  onInsert: (payload: T) => void;
};

export const AIGenerator = <T extends object>({
  message,
  buttonText,
  errorMessage,
  PreviewComponent,
  className,
  enabled,
  allowMakeLonger = true,
  onGenerate,
  onInsert
}: AIGeneratorProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState<T[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const selectedGeneratedData = generatedData[selectedIndex];

  const handleGenerate = (params: OnGenerateParams<T> = {}) => {
    setError(false);
    setIsLoading(true);
    onGenerate(params)
      .then((response) => {
        const newGeneratedData = [...generatedData, response];
        setGeneratedData(newGeneratedData);
        setSelectedIndex(newGeneratedData.length - 1);
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setIsOpen(true);
    if (generatedData.length === 0) {
      handleGenerate();
    }
  };

  if (!isOpen) {
    return (
      <CollapsedBar
        enabled={enabled}
        message={message}
        buttonText={buttonText}
        onOpen={handleOpen}
        className={className}
      />
    );
  }

  return (
    <Flex
      direction="column"
      gap="spacingLg"
      className={classNames(css.root, { [css.open]: isOpen, [css.loading]: isLoading }, className)}
    >
      <Header
        onClose={() => setIsOpen(false)}
        isLoading={isLoading}
        count={generatedData.length}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      {error && <Alert kind="error" title={errorMessage} small />}
      {selectedGeneratedData && <PreviewComponent data={selectedGeneratedData} />}
      {selectedGeneratedData && (
        <Footer
          allowMakeLonger={allowMakeLonger}
          onTryAgain={() => handleGenerate({ regenerate: true, selectedGeneratedData })}
          onMakeLonger={() => handleGenerate({ longer: true, selectedGeneratedData })}
          onInsert={() => onInsert(selectedGeneratedData)}
        />
      )}
    </Flex>
  );
};
