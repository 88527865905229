import isEmpty from "lodash/isEmpty";
import { Fragment } from "react";

import { Option, OptionOriginal, ResultOption, SelectV2Props } from "../../select_v2.types";
import { getIds } from "../../select_v2.utils";
import { HighlightedIndex } from "../use_highlighted_index";
import css from "./list.module.scss";
import { ListItem } from "./list_item";

type ListProps<T> = {
  id: string;
  options: ResultOption<T>[];
  onChange: (option: Option<T>, selected: boolean) => void;
  highlightedIndex: HighlightedIndex;
  highlightOption: (option: Option<T>, options: Option<T>[]) => void;
} & Pick<SelectV2Props<T>, "value" | "multiple">;

export const List = <T extends OptionOriginal = OptionOriginal>({
  id,
  value,
  options,
  multiple,
  onChange,
  highlightedIndex,
  highlightOption
}: ListProps<T>) => {
  const selectedIds = getIds(value);

  if (isEmpty(options)) {
    return null;
  }

  return (
    <ul id={id} role="listbox" className={css.list} aria-multiselectable={multiple}>
      <>
        {(options ?? []).map((option, index) => {
          const selected = selectedIds.includes(option.id);

          return (
            <Fragment key={option.id}>
              <ListItem
                id={`${id}-${option.id}`}
                option={option}
                selected={option.selected ?? selected}
                highlighted={highlightedIndex === index}
                onClick={() => {
                  const handleChange = option.onChange || onChange;
                  handleChange(option, !selected);
                  highlightOption(option, options);
                }}
                multiple={multiple}
              />
            </Fragment>
          );
        })}
      </>
    </ul>
  );
};
