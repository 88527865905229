import { filter, flatten } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { normalizeList } from "PFApp/search/helpers/normalize_keywords";
import { Accordion } from "PFComponents/accordion";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AttributeTile } from "./attribute_tile";
import css from "./match_attributes.module.scss";

type MatchAttributesProps = {
  canEdit?: boolean;
};

export const MatchAttributes = ({ canEdit }: MatchAttributesProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "matchAttributes" });

  const { customTypes, getCustomTypeByName } = useCustomTypes();
  const { task } = useActivityPageContext();
  const matchableTypes = useMatchableTypes(task.template_id);

  const chosenKeywords = useMemo(
    () =>
      normalizeList(
        flatten(
          task.custom_fields.map(({ values, type }) => (values || []).map((val) => ({ ...val, type })))
        ),
        customTypes,
        matchableTypes,
        []
      ),
    [customTypes, matchableTypes, task.custom_fields]
  );

  const renderHeader = () => (
    <span className={css.accordionHeader}>
      {t("title")}
      {canEdit && (
        <TooltipIcon
          content={t("disclaimer")}
          IconComponent={() => <Icon name="info" />}
          width={20}
          height={20}
          tooltipProps={{
            maxWidth: 360
          }}
        />
      )}
    </span>
  );

  return (
    <Accordion
      header={renderHeader()}
      defaultState="closed"
      headerVariant="bodyBold"
      headerFullWidth
      iconSet="chevron"
    >
      <div className={css.root}>
        {matchableTypes.map(({ name }) => {
          const customType = getCustomTypeByName(name);

          if (!customType) {
            return;
          }

          return (
            <div key={customType.name}>
              <span className={css.header}>
                <Typography variant="h5" title={customType.display_as} clipOverflow noMargin>
                  {customType.display_as}
                </Typography>
                {canEdit && <Button kind="text" text={t("add")} disabled className={css.addButton} />}
              </span>
              <AttributeTile matchKeywords={filter(chosenKeywords, ({ type }) => type.name === name)} />
            </div>
          );
        })}
      </div>
    </Accordion>
  );
};
