import { AccountAvailability } from "PFTypes";

import { Snakify } from "./snakify";
import { Threshold } from "./threshold";

export type DateFormatKey = "day_month" | "month_day" | "full_month";

export enum Currency {
  GBP = "£",
  USD = "$",
  EUR = "€",
  AUD = "$",
  NZD = "$",
  CAD = "$"
}

type DateFormat = {
  key: DateFormatKey;
  displayFormat: string;
};

export type LocalesData = {
  locale: string;
  allowedLocales: string[];
  availableLocales: string[];
  availableDateFormats: DateFormat[];
  displayDateFormat: string;
  dateFormatKey: DateFormatKey;
  twelveHourClock: boolean;
  currency: Currency;
  availableCurrencies: string[];
};

export type Logo = Snakify<{
  fullUrl: string;
  thumbnailUrl: string;
}>;

export type Customization = Snakify<
  {
    landingDescription: string;
    landingCoverUrl: string;
    contactInfo: string;
    showTermsAndConditions: boolean;
    learningPlatformLink: string | null;
    customLinks: { title: string; url: string }[];
    termsAndConditions?: string;
  } & LocalesData
>;

type ActiveFeature = {
  id: number;
  name: string;
};

type CVParserConfig = {
  accreditations: boolean;
  brands: boolean;
  certificates: boolean;
  current_position: boolean;
  enabled: boolean;
  experience: boolean;
  internal_experience: boolean;
  languages: boolean;
  memberships: boolean;
  name: boolean;
  override_accreditations: boolean;
  override_brands: boolean;
  override_certificates: boolean;
  override_languages: boolean;
  override_memberships: boolean;
  override_name: boolean;
  override_qualifications: boolean;
  override_sectors: boolean;
  override_skills: boolean;
  override_summary: boolean;
  qualifications: boolean;
  sectors: boolean;
  skills: boolean;
  summary: boolean;
};

export type CurrentAccount = {
  id: number;
  availability: AccountAvailability;
  customization: Customization;
  profile_view_customization: Record<
    "accomplishment_section" | "edit_photo" | "validated_skill_text",
    boolean
  >;
  thresholds: Threshold[];
  full_domain: string;
  name?: string;
  active_features: ActiveFeature[];
  cv_parser_config: CVParserConfig;
  logo: Logo;
};
