import { ColumnHelper } from "@tanstack/react-table";
import { isNil } from "lodash";
import Table from "PFComponents/table/table";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { EconomicsVacancy } from "PFTypes";
import { useCallback, useMemo } from "react";

import { useStateColumn } from "./columns/use_state_column";
import { useWorkforceMemberColumn } from "./columns/use_workforce_member_column";

type ClosedVacanciesTableProps = {
  vacancies: EconomicsVacancy[];
};

export const ClosedVacanciesTable = ({ vacancies }: ClosedVacanciesTableProps) => {
  const activityId = vacancies[0]?.activity.id;
  const { data } = useActivityShortlists(activityId, undefined, { enabled: !isNil(activityId) });
  const shortlists = useMemo(() => data?.entries || [], [data?.entries]);

  const getVacancyProfile = useCallback((vacancy: EconomicsVacancy) => vacancy.profile!, []);

  const renderWorkforceMemberColumn = useWorkforceMemberColumn({ enableSorting: false, getVacancyProfile });
  const renderStateColumn = useStateColumn({
    shortlists,
    getVacancyProfile
  });

  const columnsGenerator = useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) => [
      renderWorkforceMemberColumn(columnHelper),
      renderStateColumn(columnHelper)
    ],
    [renderStateColumn, renderWorkforceMemberColumn]
  );

  return (
    <Table<EconomicsVacancy>
      compact
      generateColumns={columnsGenerator}
      tableData={vacancies}
      disableAutoResizing
      noHover
    />
  );
};
