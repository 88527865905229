import { ActionIcon } from "PFComponents/action_icon";
import { Flex } from "PFComponents/containers";
import { Icon } from "PFComponents/icon/icon";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography/typography";
import { useTranslation } from "react-i18next";

import css from "../ai_generator.module.scss";
import { Navigation } from "./navigation";

type HeaderProps = {
  onClose: () => void;
  isLoading?: boolean;
  count: number;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const Header = ({ isLoading, onClose, count, selectedIndex, setSelectedIndex }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={css.headerWrapper}>
      <Flex center>
        <Icon name="ai" className={css.magicIcon} size="md" />
        <Typography variant="h5" className={css.header}>
          {t("aiGenerator.aiGeneratedContent")}
        </Typography>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <Navigation count={count} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        )}
      </Flex>
      <ActionIcon onClick={onClose} name="cross" size="sm" />
    </div>
  );
};

Header.displayName = "AIGeneratorHeader";
