import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

import Breadcrumb from "./breadcrumb";
import { addEntryToChildren, getAllSkillsFromTree, getSkillEntry } from "./helpers";
import SkillsBubbles from "./skills_bubbles";
import SkillsDrilldown from "./skills_drilldown";
import css from "./skills_explorer.module.scss";

const SkillsExplorer = ({ profile, skills, selectedChart, showTooltip, className }) => {
  const [currentPath, setCurrentPath] = useState([]);
  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const handleDimentionsChange = () => {
    if (chartRef.current) {
      setTimeout(
        () =>
          setDimensions({
            width: chartRef?.current?.offsetWidth,
            height: chartRef?.current?.offsetHeight
          }),
        100
      ); // container width is changing just after first render so we need small timeout
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleDimentionsChange);
    return () => {
      window.removeEventListener("resize", handleDimentionsChange);
    };
  }, []);

  useEffect(() => {
    handleDimentionsChange();
  }, [chartRef]);

  // We want to pre-calculate as much of the data we need as possible. Otherwise we run the risk of things
  // like the hover over tooltips performing slowly.
  const skillsTree = useMemo(() => {
    const filteredCategories = (profile?.skill_categories || []).filter((category) =>
      skills?.find((skill) => skill.value === category[0])
    );

    const tree = filteredCategories.reduce((toReturn, category) => {
      if (category[2] !== "" && category[1] !== "" && category[0] !== "") {
        const rootIndex = addEntryToChildren(toReturn, category[2]);
        const midIndex = addEntryToChildren(toReturn[rootIndex].children, category[1]);
        const skillIndex = addEntryToChildren(toReturn[rootIndex].children[midIndex].children, category[0]);
        const skill = getSkillEntry(category[0], skills);

        if (skill) {
          toReturn[rootIndex].children[midIndex].children[skillIndex] = skill;
        }
      }
      return toReturn;
    }, []);

    tree.forEach((node) => {
      if (node.children.length > 0) {
        node.children.forEach((node) => {
          node.value = getAllSkillsFromTree(node)?.length;
        });
      }
      node.value = getAllSkillsFromTree(node)?.length;
    });

    return tree;
  }, [profile?.skill_categories, skills]);

  const ChartComponent = {
    drilldown: SkillsDrilldown,
    bubble: SkillsBubbles
  }[selectedChart];

  const showBreadCrumbs = currentPath.length > 0;

  // dimensions must be above 0
  const height = Math.max(showBreadCrumbs ? dimensions.height - 40 : dimensions.height, 1);
  const width = Math.max(dimensions.width, 1);

  return (
    <div className={classNames(css.skillsExplorerWrapper, className)} ref={chartRef}>
      {showBreadCrumbs && <Breadcrumb skills={skillsTree} path={[...currentPath]} setPath={setCurrentPath} />}
      <ChartComponent
        profile={profile}
        currentPath={currentPath}
        skillsTree={skillsTree}
        setCurrentPath={setCurrentPath}
        height={height}
        width={width}
        showTooltip={showTooltip}
      />
    </div>
  );
};

SkillsExplorer.propTypes = {
  profile: PropTypes.shape({
    summary: PropTypes.string,
    suggestions: PropTypes.object,
    avatar: PropTypes.object,
    id: PropTypes.number,
    skills: PropTypes.array,
    skill_categories: PropTypes.array
  }),
  selectedChart: PropTypes.string,
  skills: PropTypes.array,
  showTooltip: PropTypes.bool,
  className: PropTypes.string
};

export default SkillsExplorer;
