import { useExperienceText } from "PFApp/activities/edit/modals/skills_framework_select_modal/use_experience_text";
import { ExperienceRadial, useExperienceRadial } from "PFApp/components/experience_radial";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";

import css from "./experience_option.module.scss";

type ExperienceOptionProps = {
  experience: Experience;
  isSelected: boolean;
};

export const ExperienceOption = ({ experience, isSelected }: ExperienceOptionProps) => {
  const getExperienceText = useExperienceText();
  const { colorVariants } = useExperienceRadial({ experience });

  return (
    <div className={css.experienceOption}>
      <ExperienceRadial colorVariants={colorVariants} dark={isSelected} />
      <Typography variant="bodyRegular" noMargin>
        {getExperienceText(experience)}
      </Typography>
    </div>
  );
};
