import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import css from "./page_title.module.scss";

export default function PageTitle({ qaId, actions, children, subtitle, parentActivityId, classes }) {
  const history = useHistory();
  return (
    <div
      className={classNames(css.root, classes?.root, { [css.subtitle]: subtitle })}
      data-qa-id={qaId}
      onClick={subtitle ? null : () => subtitle && history.push(`/activities/${parentActivityId}`)}
      onKeyDown={subtitle ? null : () => subtitle && history.push(`/activities/${parentActivityId}`)}
    >
      {actions && <div className={classNames(css.actions, classes?.actions)}>{actions}</div>}
      <h1 className={css.children}>{children}</h1>
    </div>
  );
}

PageTitle.defaultProps = {
  subtitle: false
};

PageTitle.propTypes = {
  parentActivityId: PropTypes.number,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  subtitle: PropTypes.bool,
  qaId: PropTypes.string,
  classes: PropTypes.object
};
