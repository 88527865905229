import { MultiToggle } from "PFComponents/multi_toggle";
import { useBooking } from "PFCore/hooks/queries";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { Controller, useFormContext } from "react-hook-form";

import css from "../booking_form.module.scss";
import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormData, BookingFormDataEditOrCloneMode, BookingFormMode } from "../use_booking_form";
import { mapBookings } from "./map_bookings";
import { useBookingTypeOptions } from "./use_booking_type_options";

type BookingRangeColumnProps = {
  initialData: BookingFormData;
  mode: BookingFormMode;
};

export const BookingTypeSelector = ({ initialData, mode }: BookingRangeColumnProps) => {
  const { control, setValue, getValues } = useFormContext<BookingFormValues>();

  const bookingId =
    mode === BookingFormMode.Create ? undefined : (initialData as BookingFormDataEditOrCloneMode).bookingId;
  const { data: initialBooking } = useBooking(bookingId, { enabled: !!bookingId });
  const { data: bookingTemplate } = useBookingTemplate(initialBooking?.booking_template_id, {
    enabled: !!initialBooking?.booking_template_id
  });

  const bookingTypeOptions = useBookingTypeOptions({ initialBooking, mode });

  const handleChange = (onChange: (value: BookingType) => void) => (value: BookingType) => {
    const { bookings } = getValues();
    if (mode === BookingFormMode.Edit) {
      switch (value) {
        case BookingType.Single: {
          const bookingsWithId = mapBookings(bookings, { bookingTemplateId: undefined }, initialBooking);
          setValue("bookings", bookingsWithId);
          break;
        }
        case BookingType.Repeated: {
          const bookingsWithBookingTemplateId = mapBookings(bookings, { id: undefined }, bookingTemplate);
          setValue("bookings", bookingsWithBookingTemplateId);
          break;
        }
      }
    }
    onChange(value);
  };

  return (
    <>
      {bookingTypeOptions.length > 1 && (
        <div className={css.section}>
          <Controller
            name="bookingType"
            control={control}
            render={({ field }) => (
              <MultiToggle<BookingType>
                options={bookingTypeOptions}
                defaultSelected={field.value}
                onChange={handleChange(field.onChange)}
              />
            )}
          />
        </div>
      )}
    </>
  );
};
