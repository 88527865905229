import { flatten, isEqual, uniqBy } from "lodash";
import { Button } from "PFComponents/button";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import AutoSelect from "PFComponents/select/autoselect";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { Typography } from "PFComponents/typography";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { getId } from "PFCore/helpers/get_id";
import useDebounce from "PFCore/helpers/use_debounce";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useKeywords } from "PFCore/hooks/queries/bookings/keywords/use_keywords";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { fetchAutocompleteSkills } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import { FetchUrlMetadataResponse } from "PFCore/services/common";
import { KeywordsParams } from "PFCore/services/keywords";
import { Keyword } from "PFTypes";
import { Certificate, CertificatePayload } from "PFTypes/certificate";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./edit_certificate.module.scss";

type EditCertificateFormProps = {
  onSubmit: (certificate: CertificatePayload) => void;
  onClose: () => void;
  certificate?: Certificate | null;
  errors?: any[];
  urlRetrievedData?: FetchUrlMetadataResponse | null;
  isSubmitting: boolean;
  onFormChange: (isChanged: boolean, payload: CertificatePayload) => void;
};

export const EditCertificateForm = ({
  certificate,
  errors,
  urlRetrievedData,
  onFormChange
}: EditCertificateFormProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { templates } = currentProfile;

  const { formatISODate } = useDateFormatter();
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });

  const getDefaults = useCallback(
    (): CertificatePayload => ({
      title: certificate?.title || urlRetrievedData?.title || "",
      issuer: certificate?.issuer || "",
      issueDate: certificate?.issueDate || "",
      expiryDate: certificate?.expiryDate || "",
      expires: certificate?.expiryDate ? true : false,
      skills: certificate?.skills || []
    }),
    [certificate, urlRetrievedData]
  );

  const [suggestedSkills, setSuggestedSkills] = useState<Keyword[]>([]);
  const [formData, setFormData] = useState<CertificatePayload>(getDefaults() as CertificatePayload);

  useEffect(() => {
    setFormData(getDefaults());
  }, [certificate, urlRetrievedData]);

  const handleFormDataChange = (field: keyof CertificatePayload, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const findError = (key) => errors?.find((error) => error?.source?.pointer?.search(key) > 0)?.detail;

  const searchTemplate = templates.find(({ key }) => key === "task");
  const matchableTypeNames = useMatchableTypes(searchTemplate?.id).map(({ name }) => name);
  const text = `${formData.title} ${urlRetrievedData?.description || ""}`;

  useEffect(() => {
    const hasChanged = !isEqual(getDefaults(), formData);

    onFormChange(hasChanged, formData);
  }, [formData, getDefaults]);

  useEffect(() => {
    handleFormDataChange("title", urlRetrievedData?.title || certificate?.title || "");
  }, [urlRetrievedData]);

  const getParams = () => {
    const selectedSkills = formData.skills?.map(getId);

    const params: KeywordsParams = {
      text,
      matchTypes: matchableTypeNames
    };
    if (selectedSkills.length > 0) {
      params.selectedIds = selectedSkills;
    }

    return params;
  };

  const [params, setParams] = useState(getParams());

  const { data } = useKeywords(params, {
    enabled: Boolean(params.selectedIds?.length || params.text?.trim())
  });

  const debounceSetRelevantAttributes = useDebounce(() => {
    setParams(getParams());
  }, 500);

  useEffect(() => {
    if (data?.entries) {
      setSuggestedSkills(data.entries as Keyword[]);
    }
  }, [data, formData.skills]);

  useEffect(() => {
    debounceSetRelevantAttributes();
  }, [formData.title, urlRetrievedData?.description, formData.skills]);

  const handleSkillsChange = (selectedSkills) => {
    const dedupedAttributes = uniqBy(flatten([...formData.skills, selectedSkills]), "id");
    handleFormDataChange("skills", dedupedAttributes);
  };

  const { windowWidth } = useWindowSize();
  const calendarStyle = windowWidth <= 600 ? { minWidth: "auto" } : {};

  return (
    <div className={css.wrapper}>
      <InputFieldSet
        label={t("title")}
        value={formData.title}
        placeholder={t("enterCertificateName")}
        required
        qaId="certificates-form-title"
        error={findError("title")}
        onChange={(value) => handleFormDataChange("title", value)}
      />
      <div className={css.row}>
        <InputFieldSet
          label={t("issuingCompany")}
          value={formData.issuer}
          placeholder={t("providerName")}
          required
          qaId="certificates-form-company"
          error={findError("issuer")}
          onChange={(value) => handleFormDataChange("issuer", value)}
        />
        <InlineCalendar
          label={t("completed")}
          placeholder={t("addDate")}
          selectedDate={formData.issueDate ? formatISODate(formData.issueDate) : null}
          maxDate={formatISODate()}
          qaId="certificates-form-calendar"
          error={findError("issue_date")}
          handleChange={(value) => handleFormDataChange("issueDate", formatISODate(value))}
          required
          calendarStyle={calendarStyle}
        />
      </div>
      <div className={css.row}>
        <Toggle
          onChange={(value) => handleFormDataChange("expires", value)}
          label={t("doesExpire")}
          inline
          checked={formData.expires}
          qaId="certificates-form-toggle"
          reversed
        />
        {formData.expires && (
          <InlineCalendar
            placeholder={t("expiryDate")}
            selectedDate={formData.expiryDate ? formatISODate(formData.expiryDate) : null}
            qaId="certificates-form--expiry-calendar"
            handleChange={(value) => handleFormDataChange("expiryDate", formatISODate(value))}
            minDate={formatISODate()}
            error={findError("expiry")}
            required
            calendarStyle={calendarStyle}
          />
        )}
      </div>
      <AutoSelect
        qaId="certificates-form-skills"
        label={t("whichSkillsLearned")}
        placeholder={t("typeSkillHere")}
        values={formData.skills}
        closeOnChange
        multi
        error={findError("skills")}
        query={(term) => fetchAutocompleteSkills({ term })}
        handleChange={(value) => handleFormDataChange("skills", value)}
        handleRemove={(value) => handleFormDataChange("skills", value)}
        letClear
      />
      {suggestedSkills.length > 0 && (
        <div className={css.relevantAttributes} data-qa-id="certificates-suggestions">
          <Typography variant="h5" noMargin>
            {t("suggestedAttributes")}
          </Typography>
          <Typography variant="bodyRegular" noMargin>
            {t("identifiedRelatedAttributes")}
          </Typography>
          <div className={css.suggestions}>
            {suggestedSkills?.map((suggestion) => (
              <Button
                aria-label={t("addSkill", { skill: suggestion.value })}
                icon="add"
                text={suggestion.value}
                key={`${suggestion.id}+${suggestion.globalId}`}
                kind="secondary"
                onClick={() => {
                  handleSkillsChange({ ...suggestion, text: suggestion.value });
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
