import classNames from "classnames";
import { compact, maxBy } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { mapWorkflowStatesToUI } from "PFApp/audit_planner/parts/helpers";
import MultiPage from "PFComponents/page/multi_page";
import PageCard from "PFComponents/page/page_card";
import { Tabs } from "PFComponents/tabs";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { ActivityHistoryProvider } from "../../parts/history/activity_history_context";
import { Economics } from "../economics";
import { useActivityPageCounters } from "../hooks/use_activity_page_counters";
import css from "./activity_page.module.scss";
import {
  ActivityDetails,
  ActivityFeedback,
  ActivityInterested,
  ActivityMatches,
  ActivityRoles,
  ActivityShortlist,
  ActivityVacancies,
  Header
} from "./parts";

export const ActivityPage = () => {
  const {
    task,
    isRole,
    isAudit,
    comparisonToastComponent,
    tabs,
    showTabs,
    tabActiveId,
    tabsIds,
    handleChangeTab,
    allShortlists
  } = useActivityPageContext();

  const activity = task as Activity;

  const { formatDateTime } = useDateFormatter();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches" });

  const { counters, setInterestsCount } = useActivityPageCounters();

  const tabsWithCounters = useMemo(
    () => tabs.map((tab) => ({ ...tab, count: counters[tab.id] })),
    [tabs, counters]
  );

  const tabPages = useMemo(() => {
    const canViewTab = (tabId: string) => tabsIds.indexOf(tabId) >= 0;

    return compact([
      canViewTab("details") && <ActivityDetails key="details" />,
      canViewTab("interested") && (
        <ActivityInterested
          key="interests"
          handleInterestsCountChange={(newCount) => setInterestsCount(newCount)}
        />
      ),
      canViewTab("matches") && <ActivityMatches key="matches" />,
      canViewTab("shortlist") && <ActivityShortlist key="shortlist" />,
      canViewTab("roles") && <ActivityRoles key="roles" />,
      canViewTab("vacancies") && <ActivityVacancies key="vacancies" />,
      canViewTab("feedback") && <ActivityFeedback key="feedback" />,
      canViewTab("economics") && <Economics key="economics" />
    ]);
  }, [tabsIds]);

  if (isAudit && isRole) {
    return (
      <Redirect
        to={`/audit_planner/control_centre/${mapWorkflowStatesToUI(
          mapWorkflowStatesToUI(activity.workflow_state) === "pending"
            ? ""
            : `${activity.parent_activity_id}/${activity.id}`
        )}`}
      />
    );
  }

  const lastActionDate = maxBy(allShortlists || [], "updated_at")?.updated_at;

  const isPageCardVisible = !["feedback", "matches", "shortlist"].includes(tabActiveId || "");

  const fadeHeader = comparisonToastComponent !== null;

  return (
    <ActivityHistoryProvider>
      <div className={css.container}>
        <div className={css.page}>
          <Header className={classNames({ [css.fade]: fadeHeader })} />
          <div className={classNames(css.tabs, { [css.fade]: fadeHeader })}>
            {showTabs && <Tabs onChange={handleChangeTab} tabs={tabsWithCounters} activeTab={tabActiveId} />}
            <div className={css.actions}>
              {tabActiveId === "shortlist" && (
                <span className={css.updatedAt}>{`${t("lastEdited")}: ${formatDateTime(
                  lastActionDate
                )}`}</span>
              )}
            </div>
          </div>
          {isPageCardVisible ? (
            <PageCard classes={{ wrap: css.card }}>
              <MultiPage items={tabs} active={tabActiveId}>
                {tabPages}
              </MultiPage>
            </PageCard>
          ) : (
            <div className={css.minimalisticPageContainer}>
              <div>
                <MultiPage items={tabs} active={tabActiveId}>
                  {tabPages}
                </MultiPage>
              </div>
            </div>
          )}
          {comparisonToastComponent && <div className={css.compareAlert}>{comparisonToastComponent}</div>}
        </div>
      </div>
    </ActivityHistoryProvider>
  );
};
