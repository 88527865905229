import { useQueries } from "@tanstack/react-query";
import { fetchShortlists, ShortlistCollection } from "PFCore/services/shortlist";
import { QueryParams } from "PFTypes/query_params";
import { QueryOptions } from "PFTypes/request_options";

import { shortlistsKeys } from "./query_keys";

export type MultipleShortlistsResult = {
  activityId: number;
  data?: ShortlistCollection;
  isLoading: boolean;
  error?: unknown;
};

export const useActivitiesShortlists = (
  activityIds: number[],
  params: QueryParams = {},
  options: QueryOptions<ShortlistCollection> = {}
) => {
  const queries = useQueries({
    queries: activityIds.map((activityId) => ({
      queryKey: shortlistsKeys.activityShortlists(activityId, params),
      queryFn: () => fetchShortlists(activityId, params.page, params.perPage, params.filters, params.order),
      retry: false,
      staleTime: 0,
      ...options
    }))
  });

  const results: MultipleShortlistsResult[] = queries.map((query, index) => ({
    activityId: activityIds[index],
    data: query.data,
    isLoading: query.isLoading,
    error: query.error
  }));

  const isLoading = results.some(({ isLoading }) => isLoading);

  return { results, isLoading };
};
