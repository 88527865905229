export enum MatchesShortlistColumnId {
  Match = "match",
  Availability = "availability",
  WorkforceMember = "workforceMember",
  SupportingSkills = "supportingSkills",
  EssentialSkills = "essentialSkills",
  RequiredSkills = "requiredSkills",
  RelatedSkills = "relatedSkills",
  Actions = "actions"
}
