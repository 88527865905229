import classNames from "classnames";
import { LoadingDots } from "PFComponents/loading_dots";

import { Option, OptionOriginal, SelectV2Props } from "../select_v2.types";
import { useDisplayValue } from "./use_display_value";
import css from "./value_container.module.scss";

type ValueContainerProps<T> = Pick<
  SelectV2Props<T>,
  "multiple" | "renderDisplayValue" | "onChange" | "locked" | "disabled"
> & {
  className?: string;
  selectedOptions: Option<T>[];
  displayValueRef?: (node: HTMLDivElement) => void;
  isFetching: boolean;
};

export const ValueContainer = <T extends OptionOriginal = OptionOriginal>({
  multiple,
  onChange,
  renderDisplayValue,
  displayValueRef,
  selectedOptions,
  className,
  isFetching,
  locked,
  disabled
}: ValueContainerProps<T>) => {
  const { displayElement } = useDisplayValue<T>({
    selectedOptions,
    multiple,
    onChange,
    renderDisplayValue,
    displayValueRef
  });

  return (
    <div className={classNames(className, { [css.disabled]: disabled, [css.locked]: locked })}>
      {isFetching && <LoadingDots />}
      {!isFetching && displayElement}
    </div>
  );
};
