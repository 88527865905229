import ButtonGroup from "PFComponents/button_group/button_group";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { DropdownButton } from "PFComponents/dropdown_button";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { OverbookingsManagementMode } from "PFCore/services/bookings_suggested_changes";
import { PermissionRule } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import { BookingFormProps } from "../../booking_form";
import { CreateButton } from "../create_button";
import css from "./create_with_overbooking_button_group.module.scss";
import { useCheckAdjustCurrentMode } from "./use_check_adjust_current_mode";

type CreateWithOverbookingButtonGroupProps = {
  isSubmitDisabled: boolean;
  isLoading: boolean;
  overbookingIntersectionsDates: string[];
} & Pick<BookingFormProps, "onOpenOverbookingsManagementModal">;

export const CreateWithOverbookingButtonGroup = ({
  isSubmitDisabled,
  isLoading,
  onOpenOverbookingsManagementModal,
  overbookingIntersectionsDates
}: CreateWithOverbookingButtonGroupProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking" });
  const isPermittedTo = useIsCurrentUserPermittedTo();
  const canManageOverbookings = isPermittedTo(PermissionRule.ManageOverbookings);
  const { isAdjustCurrentModeDisabled, isAdjustOtherModeDisabled } = useCheckAdjustCurrentMode({
    overbookingIntersectionsDates,
    canManageOverbookings
  });

  const options: DropdownOption[] = [
    {
      id: OverbookingsManagementMode.AdjustCurrent,
      displayElement: t("adjustCurrentOption"),
      item: OverbookingsManagementMode.AdjustCurrent,
      disabled: isAdjustCurrentModeDisabled,
      tooltipMsg: isAdjustCurrentModeDisabled ? t("disabledCurrentOptionTooltip") : undefined
    },
    {
      id: OverbookingsManagementMode.AdjustOther,
      displayElement: t("adjustOtherOption"),
      item: OverbookingsManagementMode.AdjustOther,
      disabled: isAdjustOtherModeDisabled,
      tooltipMsg: isAdjustOtherModeDisabled ? t("noPermission") : undefined
    }
  ];

  if (!canManageOverbookings) {
    return (
      <CreateButton
        label={t("createLabel")}
        isLoading={isLoading}
        disabled={isSubmitDisabled}
        kind="primary"
      />
    );
  }

  return (
    <ButtonGroup compact autoWidth>
      <CreateButton
        label={t("createLabel")}
        isLoading={isLoading}
        disabled={isSubmitDisabled}
        kind="primary"
      />
      <DropdownButton
        buttonClassName={css.dropdownButton}
        icon="chevron-down"
        options={options}
        handleChange={(item) => {
          onOpenOverbookingsManagementModal(item, overbookingIntersectionsDates);
        }}
        popperOptions={{ placement: "bottom-end" }}
        disabled={isSubmitDisabled}
        buttonKind="primary"
      />
    </ButtonGroup>
  );
};
