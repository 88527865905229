import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AvailabilityRangeItem from "./availability_range_item";
import { CheckboxesItem } from "./checkboxes_item";
import DateItem from "./date_item";
import DateRangeItem from "./date_range_item";
import { AvailabilityFilterItem } from "./filter_items/availability_filter/availability_filter_item";
import { IntegerItem } from "./integer_item";
import RadioItem from "./radio_item";
import ReportingRangeItem from "./reporting_range_item";
import { SearchItem } from "./search_item";
import { SelectManyItem } from "./select_many_item";
import { SelectOneItem } from "./select_one_item";
import { ToggleCheckboxItem } from "./toggle_checkbox_item";

const AnyFilter = (props) => {
  const { t } = useTranslation();
  const { customTypes } = useCustomTypes();

  const isOrderable = customTypes.find((customType) => customType.name === props.filter.name)?.orderable;

  const labelTooltip = useMemo(
    () =>
      isOrderable
        ? {
            icon: "hierarchical",
            content: t("filters.hierarchicalFilterMessage")
          }
        : null,
    [t, isOrderable]
  );

  switch (props.filter.type) {
    case "availability":
      return <AvailabilityFilterItem {...props} />;
    case "select_many":
      return <SelectManyItem {...props} onChange={props.handleChange} labelTooltip={labelTooltip} />;
    case "select_one":
      return <SelectOneItem {...props} onChange={props.handleChange} labelTooltip={labelTooltip} />;
    case "string":
      return <SearchItem {...props} label={props.filter.title} labelTooltip={labelTooltip} />;
    case "number":
      return <IntegerItem {...props} label={props.filter.title} />;
    case "radio":
      return <RadioItem {...props} />;
    case "checkboxes":
      return <CheckboxesItem {...props} onChange={props.handleChange} />;
    case "toggle_checkbox":
      return <ToggleCheckboxItem {...props} onChange={props.handleChange} />;
    case "date":
      return <DateItem {...props} />;
    case "date_range":
      return <DateRangeItem {...props} />;
    case "reporting_range":
      return <ReportingRangeItem {...props} />;
    case "availability_range":
      return <AvailabilityRangeItem {...props} />;
    case "hidden":
      return null;
    default:
      return (
        <b style={{ display: "block", padding: 8, border: "1px solid red" }}>{props.filter.type} (??)</b>
      );
  }
};

AnyFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    operator: PropTypes.string
  }),
  templateKey: PropTypes.string
};

export default AnyFilter;
