import { Importance } from "PFTypes";

import {
  getCustomFieldValuesOfImportance,
  GetCustomFieldValuesOfImportanceParams
} from "./get_custom_field_values_of_importance";
import { RoleMatchSkillsData } from "./get_skills.types";

export const getEssentialSkills = (
  params: Omit<GetCustomFieldValuesOfImportanceParams, "customFieldName" | "importance">
): RoleMatchSkillsData => {
  const { roleCustomValues, matchCustomValues } = getCustomFieldValuesOfImportance({
    ...params,
    customFieldName: "skills",
    importance: Importance.Essential
  });

  return {
    roleSkills: roleCustomValues,
    matchSkills: matchCustomValues
  };
};
