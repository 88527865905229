import { uniqBy } from "lodash";
import {
  getActivityCustomFieldFilteredValues,
  getActivityCustomFieldValues
} from "PFCore/helpers/activity_custom_fields";
import { Activity, ActivityPureCustomValue, Importance, Match, ProfileCustomValue, Shortlist } from "PFTypes";

export type GetCustomFieldValuesOfImportanceParams = {
  customFieldName: string;
  importance?: Importance;
  matchCustomValues: Match["customValues"];
  role: Activity;
  applicableCustomValues?: Shortlist["applicable_custom_values"];
};

type GetCustomFieldValuesOfImportance = (params: GetCustomFieldValuesOfImportanceParams) => {
  roleCustomValues: ActivityPureCustomValue[];
  matchCustomValues: ProfileCustomValue[];
};

export const getCustomFieldValuesOfImportance: GetCustomFieldValuesOfImportance = ({
  customFieldName,
  importance,
  matchCustomValues,
  role,
  applicableCustomValues = []
}) => {
  const roleCustomValues =
    importance === undefined
      ? getActivityCustomFieldValues(role, customFieldName)
      : getActivityCustomFieldFilteredValues(role, customFieldName, { importance });

  const directCustomValues = matchCustomValues.direct.map((direct) => {
    const { custom_value: customValue, ...other } = direct;
    return {
      ...other,
      ...customValue
    };
  });

  const allMatchCustomValues = uniqBy([...directCustomValues, ...applicableCustomValues], "id").filter(
    ({ id: matchedCustomValueId }) => roleCustomValues.findIndex(({ id }) => id === matchedCustomValueId) >= 0
  );

  return {
    roleCustomValues,
    matchCustomValues: allMatchCustomValues
  };
};
