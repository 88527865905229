import compact from "lodash/compact";
import { useMemo } from "react";

import { Option, OptionOriginal, ResultOption, SelectOptions, SelectV2Props } from "../select_v2.types";
import { getIds } from "../select_v2.utils";
import { areOptionsGrouped as areOptionsGroupCheck } from "../select_v2.utils";
import { useOptions, useSelectedOptions } from "../use_options";
import { getSelectAllOption } from "./get_select_all_option";

type UseDropdownOptions<T> = {
  searchTerm: string;
  isDropdownOpen: boolean;
  dropdownId: string;
  isSearchable: boolean;
} & Pick<SelectV2Props<T>, "onChange" | "value" | "multiple" | "options" | "fetchOptions" | "cacheTime">;

export type UseDropdownOptionsReturn<T> = {
  resultOptions: SelectOptions<T>;
  selectedOptions: Option<T>[];
  isFetchingAvailableOptions: boolean;
  isFetchingSelectedOptions: boolean;
  isFetchingOptionsEnabled: boolean;
  areOptionsGrouped: boolean;
};

export const useDropdownOptions = <T extends OptionOriginal = OptionOriginal>({
  value,
  options,
  fetchOptions,
  searchTerm,
  isDropdownOpen,
  dropdownId,
  cacheTime,
  multiple,
  onChange,
  isSearchable
}: UseDropdownOptions<T>): UseDropdownOptionsReturn<T> => {
  const { data: availableOptions, isFetching: isFetchingAvailableOptions } = useOptions({
    dropdownId,
    searchTerm,
    options,
    fetchOptions,
    queryOptions: {
      enabled: isDropdownOpen,
      cacheTime
    }
  });

  const isFetchingOptionsEnabled = !!fetchOptions;

  const selectedOptionIds = getIds(value);
  const { data: selectedOptions, isFetching: isFetchingSelectedOptions } = useSelectedOptions({
    dropdownId,
    selectedOptionIds,
    options,
    fetchOptions,
    queryOptions: {
      keepPreviousData: true,
      cacheTime
    }
  });

  const areOptionsGrouped = useMemo(
    () => !fetchOptions && areOptionsGroupCheck(options),
    [options, fetchOptions]
  );

  const resultOptions = useMemo(() => {
    if (areOptionsGrouped) {
      return availableOptions;
    }
    if (!isFetchingAvailableOptions && !searchTerm.trim() && isFetchingOptionsEnabled && multiple) {
      return selectedOptions;
    }
    if (isFetchingAvailableOptions) {
      return [];
    }
    const selectAllOption = getSelectAllOption<T>({
      availableOptions,
      multiple,
      selectedOptions,
      selectedOptionIds,
      onChange,
      isSearchable
    });
    return compact([selectAllOption, ...availableOptions]) as ResultOption<T>[];
  }, [
    areOptionsGrouped,
    availableOptions,
    searchTerm,
    selectedOptions,
    selectedOptionIds,
    isFetchingAvailableOptions,
    isFetchingOptionsEnabled,
    isSearchable,
    multiple,
    onChange
  ]);

  return {
    resultOptions,
    selectedOptions,
    isFetchingAvailableOptions,
    isFetchingSelectedOptions,
    isFetchingOptionsEnabled,
    areOptionsGrouped
  };
};
