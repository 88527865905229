import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { getEssentialSkills, getRequiredSkills, getSupportingSkills } from "PFApp/hooks/matches";
import { Id, Importance, Match, Shortlist } from "PFTypes";

import { SkillsCell } from "./skills_cell";

const IMPORTANCE_TO_GETTER_MAP = {
  [Importance.Supporting]: getSupportingSkills,
  [Importance.Essential]: getEssentialSkills,
  [Importance.Required]: getRequiredSkills
};

type SkillsOfImportanceCellProps = {
  importance: Importance;
  match?: Match;
  shortlist?: Shortlist;
};

export const SkillsOfImportanceCell = ({ importance, match, shortlist }: SkillsOfImportanceCellProps) => {
  const { task: role } = useActivityPageContext();

  const getSkills = IMPORTANCE_TO_GETTER_MAP[importance];

  const profileId = (match?.profile.id ?? shortlist?.profile.id) as Id;
  const matchCustomValues = match?.custom_values || { direct: [], indirect: [], related: [] };
  const applicableCustomValues = (shortlist || match?.shortlist)?.applicable_custom_values;

  const { matchSkills, roleSkills } = getSkills({
    role,
    matchCustomValues,
    applicableCustomValues
  });

  return <SkillsCell profileId={profileId} matchSkills={matchSkills} roleSkills={roleSkills} />;
};
