import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule } from "PFTypes";
import { useCallback } from "react";

import { useActivityPageContext } from "../activity_page_context";

export const useEconomicsPermissions = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { task: activity } = useActivityPageContext();
  const isPermittedTo = useIsPermittedTo(currentProfile);

  const canViewScenario = activity.coowned || isPermittedTo(PermissionRule.ShortlistWithoutOwnership);
  const canManageEconomics = isPermittedTo(PermissionRule.EngagementEconomicsManage);
  const canCreateScenarios =
    canViewScenario &&
    (canManageEconomics || isPermittedTo(PermissionRule.EngagementEconomicsCreateScenarios));

  const canManageScenario = useCallback(
    (creatorId: number) => canManageEconomics || creatorId === currentProfile.id,
    [canManageEconomics, currentProfile]
  );

  return {
    canViewScenario,
    canCreateScenarios,
    canManageScenario
  };
};
