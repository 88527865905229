import { Button } from "PFComponents/button/";
import { Typography } from "PFComponents/typography/typography";
import { useTranslation } from "react-i18next";

import css from "../ai_generator.module.scss";

type FooterProps = {
  allowMakeLonger: boolean;
  onTryAgain: VoidFunction;
  onMakeLonger: VoidFunction;
  onInsert: VoidFunction;
};

export const Footer = ({ allowMakeLonger, onTryAgain, onMakeLonger, onInsert }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <div className={css.footer}>
      <Typography variant="labelRegular" tag="span" className={css.hint}>
        {t("aiGenerator.infoMessage")}
      </Typography>
      <div className={css.buttons}>
        <Button kind="secondary" type="button" onClick={onTryAgain}>
          {t("aiGenerator.tryAgain")}
        </Button>
        {allowMakeLonger && (
          <Button kind="secondary" type="button" onClick={onMakeLonger}>
            {t("aiGenerator.makeLonger")}
          </Button>
        )}
        <Button type="button" onClick={onInsert}>
          {t("aiGenerator.insert")}
        </Button>
      </div>
    </div>
  );
};

Footer.displayName = "AIGeneratorFooter";
