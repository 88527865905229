import { ActionIcon } from "PFComponents/action_icon";
import { Flex } from "PFComponents/containers";
import { useTranslation } from "react-i18next";

type NavigationProps = {
  count: number;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const Navigation = ({ count, selectedIndex, setSelectedIndex }: NavigationProps) => {
  const { t } = useTranslation();

  if (!count) {
    return null;
  }

  return (
    <Flex gap="spacingXs" center>
      <ActionIcon
        name="chevron-left"
        size="sm"
        onClick={() => setSelectedIndex((index) => Math.max(index - 1, 0))}
        disabled={selectedIndex === 0}
      />
      {t("aiGenerator.pagination", {
        current: selectedIndex + 1,
        total: count
      })}
      <ActionIcon
        name="chevron-right"
        size="sm"
        onClick={() => setSelectedIndex((index) => Math.min(index + 1, count - 1))}
        disabled={selectedIndex === count - 1}
      />
    </Flex>
  );
};

Navigation.displayName = "AIGeneratorNavigation";
