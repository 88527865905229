import BookingPill from "PFApp/components/pills/booking_pill";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import { UpdatedBookingChange } from "../overbookings_management_modal";
import { BookingPillContent } from "./components/booking_pill_content";
import { ChangedBooking } from "./components/changed_booking";
import css from "./suggested_changes.module.scss";
import { getTextColor } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type SuggestedChangesAdjustProps = {
  bookingChange: UpdatedBookingChange;
  category: BookingCategory;
};

export const SuggestedChangesAdjust = ({ bookingChange, category }: SuggestedChangesAdjustProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });

  const { booking } = bookingChange;
  const { formatBookingDates } = useFormatBookingDates();

  const originalBookingDates = formatBookingDates(booking.startDate, booking.endDate);
  const isSingleChange = bookingChange.changes.length === 1;

  const textColor = getTextColor(category);

  return (
    <div className={css.flexColumnContainer}>
      <div className={css.categoryContainer}>
        <Typography variant="bodyBold" tag="span">
          {category.display_as}
        </Typography>
      </div>

      <div
        className={css.changesContainer}
        style={{
          flexDirection: isSingleChange ? "row" : "column",
          justifyContent: isSingleChange ? "space-between" : "flex-start"
        }}
      >
        <div
          className={css.flexColumnContainer}
          style={{
            width: isSingleChange ? "50%" : "100%"
          }}
        >
          <Typography variant="bodyRegular" tag="span">
            {t("originalBooking")}
          </Typography>
          <BookingPill
            title={originalBookingDates}
            category={category}
            className={css.bookingPill}
            style={{
              color: textColor,
              borderWidth: 1
            }}
          >
            <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
          </BookingPill>
        </div>

        <div
          className={css.flexColumnContainer}
          style={{
            width: isSingleChange ? "50%" : "100%"
          }}
        >
          <Typography variant="bodyRegular" tag="span">
            {t("newBooking")}
          </Typography>
          {bookingChange.changes.map((change, index) => (
            <ChangedBooking key={index} suggestedChange={change} category={category} />
          ))}
        </div>
      </div>
    </div>
  );
};
