import { ColumnHelper } from "@tanstack/react-table";
import { Typography } from "PFComponents/typography";
import { EconomicsVacancy } from "PFTypes";
import { useCallback } from "react";

import { useCostFieldLabel } from "../../../use_cost_field_label";
import css from "../vacancies_table.module.scss";

export const useCostFieldColumn = () => {
  const costFieldLabel = useCostFieldLabel();

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor(({ costFieldValue }) => costFieldValue, {
        id: "costFieldValue",
        header: () => (
          <Typography className={css.costFieldHeader} variant="bodyRegular" noMargin clipOverflow>
            {costFieldLabel}
          </Typography>
        ),
        cell: (info) => (
          <Typography className={css.gradeCellContent} variant="bodyRegular" noMargin clipOverflow>
            {info.getValue() || "-"}
          </Typography>
        ),
        size: 100,
        enableSorting: true
      }),
    [costFieldLabel]
  );
};
