import { SkillsList } from "PFApp/components/skills_list";
import { generateMatchCustomValues } from "PFCore/helpers/custom_value";
import { ActivityPureCustomValue, MatchCustomValue } from "PFTypes";
import { Trans, useTranslation } from "react-i18next";

import { useMatchCardContext } from "../../match_card_context";
import { InfoSection } from "../info_section";

type MatchCustomFieldSectionProps = {
  customFieldLabel?: string;
  customFieldName: string;
  i18nKey: string;
  matchCustomValues: MatchCustomValue[];
  roleCustomValues: ActivityPureCustomValue[];
  skillTruncateLength?: number;
};

export const MatchCustomFieldSection = ({
  customFieldLabel,
  customFieldName,
  i18nKey,
  matchCustomValues,
  roleCustomValues,
  skillTruncateLength
}: MatchCustomFieldSectionProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });
  const { id: profileId, animate } = useMatchCardContext();
  const matchAndActivityCustomValues = generateMatchCustomValues(matchCustomValues, roleCustomValues);

  return (
    <InfoSection
      title={
        <Trans
          t={t}
          i18nKey={i18nKey as any}
          values={{
            name: customFieldLabel,
            count: matchCustomValues.length,
            total: roleCustomValues.length
          }}
          components={[<strong key="0" />]}
        />
      }
    >
      <SkillsList
        customFieldName={customFieldName}
        skills={matchAndActivityCustomValues}
        profileId={profileId}
        skillTruncateLength={skillTruncateLength}
        onMoreLessButtonClick={animate}
      />
    </InfoSection>
  );
};
