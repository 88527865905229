/* eslint-disable camelcase */
import { decamelizeKeys } from "humps";
import { trim } from "lodash";
import { IRoleWatchersResponse } from "PFApp/activities/types/watchers";
import { MatchingRelationType } from "PFApp/constants/matching_relation_type";
import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { decamelizeDesiredKeys } from "PFCore/helpers/decamelize_desired_keys";
import { ApiRoute } from "PFCore/utilities/routes";
import {
  Activity,
  ActivityData,
  Collection,
  Id,
  MatchedActivity,
  OrderOption,
  PaginatedCollection,
  PostUntilChangeReasonData,
  QueryParams
} from "PFTypes";
import { ActivityFeedback, ActivityFeedbackCreateData, FeedbackForProfile } from "PFTypes/activity_feedback";
import { ActivityParticipant } from "PFTypes/activity_participant";

export type ActivityResponseData = { id: Id; activity: Activity };

export type ActivitiesResponse = PaginatedCollection<ActivityResponseData[]>;

// TODO: [PROF-3813] params should be typed when filters types are fixed
export const fetchActivities = (params?: QueryParams, headers?: any): Promise<ActivitiesResponse> =>
  api({
    url: "activities",
    method: "GET",
    params,
    headers
  }).then((res) => decamelizeDesiredKeys<ActivitiesResponse>(res, ["entries", "meta.filters"])); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export const fetchActivity = (activityId: Id): Promise<Activity> =>
  api({
    url: `activities/${activityId}`,
    method: "GET"
  }).then((res) => decamelizeKeys(res) as Activity); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export const closeActivity = (activityId: number, vacanciesClosureReason: string | null): Promise<Activity> =>
  api({
    url: `activities/${activityId}/close`,
    method: "PUT",
    data: {
      ...(vacanciesClosureReason ? { vacanciesClosureReason } : {})
    }
  }).then((res) => decamelizeKeys(res) as Activity); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export type ReopenActivityPayload = {
  activityId: number;
  postUntil: string;
  postUntilChangeReason?: PostUntilChangeReasonData;
};

export const reopenActivity = ({ activityId, ...data }: ReopenActivityPayload): Promise<void> =>
  api({
    url: `activities/${activityId}/reopen`,
    method: "PUT",
    data
  });

export const updateActivity = (activityId: Id, data: ActivityData): Promise<Activity> =>
  api({
    url: `activities/${activityId}`,
    method: "PUT",
    data
  }).then((res) => decamelizeKeys(res) as Activity); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export const createActivity = (data: ActivityData): Promise<Activity> =>
  api({
    url: "activities",
    method: "POST",
    data
  }).then((resp) => decamelizeKeys(resp) as Activity); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export const deleteActivity = (activityId: Id): Promise<void> =>
  api({
    url: `activities/${activityId}`,
    method: "DELETE"
  });

export const forceManual = (activityId: number) =>
  api({
    url: `audit_roles/${activityId}/force_manual`,
    method: "PUT"
  });

export const fetchActivityWatchers = (
  activityId: number,
  page = 1,
  perPage = 10,
  filters = {},
  order?: Partial<OrderOption>
): Promise<IRoleWatchersResponse> =>
  fetchPaginatedCollection(ApiRoute(`/api/activities/${activityId}/interests`), {
    perPage,
    page,
    filters,
    order
  });

export type QuestionnaireAnswers = {
  answer1?: boolean | null;
  answer2?: boolean | null;
  answer3?: boolean | null;
  statement?: string | null;
};

export type ShowInterestResponse = QuestionnaireAnswers & {
  id: number;
  activity_id: number;
  profile_id: number;
  createdAt: string;
};

export const showInterest = (
  activityId: Id,
  data: QuestionnaireAnswers = {}
): Promise<ShowInterestResponse> =>
  api({
    url: `activities/${activityId}/interests`,
    method: "POST",
    data
  });

export const removeInterest = (activityId: Id, interestId: Id): Promise<void> =>
  api({
    url: `activities/${activityId}/interests/${interestId}`,
    method: "DELETE"
  });

export const fetchActivityFeedbackParticipants = (
  activityId: Id
): Promise<Collection<ActivityParticipant[]>> =>
  api({
    url: `activities/${activityId}/feedback_participants`,
    method: "GET"
  });

export const fetchActivityFeedback = (feedbackId: Id): Promise<ActivityFeedback> =>
  api({
    url: `feedback/${feedbackId}`,
    method: "GET"
  });

export const fetchProfileFeedback = (
  profileId: Id,
  roleIds: Id[]
): Promise<Collection<FeedbackForProfile[]>> =>
  api({
    url: "feedback",
    params: { profileId, roleIds }
  });

export const sendActivityFeedback = (data: ActivityFeedbackCreateData): Promise<void> =>
  api({
    url: "feedback",
    method: "POST",
    data
  });

type MatchingActivitiesData = QueryParams & {
  profileId: Id;
  relationType: MatchingRelationType;
};

export type MatchingActivitiesResponse = PaginatedCollection<MatchedActivity[]>;

export const fetchMatchingActivities = (data: MatchingActivitiesData): Promise<MatchingActivitiesResponse> =>
  api<MatchingActivitiesResponse>({
    url: "matching_activities",
    method: "POST",
    data
  }).then((res) => decamelizeDesiredKeys<MatchingActivitiesResponse>(res, ["entries", "meta.filters"])); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export type ActivitiesOptionsParams = {
  term: string;
  templateKey: string;
  perPage?: number;
};

type ActivityOption = {
  id: Id;
  name: string;
  templateId: Id;
  parentActivityId?: Id;
};

export type ActivityOptionsResponse = Collection<ActivityOption[]>;

export const fetchActivitiesOptions = ({ term, templateKey, perPage }: ActivitiesOptionsParams) => {
  const trimmedTerm = trim(term);

  return api<ActivityOptionsResponse>({
    url: "options/activities",
    params: {
      ...(trimmedTerm ? { term: trimmedTerm } : {}),
      templateKey,
      perPage
    }
  });
};
