import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks/use_template";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useActivityFeedbackParticipants } from "PFCore/hooks/queries/activity/use_activity_feedback_participants";
import { Activity, FeatureFlag } from "PFTypes";

export const useEngagementFeedbackParticipants = (activity: Activity) => {
  const isFeatureEnabled = useIsFeatureEnabled();
  const template = useTemplate(activity.template_id);
  const isEngagementFeedbackEnabled = isFeatureEnabled(FeatureFlag.EngagementFeedback);
  const isEngagement = template?.key === TemplateKey.Engagement;
  return useActivityFeedbackParticipants(activity.id, {
    enabled: isEngagementFeedbackEnabled && isEngagement
  });
};
