import css from "./required_text.module.scss";

type RequiredTextProps = {
  label: string;
};

export const RequiredText = ({ label }: RequiredTextProps) => {
  const words = label.split(" ");
  const wordsExceptLast = words.slice(0, -1).join(" ");
  const lastWord = words[words.length - 1];

  return (
    <>
      {wordsExceptLast} <span className={css.noWrap}>{lastWord} *</span>
    </>
  );
};
