import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { OverbookedDates, OverbookingsManagementMode } from "PFCore/services/bookings_suggested_changes";
import { Booking, BookingCategory, Profile } from "PFTypes";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { BookingSelectOption } from "../booking_questions/booking_questions";
import { GroupBookingsQuestionsOption } from "../booking_questions/group_bookings_questions";
import { UpdateQuestionsModal } from "../booking_questions/update_questions_modal";
import { OverbookingsManagementModal } from "../overbookings_management_modal/overbookings_management_modal";
import { bookingFormValuesToSuggestedChangesData } from "../overbookings_management_modal/overbookings_management_modal.utils";
import { BookingForm, BookingFormProps } from "./booking_form";
import { useBookingFormContext } from "./booking_form_context_provider";
import { useInitialActivityId, useInitialBookingType, useInitialGlobalCategory } from "./initial_data";
import { useOverbookingsManagementApiContext } from "./overbookings_management_context/overbookings_management_context";
import { useApplySuggestedChanges } from "./use_apply_suggested_changes";
import { BookingDataItem, BookingFormDataCreateMode, BookingFormMode } from "./use_booking_form";
import { useHandleSubmit } from "./use_handle_submit";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";
import { GroupBookingOption } from "./workforce_member_select/workforce_member_select_dropdown";

const DEFAULT_FORM_VALUES = {
  updateGroupOptionSelected: GroupBookingsQuestionsOption.GroupThis,
  updateRepeatOptionSelected: BookingSelectOption.All,
  globalOverridesDiaryTime: true,
  globalOverridesNonWorkingTime: false
};

export enum BookingType {
  Single = "single",
  Repeated = "repeated"
}

type BookingFormWorkforceMember = Profile | GroupBookingOption;

export type BookingFormValues = {
  workforceMember?: BookingFormWorkforceMember;
  activityId?: number;
  bookingType: BookingType;
  bookings: BookingDataItem[];
  globalTitle?: string;
  globalDescription?: string;
  globalCategory?: BookingCategory | null;
  globalOverridesDiaryTime: boolean;
  globalOverridesNonWorkingTime: boolean;
  updateGroupOptionSelected: GroupBookingsQuestionsOption;
  updateRepeatOptionSelected: BookingSelectOption;
};

type OverbookingsManagementModalState = {
  isOpen: boolean;
  mode: OverbookingsManagementMode | null;
  overbookedDates: OverbookedDates;
};

const INITIAL_OVERBOOKINGS_MANAGEMENT_MODAL_STATE: OverbookingsManagementModalState = {
  isOpen: false,
  mode: null,
  overbookedDates: []
};

type BookingFormProviderProps = Omit<BookingFormProps, "onOpenOverbookingsManagementModal"> & {
  initialBooking?: Booking;
  onSuccess?: (data?: OnSuccessData) => Promise<void>;
};

export const BookingFormProvider = ({
  show,
  initialData = {},
  initialBooking,
  onSuccess,
  mode,
  goToDate
}: BookingFormProviderProps) => {
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [showOverbookingsManagementModal, setShowOverbookingsManagementModal] =
    useState<OverbookingsManagementModalState>(INITIAL_OVERBOOKINGS_MANAGEMENT_MODAL_STATE);

  const {
    modal: { close }
  } = useBookingFormContext();

  const { clearOverbookedBookings } = useOverbookingsManagementApiContext();
  const { isBookingResourcer } = useBookingModulePermissions();

  const isCreateMode = mode === BookingFormMode.Create;

  const methods = useForm<BookingFormValues>({
    defaultValues: DEFAULT_FORM_VALUES
  });
  useInitialActivityId({
    setValue: methods.setValue
  });
  useInitialGlobalCategory({
    enabled: isCreateMode,
    setValue: methods.setValue,
    bookingCategoryId: isCreateMode ? (initialData as BookingFormDataCreateMode).bookingCategoryId : undefined
  });
  useInitialBookingType({
    initialBooking,
    setValue: methods.setValue
  });

  const handleClose = () => {
    clearOverbookedBookings();
    methods.reset();
    close();
  };

  const { handleSubmit } = useHandleSubmit({
    initialData,
    mode,
    onSuccess: (data) => {
      onSuccess?.(data);
      handleClose();
    },
    onError: () => handleClose(),
    formMethods: methods
  });

  const handlePreSubmitActions = async (values: BookingFormValues) => {
    const { bookingType } = methods.getValues();
    const isGrouped = !!initialBooking?.booking_group_id;
    const isRepeated = bookingType === BookingType.Repeated;

    if (mode === BookingFormMode.Edit && (isGrouped || isRepeated) && isBookingResourcer) {
      setShowQuestionsModal(true);
    } else {
      await handleSubmit(values);
    }
  };

  const handleFormSubmit = methods.handleSubmit(handlePreSubmitActions);
  const handleConfirmSubmit = methods.handleSubmit(handleSubmit);

  const handleCloseOverbookingsManagementModal = () => {
    setShowOverbookingsManagementModal(INITIAL_OVERBOOKINGS_MANAGEMENT_MODAL_STATE);
  };

  const bookingsData = bookingFormValuesToSuggestedChangesData(
    methods.getValues(),
    showOverbookingsManagementModal.overbookedDates,
    showOverbookingsManagementModal.mode
  );

  const { handleApplySuggestedChanges } = useApplySuggestedChanges({
    mode: showOverbookingsManagementModal.mode,
    handleSubmit,
    formMethods: methods
  });

  return (
    <FormProvider {...methods}>
      <form id="booking_form" onSubmit={handleFormSubmit}>
        <BookingForm
          show={show}
          initialData={initialData}
          mode={mode}
          onClose={handleClose}
          goToDate={goToDate}
          onOpenOverbookingsManagementModal={(mode, overbookedDates) => {
            setShowOverbookingsManagementModal({
              isOpen: true,
              mode,
              overbookedDates
            });
          }}
        />
      </form>
      {showQuestionsModal && (
        <UpdateQuestionsModal
          onClose={() => setShowQuestionsModal(false)}
          onConfirm={handleConfirmSubmit}
          initialBooking={initialBooking}
        />
      )}
      {showOverbookingsManagementModal.isOpen && (
        <OverbookingsManagementModal
          onClose={handleCloseOverbookingsManagementModal}
          onConfirm={handleApplySuggestedChanges}
          category={methods.getValues().globalCategory ?? bookingsData?.bookings[0]?.category}
          bookingsData={bookingsData}
        />
      )}
    </FormProvider>
  );
};
