import { ColumnHelper } from "@tanstack/react-table";
import { isNil } from "lodash";
import Table from "PFComponents/table/table";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { EconomicsVacancy, EconomicsVacancyProfile, Id } from "PFTypes";
import { useCallback, useMemo } from "react";

import { useActionsColumn } from "./columns/use_actions_column";
import { useAvailabilityColumn } from "./columns/use_availability_column";
import { useCheckboxColumn } from "./columns/use_checkbox_column";
import { useCostFieldColumn } from "./columns/use_cost_field_column";
import { useMatchColumn } from "./columns/use_match_column";
import { useStateColumn } from "./columns/use_state_column";
import { useWorkforceMemberColumn } from "./columns/use_workforce_member_column";

type VacanciesTableProps = {
  vacancies: EconomicsVacancy[];
  scenarioId: Id;
  isEditable?: boolean;
  selectedVacancies: Id[];
  hasUnassignedVacancies: boolean;
  hasOpenVacancies: boolean;
  onSelectVacancy: (vacancyId: Id, select: boolean) => void;
  checkCanSelectVacancy: (roleId: number, profile: EconomicsVacancyProfile) => boolean;
  getVacancyProfile: (vacancy: EconomicsVacancy) => EconomicsVacancyProfile;
};

export const VacanciesTable = ({
  vacancies,
  scenarioId,
  isEditable = true,
  selectedVacancies,
  hasUnassignedVacancies,
  hasOpenVacancies,
  checkCanSelectVacancy,
  onSelectVacancy
}: VacanciesTableProps) => {
  const activityId = vacancies[0]?.activity.id;
  const { data } = useActivityShortlists(activityId, undefined, { enabled: !isNil(activityId) });
  const shortlists = useMemo(() => data?.entries || [], [data?.entries]);

  const handleDeleteAssignment = useCallback(
    (vacancyId: Id) => {
      onSelectVacancy?.(vacancyId, false);
    },
    [onSelectVacancy]
  );

  const getVacancyProfile = useCallback((vacancy: EconomicsVacancy) => vacancy.assignment!.profile!, []);

  const renderCheckboxColumn = useCheckboxColumn({
    selectedVacancies,
    hasUnassignedVacancies,
    hasOpenVacancies,
    shortlists,
    isEditable,
    onSelectVacancy,
    checkCanSelectVacancy
  });
  const renderWorkforceMemberColumn = useWorkforceMemberColumn({ enableSorting: true, getVacancyProfile });
  const renderCostFieldColumn = useCostFieldColumn();
  const renderMatchColumn = useMatchColumn();
  const renderAvailabilityColumn = useAvailabilityColumn();
  const renderStateColumn = useStateColumn({
    shortlists,
    getVacancyProfile
  });
  const renderActionsColumn = useActionsColumn({
    scenarioId,
    isEditable,
    onDelete: handleDeleteAssignment
  });

  const columnsGenerator = useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) => [
      renderCheckboxColumn(columnHelper),
      renderWorkforceMemberColumn(columnHelper),
      renderCostFieldColumn(columnHelper),
      renderMatchColumn(columnHelper),
      renderAvailabilityColumn(columnHelper),
      renderStateColumn(columnHelper),
      renderActionsColumn(columnHelper)
    ],
    [
      renderActionsColumn,
      renderAvailabilityColumn,
      renderCheckboxColumn,
      renderCostFieldColumn,
      renderMatchColumn,
      renderStateColumn,
      renderWorkforceMemberColumn
    ]
  );

  return (
    <Table<EconomicsVacancy>
      compact
      generateColumns={columnsGenerator}
      tableData={vacancies}
      disableAutoResizing
    />
  );
};
