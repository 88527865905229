import useDebounce from "PFCore/helpers/use_debounce";
import { useState } from "react";

import { DropdownData } from "./use_dropdown";

const DEBOUNCE_TIME = 500;
const NO_DEBOUNCE_TIME = 1;

type UseDeboncedSearchTermProps = Pick<
  DropdownData<string>,
  "searchTerm" | "setSearchTerm" | "isFetchingOptionsEnabled"
>;

type UseDebouncedSearchTermReturn = {
  currentSearchTerm: string;
  setDebouncedSearchTerm: (string) => void;
};

export const useDebouncedSearchTerm = ({
  searchTerm,
  setSearchTerm,
  isFetchingOptionsEnabled
}: UseDeboncedSearchTermProps): UseDebouncedSearchTermReturn => {
  const [queryTerm, setQueryTerm] = useState(searchTerm);

  const debouncedChangeSearchTerm = useDebounce(
    (newTerm: string) => {
      setSearchTerm(newTerm);
    },
    isFetchingOptionsEnabled ? DEBOUNCE_TIME : NO_DEBOUNCE_TIME
  );

  const setDebouncedSearchTerm = (value) => {
    debouncedChangeSearchTerm(value);
    setQueryTerm(value);
  };

  return {
    currentSearchTerm: queryTerm,
    setDebouncedSearchTerm
  };
};
