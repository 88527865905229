import { compact } from "lodash";
import { useMemo } from "react";

import { useProfileCustomValues } from "../../../hooks/use_profile_custom_values";

export const useMatchSidebarCustomTypes = () => {
  const { getCustomType } = useProfileCustomValues();
  const matchSidebarInfoCustomTypes = useMemo(
    () =>
      compact([
        getCustomType("match_sidebar_info_1"),
        getCustomType("match_sidebar_info_2"),
        getCustomType("match_sidebar_info_3")
      ]),
    [getCustomType]
  );

  return {
    matchSidebarInfoCustomTypes
  };
};
