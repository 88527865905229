import { ActionIcon } from "PFComponents/action_icon";
import { SyntheticEvent } from "react";

import css from "./accordion_header.module.scss";

type AccordionHeaderProps = {
  title: string;
  canClear: boolean;
  canRestore: boolean;
  disabled: boolean;
  clearTitle: string;
  restoreTitle?: string;
  onClear: (event?: SyntheticEvent) => void;
  onRestore?: (event?: SyntheticEvent) => void;
};

export const AccordionHeader = ({
  title,
  canClear,
  canRestore,
  disabled,
  clearTitle,
  restoreTitle,
  onClear,
  onRestore
}: AccordionHeaderProps) => {
  const showActions = canClear || canRestore;

  return (
    <div className={css.accordionHeader}>
      {title}
      {showActions && (
        <span className={css.buttons}>
          {canRestore && (
            <ActionIcon
              name="history"
              size="sm"
              onClick={onRestore}
              disabled={disabled}
              title={restoreTitle}
            />
          )}
          {canClear && (
            <ActionIcon
              name="filter-clean"
              size="sm"
              onClick={onClear}
              disabled={disabled}
              title={clearTitle}
            />
          )}
        </span>
      )}
    </div>
  );
};
