import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistFill } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { Activity, Id } from "PFTypes";
import { useCallback } from "react";

export const useShortlistAndFillAction = () => {
  const growlError = useErrorsGrowl();

  const { mutateAsync: fill, isLoading: isFilling } = useShortlistFill();
  const { mutateAsync: createShortlist, isLoading: isCreatingShortlist } = useShortlistCreate();
  const { invalidateVacancies } = useActivityInvalidate();

  const handleFill = useCallback(
    async ({
      activity,
      profileId,
      shortlistId,
      vacancyId
    }: {
      activity: Activity;
      profileId: Id;
      shortlistId?: Id;
      vacancyId?: Id;
    }) => {
      try {
        const finalShortlistId =
          shortlistId || (await createShortlist({ activityId: activity.id, profileId })).id;

        const response = await fill({
          id: finalShortlistId,
          vacancyId: Number(vacancyId)
        });

        await invalidateVacancies(activity.id);

        return response;
      } catch ({ response }) {
        growlError(response);
      }
    },
    [createShortlist, fill, growlError, invalidateVacancies]
  );

  const isLoading = isFilling || isCreatingShortlist;

  return {
    isLoading,
    handleFill
  };
};
