import { Button } from "PFComponents/button";
import { Markdown } from "PFComponents/markdown";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useTranslation } from "react-i18next";

import { DefaultTermsOfService } from "./default_terms_of_service";
import css from "./terms_of_service.module.scss";

type TermsOfServiceProps =
  | {
      showAccept: true;
      acceptDisabled?: boolean;
      onAccept: VoidFunction;
    }
  | {
      showAccept?: false;
      acceptDisabled?: never;
      onAccept?: never;
    };

export const TermsOfService = ({ showAccept = false, acceptDisabled, onAccept }: TermsOfServiceProps) => {
  const { t } = useTranslation();
  const { data: currentAccount } = useCurrentAccount();

  const rawCopy = currentAccount?.customization?.terms_and_conditions?.trim();

  return (
    <div className={css.container}>
      <div className={css.markdownWrap}>
        {rawCopy ? <Markdown raw={rawCopy} crop={1e10} emojify={false} /> : <DefaultTermsOfService />}
      </div>
      {showAccept && (
        <Button
          text={t("accept")}
          disabled={acceptDisabled}
          onClick={onAccept}
          className={css.acceptButton}
        />
      )}
    </div>
  );
};
