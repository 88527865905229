import { find } from "lodash";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTemplate } from "../../../../hooks";
import { useActivityPageContext } from "../../activity_page_context";

export const useCostFieldLabel = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });

  const { task: activity } = useActivityPageContext();
  const { economics_config: economicsConfig } = useTemplate(activity.template_id) || {};

  const { customTypes } = useCustomTypes();

  const costFieldLabel =
    find(customTypes, ["name", economicsConfig?.cost_field_name])?.display_as || t("grade");

  return useMemo(() => costFieldLabel, [costFieldLabel]);
};
