import { useActivityLink } from "PFApp/booking/parts/overview/detail_view/booking_detail/use_activity_link";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import NoProfilesIllustration from "PFTheme/graphics/illustrations/illustration-no-profiles.svg";
import { Activity } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./no_matches_placeholder.module.scss";
import { ProfilesTab } from "./reassign_content";

type NoMatchesPlaceholderProps = {
  activity: Activity | undefined;
  parentActivity: Activity | undefined;
  changeSelectedTab: (selectedTabId: ProfilesTab) => void;
  canReassignBookingsNamedResources: boolean;
};

export const NoMatchesPlaceholder = ({
  activity,
  parentActivity,
  changeSelectedTab,
  canReassignBookingsNamedResources
}: NoMatchesPlaceholderProps) => {
  const { t } = useTranslation(["core", "bookingModule"]);
  const linkUrl = useActivityLink({ activity, parentActivity });

  const isCompleted = activity?.state === "complete";

  return (
    <div className={css.root}>
      <NoProfilesIllustration className={css.icon} />
      <div className={css.messageContainer}>
        <Typography variant="h5" noMargin>
          {t("bookingModule:bookings.reassign.sidePanel.noMatches")}
        </Typography>
        <Typography variant="h5" noMargin className={css.actions}>
          {linkUrl && (
            <>
              <Trans
                i18nKey={
                  isCompleted
                    ? "bookingModule:bookings.reassign.sidePanel.closedRoleAction"
                    : "bookingModule:bookings.reassign.sidePanel.noMatchesAction"
                }
                t={t}
                values={{ activityName: activity?.name ?? t("core:activity") }}
                components={[<Link key="activityLink" to={linkUrl} />]}
              />
              {canReassignBookingsNamedResources && (
                <Trans
                  i18nKey={"bookingModule:bookings.reassign.sidePanel.noMatchesActionNamedResource"}
                  t={t}
                  components={[
                    <Button
                      key="namedResourceButton"
                      kind="text"
                      onClick={() => changeSelectedTab(ProfilesTab.NamedResource)}
                    />
                  ]}
                />
              )}
            </>
          )}
        </Typography>
      </div>
    </div>
  );
};
