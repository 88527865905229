import { useTranslation } from "react-i18next";

import { Button } from "../../button";
import { Icon } from "../../icon";
import { Typography } from "../../typography";
import css from "../file_upload.module.scss";

export type UploadedContentProps = {
  uploadedFile?: string;
  hasRemoveButton?: boolean;
  disabled?: boolean;
  onReset?: () => void;
};

export const UploadedContent = ({
  uploadedFile,
  hasRemoveButton = true,
  onReset,
  disabled
}: UploadedContentProps) => {
  const { t } = useTranslation("core", { keyPrefix: "components.fileUpload" });

  const buttonLabel = hasRemoveButton ? t("remove") : t("startNewUpload");

  return (
    <div className={css.wrapper}>
      <Icon name="check" size="xxl" color="paletteGreen0" />
      <Typography variant="h4" noMargin clipOverflow>
        {t("importSuccessful")}
      </Typography>
      <Typography
        className={css.uploadedFile}
        variant="bodyRegular"
        noMargin
        clipOverflow
        title={uploadedFile}
      >
        {uploadedFile}
      </Typography>
      <Button
        text={buttonLabel}
        kind={hasRemoveButton ? "danger" : "secondary"}
        onClick={onReset}
        disabled={disabled}
      />
    </div>
  );
};
