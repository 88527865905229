import { Checkbox } from "PFComponents/checkbox";
import { Typography } from "PFComponents/typography";
import { CheckboxesFieldValue } from "PFTypes";

import { ComponentProps } from "../component_prop_types";
import css from "./checkboxes_wrapper.module.scss";

type CheckboxesWrapperProps = Pick<ComponentProps, "property" | "label" | "error" | "required"> & {
  value: CheckboxesFieldValue;
  onChange: (newValue: CheckboxesFieldValue) => void;
};

export const CheckboxesWrapper = ({
  property,
  label,
  onChange,
  error,
  value = [],
  required
}: CheckboxesWrapperProps) => {
  const handleChange = (id: string, checked: boolean) => {
    const newValue = [...value];
    const checkboxIndex = newValue.findIndex((checkbox) => checkbox.id === id);

    if (checkboxIndex < 0) {
      newValue.push({
        id,
        value: checked
      });
    } else {
      newValue[checkboxIndex].value = checked;
    }

    onChange(newValue);
  };

  return (
    <div className={css.root}>
      <Typography variant="bodyBold" noMargin>
        {label}
        {required && " *"}
      </Typography>
      <div className={css.checkboxes}>
        {property.options!.map(({ id, label: checkboxLabel }) => (
          <Checkbox
            key={id}
            checked={Boolean(value.find((checkbox) => checkbox.id === id)?.value)}
            onChange={(checked) => handleChange(id, checked)}
            label={checkboxLabel}
          />
        ))}
      </div>
      {error && (
        <Typography variant="labelRegular" className={css.error}>
          {error}
        </Typography>
      )}
    </div>
  );
};
