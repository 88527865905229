import { Modal } from "PFComponents/modal";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { Template } from "PFTypes";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

type ExpiryDateModalProps = {
  activityId: number;
  templateKey: Template["key"];
  mode: "publish" | "edit" | "clone";
  onClose: () => void;
};

export const ExpiryDateWarningModal = ({
  mode,
  activityId,
  templateKey,
  onClose
}: ExpiryDateModalProps): JSX.Element => {
  const history = useHistory();
  const { t } = useOptionalTranslation("activities");
  const redirectUrl = ["publish", "edit"].includes(mode)
    ? `/activities/${activityId}/edit`
    : `/activities/${templateKey}/new/${activityId}`;

  const title = useMemo(() => t(`show.${mode}`), [mode, t]);

  return (
    <Modal title={title} onOK={() => history.push(redirectUrl)} onClose={onClose}>
      {t("show.activityWithExpiryDate")}
    </Modal>
  );
};
