import BookingPill from "PFApp/components/pills/booking_pill";
import { SuggestedChange } from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React from "react";

import css from "../suggested_changes.module.scss";
import { getTextColor } from "../suggested_changes.utils";
import { useFormatBookingDates } from "../use_format_booking_dates";
import { BookingPillContent } from "./booking_pill_content";

type ChangedBookingProps = {
  suggestedChange: SuggestedChange;
  category: BookingCategory;
};

export const ChangedBooking = ({ suggestedChange, category }: ChangedBookingProps) => {
  const { formatBookingDates } = useFormatBookingDates();

  const bookingDates =
    suggestedChange.data?.startDate && suggestedChange.data?.endDate
      ? formatBookingDates(suggestedChange.data.startDate, suggestedChange.data.endDate)
      : undefined;

  const textColor = getTextColor(category);

  return (
    <BookingPill
      title={bookingDates}
      category={category}
      className={css.bookingPill}
      style={{
        color: textColor,
        borderWidth: 1
      }}
    >
      <BookingPillContent
        startDate={suggestedChange.data?.startDate}
        endDate={suggestedChange.data?.endDate}
      />
    </BookingPill>
  );
};
