import AppContext from "PFApp/app_context";
import { Button } from "PFComponents/button";
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_ASYNC_ACTIONS } from "PFReducers/notifications_reducer";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const NotificationsPanelActions = () => {
  const {
    dispatch,
    store: {
      notifications: { showUnreadOnly, target }
    }
  } = useContext(AppContext);
  const { t } = useTranslation();

  const markAllAsRead = () =>
    dispatch({
      type: NOTIFICATIONS_ASYNC_ACTIONS.BULK_MARK_AS_READ,
      payload: {
        ...(target ? { target_types: target } : { excluded_target_types: ["Chat::Conversation"] })
      }
    }).then(() => {
      dispatch({
        type: NOTIFICATIONS_ASYNC_ACTIONS.NOTIFICATIONS_FETCH_COLLECTION,
        payload: { showUnreadOnly, target }
      });
    });

  const toggleShowUnreadOnly = () => {
    dispatch({
      type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_PAGE,
      payload: 1
    });
    dispatch({ type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_SHOW_UNREAD_ONLY, payload: !showUnreadOnly });
  };

  return (
    <>
      <Button small kind="text" onClick={toggleShowUnreadOnly}>
        {showUnreadOnly ? t("notifications.showAll") : t("notifications.showUnreadOnly")}
      </Button>
      <Button text={t("notifications.markAllAsRead")} small kind="text" onClick={markAllAsRead} />
    </>
  );
};

export default NotificationsPanelActions;
