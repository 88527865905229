import classNames from "classnames";
import BookingPill from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";
import React from "react";

import { UpdatedBookingChange } from "../overbookings_management_modal";
import { BookingPillContent } from "./components/booking_pill_content";
import { DeleteBookingAction } from "./components/delete_booking_action";
import css from "./suggested_changes.module.scss";
import { getTextColor } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type SuggestedChangesDeleteProps = {
  bookingChange: UpdatedBookingChange;
  category: BookingCategory;
  onToggleRemoveBooking: (index: number) => void;
};

export const SuggestedChangesDelete = ({
  bookingChange,
  category,
  onToggleRemoveBooking
}: SuggestedChangesDeleteProps) => {
  const { booking } = bookingChange;
  const { formatBookingDates } = useFormatBookingDates();

  const originalBookingDates = formatBookingDates(booking.startDate, booking.endDate);
  const textColor = getTextColor(category);

  return (
    <div className={css.flexColumnContainer}>
      <div className={css.categoryContainer}>
        <Icon name="warning" color="paletteOrange0" />
        <Typography variant="bodyBold" tag="span">
          {category.display_as}
        </Typography>
      </div>

      <div className={classNames(css.flexColumnContainer, css.singleChangeContainer)}>
        <DeleteBookingAction
          shouldBeRemoved={bookingChange.shouldBeRemoved}
          onToggle={() => onToggleRemoveBooking(bookingChange.bookingChangeIndex)}
        />
        <BookingPill
          title={originalBookingDates}
          category={category}
          className={css.bookingPill}
          style={{
            color: textColor,
            borderWidth: 1
          }}
        >
          <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
        </BookingPill>
      </div>
    </div>
  );
};
