import { Divider } from "PFComponents/divider";
import { Tile } from "PFComponents/tile";

import { useActivityPageContext } from "../../../../../../activity_page_context";
import { MatchAttributes } from "../../../components/match_attributes";
import { RoleInformation } from "../../../components/role_information";
import css from "./shortlist_information.module.scss";

export const ShortlistInformation = () => {
  const { task } = useActivityPageContext();

  return (
    <Tile className={css.root} paddingVariant="lg" selected>
      <RoleInformation role={task} />
      <Divider />
      <MatchAttributes />
    </Tile>
  );
};
