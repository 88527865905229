import { Id } from "PFTypes";
export const optionsKeys = {
  all: ["selectOptions"],
  dropdown: (dropdownId) => [...optionsKeys.all, dropdownId],
  dropdownWithParams: (dropdownId, ids: Id[] = [], searchTerm = "") => [
    ...optionsKeys.all,
    dropdownId,
    ids,
    searchTerm
  ]
};
