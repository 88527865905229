import { isArray, isEmpty, isNil, snakeCase } from "lodash";
import { ApiRoute } from "PFCore/utilities/routes";

import { checkFiltersAreDefault, filterElements, getValueFilters } from "./helpers";

/*
 * API meta contains filters with this structure
 * This is known as metaFilters as it contains filter values AND filter config together
 *
 * MetaFilters
 * {
 *   meta: {
 *     filters: {
 *       availability: MetaFilter,
 *       strings: {
 *         key1: MetaFilter
 *       },
 *       fields: {
 *         key2: MetaFilter
 *       },
 *       numbers: {
 *         key3: MetaFilter
 *       }
 *     }
 *   }
 * }
 *
 * Each MetaFilter contains this structure
 * MetaFilter
 * {
 *   title: "Title1", //Title of the filter
 *   operator: ["any", "all", "lte", "gte"],
 *   facets: true / false , // indicates if the options come from HAL or not
 *   options: [], // When present this are the options to render
 *   ordinal: 200, // When present it is used to know the oder to render it
 *   value: null / [], // When present this filter contains a value from the user
 * }
 *
 * UI needs to preserve the same structure but with just the values
 * That is called ValueFilters
 *
 * ValueFilters
 * {
 *   valueFilters: {
 *     availability: {mode: "time_rules, ...} / null,
 *     strings: {
 *       key1: [12, 87]
 *     },
 *     fields: {
 *       key2: [98, 45]
 *     },
 *     numbers: {
 *       key3: {min, max, gte, lte}
 *     }
 *   }
 * }
 *
 * UI needs to strip the filters that have no values before sending it to API
 * That is called APIFilters
 *
 * */

export const checkFiltersAreClear = (metaFilters, blockedList = [], blockedChildrenList = []) => {
  const elements = filterElements(metaFilters, blockedList, blockedChildrenList);
  return elements.every((item) => (isArray(item.value) ? isEmpty(item.value) : isNil(item.value)));
};

export const letFiltersRestore = (valueFilters, defaultFilters) =>
  !isEmpty(defaultFilters) && !checkFiltersAreDefault(valueFilters, defaultFilters);

/**
 * returns a query function to for the filters of type "select_many", "select_one", or "checkboxes"
 *
 * @param {Object} filter - The meta filter object containing the filter config.
 * @param {Object} meta - The metadata object containing the filters to attach the query function to.
 * @param {boolean} children - The flag indicates if the options should relate to children or root filters.
 */
export const getFilterQuery = (filter, meta, children = null) => {
  if (filter.type === "select_many" || filter.type === "select_one" || filter.type === "checkboxes") {
    return ({ term, value }) =>
      filter.options
        ? Promise.resolve({
            entries: value ? filter.options.filter(({ id }) => value.includes(id)) : filter.options
          })
        : $.ajax({
            url: ApiRoute("/api/options/filters"),
            data: {
              search_id: meta.search_id || meta.searchId,
              name: snakeCase(filter.name),
              ...(isEmpty(term) ? null : { term }),
              ...(isEmpty(value) ? null : { value }),
              ...(!children ? null : { children: true })
            }
          });
  }
  return null;
};

export const getFilterDefault = (filter, meta) => {
  // eslint-disable-next-line no-unused-vars
  const { availability, ...otherFilters } = JSON.parse(JSON.stringify(meta.filters));

  if (isEmpty(meta.default_filters)) {
    return null;
  }

  if (filter.name === "availability") {
    return meta.default_filters.availability;
  }

  let defaultValue = null;
  Object.keys(otherFilters).forEach((key) => {
    const filters = otherFilters[key];
    Object.keys(filters).forEach((filterName) => {
      if (filterName === filter.name) {
        defaultValue = meta.default_filters[key][filterName];
      }
    });
  });

  return defaultValue;
};

export const handleRequestFiltersClear = (valueFilters) => {
  // eslint-disable-next-line no-unused-vars
  const { availability, ...otherFilters } = JSON.parse(JSON.stringify(valueFilters));

  Object.keys(otherFilters).forEach((key) => {
    if (key === "children") {
      otherFilters.children = handleRequestFiltersClear(valueFilters.children);
      return;
    }
    const filters = otherFilters[key];
    Object.keys(filters).forEach((filterName) => {
      filters[filterName] = null;
    });
  });

  if (Object.keys(valueFilters).includes("availability")) {
    otherFilters.availability = {};
  }

  return otherFilters;
};

export const handleRequestFiltersChange = (valueFilters, filter, value) => {
  const { availability, ...otherFilters } = JSON.parse(JSON.stringify(valueFilters));

  if (filter.name === "availability") {
    return { ...otherFilters, availability: value };
  }

  // warning: filters will not be updated when current filters is {}
  // or does not include key("numbers", "strings", "fields")
  Object.keys(otherFilters).forEach((key) => {
    const filters = otherFilters[key];
    Object.keys(filters || {}).forEach((filterName) => {
      if (filterName === filter.name) {
        if (Array.isArray(value)) {
          value = value.map((item) => item?.id || item);
        }
        filters[filterName] = value?.id || value;
      }
    });
  });

  return { availability, ...otherFilters };
};

export const handleRequestFiltersReplace = (valueFilters, filters) => {
  const clearedFilters = handleRequestFiltersClear(valueFilters);
  const replacedValueFilters = { ...clearedFilters };

  Object.keys(filters).forEach((filtersFamily) => {
    if (filtersFamily === "children" && valueFilters.children) {
      replacedValueFilters.children = handleRequestFiltersReplace(valueFilters.children, filters.children);
      return;
    }

    if (replacedValueFilters[filtersFamily]) {
      replacedValueFilters[filtersFamily] = {
        ...(replacedValueFilters[filtersFamily] || {}),
        ...(filters[filtersFamily] || {})
      };
    }
  });

  return replacedValueFilters;
};

export const countFilters = (filters) => {
  const valueFilters = getValueFilters(filters);

  const { availability, fields, numbers, strings } = valueFilters;

  const countFilters = (filters) => Object.values(filters || {}).filter((filterValue) => filterValue).length;

  const availabilityCount = availability ? 1 : 0;

  const fieldsCount = countFilters(fields);
  const stringsCount = countFilters(strings);
  const numbersCount = countFilters(numbers);

  return availabilityCount + fieldsCount + stringsCount + numbersCount;
};

// TODO: [ENG-84] Remove it or redo it
const formatFilters = (filters) => filters;
export default formatFilters;
