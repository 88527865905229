import classNames from "classnames";
import { ProfileItem } from "PFApp/components/profile_item";
import { Typography } from "PFComponents/typography";

import css from "./display_option.module.scss";
import { CostField, Option } from "./matches_named_resources_selector";

type DisplayOptionProps = {
  option: Option;
  costField?: CostField;
};

const getPercentValue = (number: number | null) => (number ? `${Math.round(number * 100)}%` : "-");

export const DisplayOption = ({ option, costField }: DisplayOptionProps) => (
  <div className={classNames(css.grid, { [css.withCostField]: !!costField })}>
    <ProfileItem profile={option} />
    {!!costField && (
      <span className={css.cell}>
        <Typography variant="bodyBold" noMargin clipOverflow>
          {costField.getValue(option) || "-"}
        </Typography>
      </span>
    )}
    <span className={css.cell}>
      <Typography className={css.cell} variant="bodyBold" noMargin>
        {getPercentValue(option.scores?.normalizedScore ?? null)}
      </Typography>
    </span>
    <span className={css.cell}>
      <Typography className={css.cell} variant="bodyBold" noMargin>
        {getPercentValue(option.scores?.availabilityScore ?? null)}
      </Typography>
    </span>
  </div>
);
