import { getSupportingSkills } from "PFApp/hooks/matches";

import { useMatchCardContext } from "../match_card_context";

export const useSupportingSkills = () => {
  const { custom_values: customValues, role, shortlist } = useMatchCardContext();

  return getSupportingSkills({
    matchCustomValues: customValues,
    role,
    applicableCustomValues: shortlist?.applicable_custom_values
  });
};
