import { useQueryClient } from "@tanstack/react-query";

import { optionsKeys } from "./query_keys";

export const useOptionsInvalidate = () => {
  const cache = useQueryClient();

  return {
    invalidate: (dropdownId) => cache.invalidateQueries({ queryKey: optionsKeys.dropdown(dropdownId) })
  };
};
