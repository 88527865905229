import { Icon, IconProps } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";

import css from "./label.module.scss";

type LabelProps = { icon?: IconProps["name"]; text: string };

export const Label = ({ text, icon }: LabelProps) => (
  <div className={css.label}>
    {icon && <Icon name={icon} size="sm" />}
    <Typography variant="labelRegular" noMargin>
      {text}
    </Typography>
  </div>
);
