import api from "PFCore/api";

import { AIGeneratorBaseParams } from "./ai.types";

export type AIDescriptionResponse = {
  roleDescription: string;
};

type GenerateDescriptionData = AIGeneratorBaseParams & {
  roleTitle: string;
  selectedSkills?: string[];
  roleDescription?: string;
};

export const generateRoleDescription = (data: GenerateDescriptionData) =>
  api<AIDescriptionResponse>({
    method: "POST",
    url: "ai/generate_description",
    data
  });
