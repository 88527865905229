import { noop } from "lodash";
import { CalendarMetric, CalendarView } from "PFApp/booking/types";

export const INITIAL_CALENDAR_DATA_PROJECTS = {
  additionalDataLoading: false,
  data: { entries: [], meta: { total: 0, perPage: 0, page: 0, totalPages: 0 }, loading: false },
  loaded: false,
  noFiltersSelected: false,
  noOrderSelected: false,
  view: CalendarView.Compressed,
  metric: CalendarMetric.Availability,
  rowDimensions: {},
  rows: [],
  setView: noop,
  setMetric: noop,
  setRowDimensions: noop,
  setRows: noop,
  updateFilter: noop,
  setFilters: noop,
  resetFilters: noop,
  onOrderChange: noop,
  page: 0,
  setPage: noop,
  warningsByActivity: {},
  isChildrenFiltersEnabledDefault: false
};
