import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./no_search_term.module.scss";

export const NoSearchTerm = () => {
  const { t } = useTranslation();
  return (
    <div className={css.root}>
      <Typography variant="bodyRegular" noMargin>
        {t("tooManyPleaseSearch")}
      </Typography>
    </div>
  );
};
