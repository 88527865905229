import React, { MutableRefObject, PropsWithChildren, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useClickAway } from "react-use";

import css from "./popover.module.scss";

type PopoverProps = PropsWithChildren<{
  reference: MutableRefObject<HTMLDivElement | null>;
  onClickOutside: VoidFunction;
  triggerUpdate: string;
}>;

export const Popover = ({ children, triggerUpdate, reference, onClickOutside }: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const ref = useRef(null);

  const { styles, attributes, update } = usePopper(reference.current, popperElement, {
    placement: "bottom-start"
  });

  useEffect(() => {
    update?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate]);

  useClickAway(reference, (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClickOutside();
  });

  return (
    <div ref={ref}>
      <div
        ref={setPopperElement}
        className={css.root}
        style={{
          ...styles.popper,
          minWidth: reference.current?.offsetWidth
        }}
        {...attributes.popper}
      >
        {children}
      </div>
    </div>
  );
};
