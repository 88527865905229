import { find } from "lodash";
import { useTemplateFind } from "PFApp/hooks";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { isProfileActive } from "PFCore/helpers/profile";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Activity, PermissionRule, Profile, Shortlist, ShortlistState } from "PFTypes";

import { useActivityCurrentUserRole } from "../activities/hooks";

const STATES_ALLOWING_FILL: Readonly<ShortlistState[]> = ["pending", "accepted", "assigned", "rejected"];

const checkIsDraft = (role: Activity) => role.state === "draft";

type ShortlistBase = Pick<Shortlist, "id" | "state"> | null;

type CheckOptions = {
  skipShortlistCheck?: boolean;
};

export type CheckActionPermissionFunction = (
  role: Activity,
  targetProfile: Partial<Profile>,
  shortlist?: ShortlistBase,
  options?: CheckOptions
) => boolean;

export const useRoleProfileActionsPermissions = () => {
  const { data: currentProfile } = useCurrentProfile();
  const findTemplate = useTemplateFind();
  const { canProfileCreateBooking } = useBookingModulePermissions();
  const { bookingCategories } = useBookingCategories();
  const hasAcceptForEndUser = useIsCurrentUserPermittedTo(PermissionRule.AcceptForEndUser);
  const hasShortlistWithoutOwnership = useIsCurrentUserPermittedTo(PermissionRule.ShortlistWithoutOwnership);
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !!demandRelated)
    .some((i) => !i.readonly);
  const { checkCurrentUserRoleInActivity } = useActivityCurrentUserRole();

  const checkActionPrerequisite = (role: Activity, targetProfile: Partial<Profile>) => {
    const { isReviewer = false } = checkCurrentUserRoleInActivity(role);
    return isProfileActive(targetProfile) && (role.coowned || isReviewer);
  };

  const getShortlistInvitesProperty = (role: Activity) =>
    !!find(currentProfile.templates, ["id", role.template_id])?.shortlist_invites;

  const checkCanApproveDecline: CheckActionPermissionFunction = (role, targetProfile, shortlist) => {
    const isPending = shortlist?.state === "pending";

    return checkActionPrerequisite(role, targetProfile) && isPending;
  };

  const checkCanInvite: CheckActionPermissionFunction = (role, targetProfile, shortlist) => {
    const shortlistInvites = getShortlistInvitesProperty(role);
    const isAccepted = shortlist?.state === "accepted";

    return (
      checkActionPrerequisite(role, targetProfile) && isAccepted && shortlistInvites && !checkIsDraft(role)
    );
  };

  const checkCanFill: CheckActionPermissionFunction = (role, targetProfile, shortlist) => {
    const shortlistInvites = getShortlistInvitesProperty(role);
    const canBypassInvite = !shortlistInvites || hasAcceptForEndUser;
    const isInProperState = !shortlist || STATES_ALLOWING_FILL.includes(shortlist.state);
    const { isResourcer = false } = checkCurrentUserRoleInActivity(role);

    return (
      checkActionPrerequisite(role, targetProfile) &&
      role.coowned &&
      isResourcer &&
      !checkIsDraft(role) &&
      canBypassInvite &&
      isInProperState
    );
  };

  const checkCanBook: CheckActionPermissionFunction = (role, targetProfile, shortlist, options = {}) => {
    const isFilled = shortlist?.state === "filled";

    return (
      checkActionPrerequisite(role, targetProfile) &&
      canProfileCreateBooking(targetProfile.id) &&
      hasWriteAccessToAtLeastOneCategory &&
      !checkIsDraft(role) &&
      (options.skipShortlistCheck || isFilled)
    );
  };

  const checkCanFillAndBook: CheckActionPermissionFunction = (role, targetProfile, shortlist) => {
    const template = findTemplate({ id: role.template_id });
    const isAutoBookingCreationEnabled = !!template?.automations?.auto_create_bookings?.enabled;
    const canFill = checkCanFill(role, targetProfile, shortlist);
    const canBook = checkCanBook(role, targetProfile, shortlist, { skipShortlistCheck: true });

    return isAutoBookingCreationEnabled && canFill && canBook;
  };

  const checkCanUndo: CheckActionPermissionFunction = (role, targetProfile, shortlist) => {
    const isPending = shortlist?.state === "pending";
    const reviewCompleted = role.review?.state === "complete";
    const { isReviewer = false } = checkCurrentUserRoleInActivity(role);

    const removeShortlistCondition = role.coowned || hasShortlistWithoutOwnership;
    const resetShortlistCondition = role.coowned || isReviewer;
    const isAllowedToUndo = isPending ? removeShortlistCondition : resetShortlistCondition;
    const isReviewerWithReviewCompleted = isReviewer && !role.coowned && reviewCompleted;

    return isProfileActive(targetProfile) && isAllowedToUndo && !isReviewerWithReviewCompleted;
  };

  return {
    checkCanApproveDecline,
    checkCanInvite,
    checkCanFill,
    checkCanBook,
    checkCanFillAndBook,
    checkCanUndo
  };
};
