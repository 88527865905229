import { SearchableVariant } from "../select_v2.types";

export const checkIsSearchable = (
  searchableVariant: SearchableVariant,
  staticOptionsCount: number,
  isAsyncFetching: boolean
) => {
  switch (searchableVariant) {
    case SearchableVariant.Never:
      return false;
    case SearchableVariant.Always:
      return true;
    case SearchableVariant.Dynamic:
      return staticOptionsCount > 5 || isAsyncFetching;
  }
};
