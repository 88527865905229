import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";

import { generateSelectColumn } from "./selection";
import css from "./table.module.scss";
import { GenerateColumnsFn, OnRowClickFn, PaginationOptions } from "./table.types";
import { getPaginationOptions } from "./table.utils";
import { TableBody } from "./table_body";
import { TableHeader } from "./table_header";
import { TablePagination } from "./table_pagination";
import { TableResizer } from "./table_resizer";

export type TableData = Record<string, any> & {
  disabled?: boolean;
  message?: string;
};

type TableProps<TABLE_DATA extends TableData> = {
  tableData: TABLE_DATA[];
  enableRowSelection?: boolean | ((row: Row<TABLE_DATA>) => boolean);
  generateColumns: GenerateColumnsFn<TABLE_DATA>;
  footerEnabled?: boolean;
  simplePaginationEnabled?: boolean;
  paginationOptions?: PaginationOptions;
  onRowClick?: OnRowClickFn<TABLE_DATA>;
  bottomElementsSize?: number;
  compact?: boolean;
  noHover?: boolean;
  onSortingChange?: (sorting: SortingState) => void;
  controlledSorting?: SortingState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  rowSelection?: RowSelectionState;
  getRowId?: (row: TABLE_DATA, index: number) => string;
  enableMultiRowSelection?: boolean;
  disableAutoResizing?: boolean;
  disableHeader?: boolean;
  lastRowRef?: React.RefObject<HTMLTableRowElement>;
};

const Table = <TABLE_DATA extends TableData>({
  tableData,
  enableRowSelection,
  generateColumns,
  simplePaginationEnabled,
  paginationOptions,
  onRowClick,
  bottomElementsSize,
  compact,
  noHover,
  onSortingChange,
  controlledSorting,
  onRowSelectionChange,
  rowSelection,
  getRowId,
  enableMultiRowSelection,
  disableAutoResizing,
  disableHeader,
  lastRowRef
}: TableProps<TABLE_DATA>): JSX.Element => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const tableRef = useRef<HTMLTableElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  const { current: columnHelper } = useRef(createColumnHelper<TABLE_DATA>());
  const columns = useMemo(() => generateColumns(columnHelper), [columnHelper, generateColumns]);

  useEffect(() => {
    if (controlledSorting) {
      setSorting(controlledSorting);
    }
  }, [controlledSorting]);

  const changeSorting: OnChangeFn<SortingState> = (sort) => {
    const unwrappedSort = typeof sort === "function" ? sort(sorting) : sort;
    onSortingChange?.(unwrappedSort);

    if (!controlledSorting) {
      setSorting(unwrappedSort);
    }
  };

  const columnsWithEnabledFeatures = enableRowSelection
    ? [generateSelectColumn<TABLE_DATA>(columnHelper, !!enableMultiRowSelection), ...columns]
    : columns;

  const table = useReactTable({
    data: tableData,
    columns: columnsWithEnabledFeatures,
    defaultColumn: {
      minSize: 0,
      size: 0
    },
    state: {
      sorting,
      rowSelection
    },
    onSortingChange: changeSorting,
    enableRowSelection: enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: simplePaginationEnabled ? getPaginationRowModel() : undefined,
    manualSorting: !!onSortingChange,
    onRowSelectionChange,
    getRowId,
    enableMultiRowSelection: !!enableMultiRowSelection
  });

  const isPaginationEnabled = simplePaginationEnabled || paginationOptions;

  return (
    <>
      <TableResizer
        tableRef={tableRef}
        paginationRef={paginationRef}
        bottomElementsSize={bottomElementsSize}
        disableAutoResizing={disableAutoResizing}
      >
        <table ref={tableRef} className={css.root}>
          {!disableHeader && (
            <TableHeader<TABLE_DATA>
              headerGroups={table.getHeaderGroups()}
              compact={compact}
              enableMultiRowSelection={enableMultiRowSelection}
            />
          )}
          <TableBody<TABLE_DATA>
            rows={table.getRowModel().rows}
            compact={compact}
            noHover={noHover}
            onRowClick={onRowClick}
            lastRowRef={lastRowRef}
          />
        </table>
      </TableResizer>

      {isPaginationEnabled && (
        <TablePagination
          paginationRef={paginationRef}
          paginationOptions={getPaginationOptions<TABLE_DATA>(table, paginationOptions)}
        />
      )}
    </>
  );
};

export default Table;
