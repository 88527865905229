import { TemplateKey } from "PFApp/constants/templates";
import { Activity, Template } from "PFTypes";

export const isEngagement = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(
    templateOrKey &&
      [TemplateKey.Engagement, TemplateKey.AuditEngagement].includes(
        (templateOrKey as Template).key || templateOrKey
      )
  );

export const isRole = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(
    templateOrKey &&
      [TemplateKey.Role, TemplateKey.AuditRole].includes((templateOrKey as Template).key || templateOrKey)
  );

export const isAudit = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(((templateOrKey as Template)?.key || templateOrKey || "").includes("audit"));

export const checkIfIsDefaultPrivate = (template: Template, parentActivity?: Activity) => {
  if (parentActivity && template.default_visibility === "inherit_from_parent") {
    return parentActivity.private;
  } else {
    return template.default_visibility === "private";
  }
};
