import AppContext from "PFApp/app_context";
import { TermsOfService } from "PFApp/components/terms_of_service";
import { Modal } from "PFComponents/modal";
import { MODALS_ACTIONS } from "PFReducers/modals_reducer";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import css from "./terms_of_service_modal.module.scss";

export const TermsOfServiceModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);

  const handleClose = () => {
    dispatch({ type: MODALS_ACTIONS.TERMS_HIDE });
  };

  return (
    <Modal
      title={t("termsAndConditions")}
      onClose={handleClose}
      showOKButton={false}
      labelCancel={t("close")}
      classes={{ modal: css.modal }}
    >
      <TermsOfService />
    </Modal>
  );
};
