import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { Icon } from "PFComponents/icon";
import { MultiToggle } from "PFComponents/multi_toggle";

const modeOptions = [
  {
    id: ProfilesViewMode.Cards,
    value: <Icon name="note" size="sm" />
  },
  {
    id: ProfilesViewMode.Table,
    value: <Icon name="list" size="sm" />
  }
];

export const ProfileViewModeToggle = () => {
  const { profilesViewMode, setProfilesViewMode } = useActivityPageContext();

  return (
    <MultiToggle<ProfilesViewMode>
      controlledValue={profilesViewMode}
      options={modeOptions}
      onChange={setProfilesViewMode}
    />
  );
};
