import { Checkbox } from "PFComponents/checkbox";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";
import css from "./override_toggles.module.scss";
import { OvercheduleDaysTooltip } from "./overschedule_days_tooltip";
import { OverscheduleTimeTooltip } from "./overschedule_time_tooltip";

interface OverrideTogglesProps {
  overridesCalendarFormValueName: string;
  overridesNonWorkingDaysFormValueName: string;
  isOverridesNonWorkingDaysDisabled: boolean;
}

export const OverrideToggles = ({
  overridesCalendarFormValueName,
  overridesNonWorkingDaysFormValueName,
  isOverridesNonWorkingDaysDisabled
}: OverrideTogglesProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (isOverridesNonWorkingDaysDisabled) {
      setValue(overridesNonWorkingDaysFormValueName, false);
    }
  }, [isOverridesNonWorkingDaysDisabled]);

  return (
    <BookingDetailItem
      label={t("overscheduling")}
      value={
        <div className={css.container}>
          <OvercheduleDaysTooltip isToggleDisabled={isOverridesNonWorkingDaysDisabled}>
            <div className={css.toggleRow}>
              <Controller
                name={overridesNonWorkingDaysFormValueName}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t("overscheduleNonWorkingTime")}
                    checked={field.value}
                    onChange={field.onChange}
                    disabled={isOverridesNonWorkingDaysDisabled}
                  />
                )}
              />
            </div>
          </OvercheduleDaysTooltip>
          <OverscheduleTimeTooltip>
            <div className={css.toggleRow}>
              <Controller
                name={overridesCalendarFormValueName}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t("overscheduleDiaryTime")}
                    checked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </OverscheduleTimeTooltip>
        </div>
      }
    />
  );
};
