export enum TemplateKey {
  Course = "course",
  Event = "event",
  Position = "position",
  Project = "project",
  Question = "question",
  Role = "role",
  Suggestion = "suggestion",
  Post = "post",
  Lesson = "lesson",
  Engagement = "engagement",
  Task = "task",
  Work = "work",
  AuditRole = "audit_role",
  AuditEngagement = "audit_engagement"
}
