import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Activity, Assignee } from "PFTypes";

type LegacyAssignee = Assignee["profile"];

const checkIsLegacyAssigneeField = (assignee: Assignee | LegacyAssignee | null) =>
  !!assignee && !("profile" in assignee);

type CurrentUserRoleInActivity = {
  isResourcer: boolean;
  isReviewer: boolean;
  isReviewerUnbiased: boolean;
  isOwner: boolean;
  isAssignee: boolean;
};

type CurrentUserRoleInActivityChecker = {
  checkCurrentUserRoleInActivity: (activity?: Activity) => CurrentUserRoleInActivity;
};

type UseActivityCurrentUserRoleReturn = CurrentUserRoleInActivity & CurrentUserRoleInActivityChecker;

export const useActivityCurrentUserRole = (activityToCheck?: Activity): UseActivityCurrentUserRoleReturn => {
  const { data: currentProfile } = useCurrentProfile();

  const checkCurrentUserRoleInActivity = (activity?: Activity) => {
    if (!activity) {
      return {
        isResourcer: false,
        isReviewer: false,
        isReviewerUnbiased: false,
        isOwner: false,
        isAssignee: false
      };
    }

    const ownGroup = currentProfile.permissions_group;

    const isResourcer = !!ownGroup?.permissions.find(
      ({ name, permitted }) => name === "resourcer" && permitted
    );

    const userAsActivityReviewer = activity.reviewers?.find(
      ({ profile_id }) => profile_id === currentProfile.id
    );

    const isReviewer = !!activity.reviewed_by_requester || !!userAsActivityReviewer;

    const isReviewerUnbiased = !!userAsActivityReviewer?.unbiased;

    const isOwner = !!activity.owners?.find((owner) => owner.profile_id === currentProfile.id);
    // TODO: [SP-1926] Remove checkIsLegacyAssigneeField and use just assignee.profile.id
    const activityAssigneeId = checkIsLegacyAssigneeField(activity.assignee)
      ? activity.assignee?.id
      : activity.assignee?.profile.id;
    const isAssignee = activityAssigneeId === currentProfile.id;

    return { isResourcer, isReviewer, isReviewerUnbiased, isOwner, isAssignee };
  };

  return { ...checkCurrentUserRoleInActivity(activityToCheck), checkCurrentUserRoleInActivity };
};
