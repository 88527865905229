import { SkillsList } from "PFApp/components/skills_list";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { generateMatchCustomValues } from "PFCore/helpers/custom_value";
import { ActivityPureCustomValue, Id, MatchCustomValue } from "PFTypes";

import css from "./table_cells.module.scss";

type SkillsCellProps = {
  profileId: Id;
  matchSkills: MatchCustomValue[];
  roleSkills: ActivityPureCustomValue[];
};

export const SkillsCell = ({ profileId, matchSkills, roleSkills }: SkillsCellProps) => {
  const matchAndActivityCustomValues = generateMatchCustomValues(matchSkills, roleSkills);

  const label = roleSkills.length > 0 ? `${matchSkills.length}/${roleSkills.length}` : matchSkills.length;

  return (
    <Tooltip
      content={
        <div className={css.skillsTooltip}>
          <SkillsList
            customFieldName="skills"
            skills={matchAndActivityCustomValues}
            profileId={profileId}
            experienceTooltipDisabled
            dark
          />
        </div>
      }
      disabled={matchAndActivityCustomValues.length === 0}
      interactive
    >
      <span>
        <Typography variant="bodyBold" tag="span" className={css.skillsCount} noMargin>
          {label}
        </Typography>
      </span>
    </Tooltip>
  );
};
