import { Id } from "PFTypes/id";
import { forwardRef } from "react";

import Row from "./row";
import { TableColumn, TableItem } from "./table_types";

type RowsPropsType = {
  columns: TableColumn[];
  items: TableItem[];
  selectedIds?: Id[];
  pinnedIds?: Id[];
  isLoading?: boolean;
  setSelectedIds?: React.Dispatch<React.SetStateAction<Id[]>>;
  setSelectedItems?: React.Dispatch<React.SetStateAction<TableItem[]>>;
  isSelectMode?: boolean;
  dimUnselectedRows?: boolean;
  renderCell?: (item: TableItem, column: TableColumn) => React.ReactNode;
  renderActions?: (item: TableItem) => React.ReactNode;
  markAsRead?: (id: Id) => void;
  utmSource?: string;
  className?: string;
  classes?: {
    unselected?: string;
    mediumColumn?: string;
  };
};

/**
 * @deprecated
 */
const Rows = forwardRef<HTMLDivElement, RowsPropsType>(
  (
    {
      columns,
      items,
      selectedIds,
      pinnedIds,
      isLoading,
      setSelectedIds,
      setSelectedItems,
      dimUnselectedRows,
      isSelectMode,
      renderCell,
      renderActions,
      markAsRead,
      utmSource,
      className,
      classes
    },
    lastItemRef
  ) => {
    const isSelected = (item) => !!selectedIds?.find((id) => id === item.id);
    const isPinned = (item) => !!pinnedIds?.find((id) => id === item.id);

    const handleSelectClick = (item, isSelected) => {
      setSelectedIds &&
        setSelectedIds((prev) => {
          const newSelected = [...prev];
          const index = newSelected.indexOf(item.id);
          if (isSelected) {
            index === -1 && newSelected.push(item.id);
          } else {
            index !== -1 && newSelected.splice(index, 1);
          }
          return newSelected;
        });

      setSelectedItems &&
        setSelectedItems((prev) => {
          const newSelected = [...prev];
          const index = newSelected.map(({ id }) => id).indexOf(item.id);
          if (isSelected) {
            index === -1 && newSelected.push(item);
          } else {
            index !== -1 && newSelected.splice(index, 1);
          }
          return newSelected;
        });
    };

    return (
      <>
        {items.map((item, i) => (
          <Row
            className={className}
            classes={classes}
            key={item.id}
            isLoading={isLoading}
            item={item}
            dimUnselectedRows={dimUnselectedRows}
            isSelectMode={isSelectMode}
            isSelected={isSelected(item)}
            isPinned={isPinned(item)}
            onSelectClick={(value) => handleSelectClick(item, value)}
            columns={columns}
            renderCell={renderCell}
            renderActions={renderActions}
            markAsRead={markAsRead}
            isSelectionEnabled={!!setSelectedItems || !!setSelectedIds}
            utmSource={utmSource}
            ref={i + 1 === items.length ? lastItemRef : null}
          />
        ))}
      </>
    );
  }
);

Rows.displayName = "Rows";

export default Rows;
