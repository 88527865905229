import { isNumber } from "lodash";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import AutoSelect from "PFComponents/select/autoselect";
import { useRoleName } from "PFCore/helpers/activities";
import { getProfileName } from "PFCore/helpers/profile";
import { useIsShortlistingPermitted } from "PFCore/helpers/shortlists";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { fetchActivities } from "PFCore/services/activities";
import { Activity, Profile } from "PFTypes";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ShortlistButton } from "./parts/shortlist_button";
import { UndoButton } from "./parts/undo_button";
import css from "./shortlist_for_profile_button.module.scss";

export const ShortlistForProfileButton = ({ profile }: { profile: Profile }) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");
  const isShortlistingPermitted = useIsShortlistingPermitted();
  const getRoleName = useRoleName();
  const roleTemplate = useTemplate(TemplateKey.Role);
  const ownGroup = currentProfile.permissions_group;
  const isResourcer = ownGroup.permissions.find(({ name, permitted }) => name === "resourcer" && permitted);
  const canShortlist = !!roleTemplate?.create_permitted && (isResourcer || isShortlistingPermitted());

  const [shown, setShown] = useState(false);
  const [isShortlistInfoVisible, setIsShortlistInfoVisible] = useState(false);
  const [isJustAdded, setIsJustAdded] = useState(false);
  const [selected, setSelected] = useState<Activity | null>(null);

  const { data: shortlists, isFetching: isLoadingShortlists } = useActivityShortlists(
    selected?.id || -1,
    {},
    {
      enabled: isNumber(selected?.id),
      onSuccess: () => {
        setIsShortlistInfoVisible(true);
      }
    }
  );

  const shortlist = useMemo(
    () => shortlists?.entries?.find?.((entry) => entry.profile.id === profile.id) ?? null,
    [profile.id, shortlists?.entries]
  );

  const handleNewShortlist = () => {
    setIsJustAdded(true);
  };

  if (!canShortlist) {
    return null;
  }

  return (
    <>
      <Button
        aria-label={t("shortlistForProfile.shortlistName", { name: profile.first_name })}
        text={t("shortlistForProfile.shortlist")}
        onClick={(event) => {
          event.stopPropagation();
          setShown(true);
        }}
      />
      {shown && (
        <Modal
          title={t("shortlistForProfile.shortlistName", { name: getProfileName(profile) })}
          onClose={() => setShown(false)}
          showOKButton={false}
        >
          <AutoSelect
            placeholder={selected ? "" : t("shortlistForProfile.startTyping")}
            values={selected ? [{ id: selected.id || -1, text: selected.name || "..." }] : null}
            key={selected?.id}
            closeOnChange
            multi={false}
            query={(term) => {
              setIsShortlistInfoVisible(false);
              setIsJustAdded(false);

              return fetchActivities({
                filters: {
                  fields: {
                    term: term,
                    state: ["draft", "new"],
                    templateKey: [TemplateKey.Role, TemplateKey.AuditRole],
                    ...(isShortlistingPermitted() ? {} : { ownerId: currentProfile.id })
                  }
                },
                order: {
                  name: ["activity.name"],
                  direction: "asc",
                  text: "Alphabetically (A-Z)" //should not be translated
                }
              });
            }}
            parseResponse={({ entries }) => entries.map(({ activity }) => activity)}
            formatOption={(item) => ({ id: item.id, displayElement: getRoleName(item), item })}
            handleChange={(task) => setSelected(task[0])}
          />

          <div className={css.action}>
            {isLoadingShortlists && !isShortlistInfoVisible && <LoadingDots />}

            {isShortlistInfoVisible &&
              selected &&
              (shortlist ? (
                <>
                  <div className={css.tip}>
                    <Trans
                      i18nKey={
                        isJustAdded
                          ? "shortlistForProfile.nameHasBeenShortlisted"
                          : "shortlistForProfile.nameAlreadyShortlisted"
                      }
                      t={t}
                      values={{ name: getProfileName(profile), selected: selected.name }}
                      components={[
                        <Link
                          to={`/activities/${selected.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          key="0"
                        />
                      ]}
                    />
                  </div>
                  <UndoButton
                    activityId={selected.id}
                    shortlist={shortlist}
                    isLoadingShortlists={isLoadingShortlists}
                  />
                </>
              ) : (
                <>
                  <div className={css.tip}>
                    <Trans
                      i18nKey="shortlistForProfile.shortlistNameTo"
                      t={t}
                      values={{ name: getProfileName(profile), selected: selected.name }}
                      components={[
                        <Link
                          to={`/activities/${selected.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          key="0"
                        />
                      ]}
                    />
                    :
                  </div>
                  <ShortlistButton
                    profile={profile}
                    activity={selected}
                    isLoadingShortlists={isLoadingShortlists}
                    onShortlistCreated={handleNewShortlist}
                  />
                </>
              ))}
          </div>
        </Modal>
      )}
    </>
  );
};
