import { Option, OptionOriginal, SelectV2Props } from "../../select_v2.types";
import { ValueBadge } from "./value_badge";
import css from "./values_list.module.scss";

type ValuesListProps<T> = Pick<SelectV2Props<T>, "onChange"> & {
  selectedOptions: Option<T>[];
};

export const ValuesList = <T extends OptionOriginal = OptionOriginal>({
  selectedOptions,
  onChange
}: ValuesListProps<T>) => {
  if (selectedOptions.length === 0) {
    return null;
  }

  return (
    <div className={css.valuesList}>
      {selectedOptions.map((value) => (
        <ValueBadge
          key={value.id}
          value={value}
          disabled={value.disabled}
          onRemove={(selectedOption, event) => {
            event.stopPropagation();
            onChange(selectedOptions.filter(({ id }) => id !== selectedOption.id));
          }}
        />
      ))}
    </div>
  );
};
